import React, { useState, useEffect } from 'react';
import { Grid, Box, CircularProgress, Typography, Divider } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ShopCard from './ShopCard';
import EndPoints from '../EndPointConfig';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import styles from './ActiveSellerProduct.module.css';

interface ActiveSellerProductsProps {
  sellerId: number;
}

interface Product {
  id: number;
  name: string;
  price: number;
  stock: number;
  minOrder: number;
  ProductImages: Array<{
    imageUrl: string;
    isMain: boolean;
  }>;
}

const ActiveSellerProducts: React.FC<ActiveSellerProductsProps> = ({ sellerId }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${EndPoints.GET_SELLER}/${sellerId}/active-products`);
        setProducts(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching seller products:', err);
        setError(t('errors.fetchProducts'));
      } finally {
        setLoading(false);
      }
    };

    if (sellerId) {
      fetchProducts();
    }
  }, [sellerId, t]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.messageContainer}>
        <Typography className={styles.errorMessage}>{error}</Typography>
      </div>
    );
  }

  if (!products.length) {
    return (
      <div className={styles.messageContainer}>
        <Typography>{t('userProfile.seller.products.noActiveProducts')}</Typography>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Box className={styles.headerSection}>
        <Box className={styles.titleContainer}>
          <StorefrontIcon className={styles.headerIcon} />
          <Typography variant="h4" className={styles.mainTitle}>
            {t('userProfile.seller.products.title')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: 2 }}>
            <LocalOfferIcon className={styles.statIcon} />
            <Typography variant="h6" className={styles.statValue}>
              {products.length}
            </Typography>
          </Box>
        </Box>
        <Divider className={styles.divider} />
      </Box>

      <Grid container spacing={3} className={styles.gridContainer}>
        {products.map(product => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
            <ShopCard
              productId={product.id}
              productName={product.name}
              productPrice={product.price}
              productImage={
                product.ProductImages.find(img => img.isMain)?.imageUrl || product.ProductImages[0]?.imageUrl
              }
              minOrder={product.minOrder}
              availableStock={product.stock}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ActiveSellerProducts;
