export const arContent = {
  title: 'سياسة الخصوصية',
  introduction:
    'مرحبًا بكم في تيتروم. نحن ملتزمون بحماية خصوصيتكم وأمان معلوماتكم الشخصية. توضح هذه السياسة كيفية جمع معلوماتكم واستخدامها وتخزينها وحمايتها، بالإضافة إلى حقوقكم المتعلقة بهذه المعلومات. باستخدام تيتروم، فإنكم توافقون على جمع واستخدام معلوماتكم كما هو موضح في سياسة الخصوصية هذه وفي شروط الخدمة الخاصة بنا.',
  sections: [
    {
      title: 'المعلومات التي نجمعها',
      subsections: [
        {
          subtitle: ':نقوم بجمع المعلومات الشخصية التي تقدمونها لنا عند',
          content:
            '• تسجيلكم كعملاء على منصتنا.\n• إنشاء متجر أو تقديم طلب شراء.\n• إتمام المعاملات الخاصة بالتوصيل.\n• تسجيل الدخول باستخدام تيليجرام.',
        },
        {
          subtitle: ':أنواع المعلومات التي نجمعها تشمل',
          content:
            '• الاسم\n• البريد الإلكتروني\n• اسم المتجر أو نوع الصناعة\n• تفاصيل التوصيل\n• رقم الهاتف (اختياري)\n• معلومات المصادقة عبر تيليجرام',
        },
      ],
    },
    {
      title: 'الأساس القانوني لمعالجة المعلومات',
      content:
        'نعالج معلوماتكم الشخصية بناءً على الأسس القانونية التالية:\n\n• الموافقة: عند تقديم موافقتكم الصريحة على استخدام بياناتكم. \n• الالتزام القانوني: للامتثال للقوانين واللوائح.\n• المصالح المشروعة: لأغراض مثل تحسين منصتنا ومنع الاحتيال.',
    },
    {
      title: 'كيفية استخدام معلوماتكم',
      content:
        'نستخدم معلوماتكم الشخصية من أجل:\n\n• تسجيلكم كعملاء جدد.\n• معالجة وتوصيل طلباتكم.\n• إدارة علاقتنا بكم، بما في ذلك تقديم دعم العملاء والتعامل مع الملاحظات.\n• تحسين وتطوير موقعنا الإلكتروني.\n• توصية أو الترويج للمنتجات أو الخدمات التي قد تهمكم.\n• منحكم الوصول إلى المنتجات والخدمات التي يقدمها شركاؤنا والبائعون.\n• ضمان الامتثال للالتزامات القانونية، بما في ذلك التحقق من هويتكم واكتشاف الاحتيال المحتمل.\n• تسهيل عملية التوصيل إلى المشتري.',
    },
    {
      title: 'الاحتفاظ بالبيانات',
      content:
        'نحتفظ بمعلوماتكم الشخصية فقط طالما كان ذلك ضروريًا لتحقيق الأغراض الموضحة في هذه السياسة أو وفقًا لما يتطلبه القانون. بمجرد انتهاء هذه الفترة، سيتم حذف بياناتكم أو إخفاؤها بشكل آمن.',
    },
    {
      title: 'أمان البيانات والتخزين',
      subsections: [
        {
          subtitle: 'التشفير',
          content: 'يتم تشفير جميع الرسائل بين المشترين والبائعين على منصتنا لضمان أمان التواصل.',
        },
        {
          subtitle: 'التخزين',
          content:
            'يتم تخزين معلوماتكم الشخصية بأمان في قاعدة بياناتنا وحمايتها من الوصول أو الاستخدام أو الكشف غير المصرح به.',
        },
      ],
    },
    {
      title: 'الخدمات الطرف الثالث',
      content:
        'قد نشارك معلوماتكم مع خدمات الطرف الثالث الموثوقة لضمان تشغيل منصتنا بسلاسة، بما في ذلك:\n\n• معالجي الدفع: لتسهيل المعاملات.\n• شركاء التوصيل: لإتمام خدمات التوصيل. تلتزم هذه الأطراف الثالثة بالتعامل مع بياناتكم بشكل آمن ووفقًا للقوانين المعمول بها.',
    },
    {
      title: 'حقوقكم',
      content:
        'كمستخدمين، لديكم الحقوق التالية المتعلقة بمعلوماتكم الشخصية:\n\n• الوصول: طلب الوصول إلى البيانات الشخصية التي نحتفظ بها عنكم. \n• التصحيح: طلب تصحيح البيانات غير المكتملة أو غير الدقيقة.\n• الحذف: طلب حذف معلوماتكم الشخصية حيثما ينطبق ذلك.\n• الاعتراض: الاعتراض على معالجة بياناتكم لأغراض محددة.\n• نقل البيانات: طلب نسخة من بياناتكم بتنسيق قابل للقراءة آليًا.\n• سحب الموافقة: سحب الموافقة عند معالجة البيانات بناءً على موافقتكم الصريحة.',
    },
    {
      title: 'خصوصية الأطفال',
      content:
        'لا تقوم تيتروم بجمع معلومات شخصية من الأفراد الذين تقل أعمارهم عن 18 عامًا. إذا اكتشفنا أننا قمنا بجمع مثل هذه المعلومات عن غير قصد، سنتخذ خطوات لحذفها على الفور.',
    },
    {
      title: 'إشعار بانتهاك البيانات',
      content:
        'في حالة حدوث انتهاك للبيانات، سنقوم بما يلي:\n\n• إخطار المستخدمين المتأثرين على الفور.\n• إبلاغ السلطات المختصة إذا تطلب القانون ذلك.\n• اتخاذ التدابير اللازمة للتخفيف من المخاطر المحتملة ومنع تكرار الانتهاك.',
    },
    {
      title: 'التغييرات على سياسة الخصوصية',
      content:
        'قد نقوم بتحديث سياسة الخصوصية من وقت لآخر. سيتم إخطاركم بأي تغييرات من خلال نشر السياسة المحدثة على هذه الصفحة. ننصحكم بمراجعة سياسة الخصوصية بانتظام للاطلاع على التحديثات.',
    },
    {
      title: 'اتصل بنا',
      content:
        'إذا كانت لديكم أي أسئلة حول سياسة الخصوصية هذه أو تحتاجون إلى مساعدة، يرجى التواصل معنا عبر: privacy@titrom.com',
    },
  ],
  footer: {
    Conclusion:
      'في تيتروم، نحن ملتزمون بحماية معلوماتكم الشخصية وتعزيز تجربتكم على منصتنا. نقدر ثقتكم ونسعى دائمًا للحفاظ على أعلى معايير حماية البيانات والشفافية.',
  },
};
