import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import EndPoints from '../EndPointConfig';

interface TelegramUser {
  id: number;
  auth_date: number;
  hash: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
}

interface AuthContextType {
  user: TelegramUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (userData: TelegramUser) => Promise<void>;
  logout: () => void;
  generateHeaders: (userData: TelegramUser) => Record<string, string>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<TelegramUser | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const validateAndSetUser = useCallback(async (userData: TelegramUser) => {
    try {
      const headers = generateHeaders(userData);
      // Validate token with backend
      const response = await axios.post(`${EndPoints.LOGIN_TELEGRAM_USER}`, {}, { headers });
      if (response.data.success) {
        setUser(userData);
        setIsAuthenticated(true);
      } else {
        logout();
      }
    } catch (error) {
      console.error('Auth validation error:', error);
      logout();
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Checks localStorage for existing user session
    const storedUser = localStorage.getItem('telegramUser');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      validateAndSetUser(userData);
    } else {
      setIsLoading(false);
    }
  }, [validateAndSetUser]);

  const login = async (userData: TelegramUser) => {
    try {
      const headers = generateHeaders(userData);
      // Send login request to backend
      const response = await axios.post(`${EndPoints.LOGIN_TELEGRAM_USER}`, {}, { headers });
      if (response.data.success) {
        localStorage.setItem('telegramUser', JSON.stringify(userData));
        setUser(userData);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('telegramUser');
    setUser(null);
    setIsAuthenticated(false);
  };

  const generateHeaders = (userData: TelegramUser) => {
    return {
      'x-telegram-id': userData.id.toString(),
      'x-telegram-auth-date': userData.auth_date.toString(),
      'x-telegram-hash': userData.hash,
      'x-telegram-first-name': userData.first_name || '',
      'x-telegram-last-name': userData.last_name || '',
      'x-telegram-username': userData.username || '',
      'x-telegram-photo-url': userData.photo_url || '',
    };
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, isLoading, login, logout, generateHeaders }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
