export const enContent = {
  title: 'Privacy Policy',
  introduction:
    'Welcome to Titrom. We are committed to protecting the privacy and security of your personal information. This policy outlines how we collect, use, store, and safeguard your information, as well as your rights in relation to this information. By using Titrom, you consent to the collection and use of your information as described in this Privacy Policy and our Terms of Service.',
  sections: [
    {
      title: 'Information We Collect',

      subsections: [
        {
          subtitle: 'We collect personal information that you provide directly to us when:',
          content:
            '• You register as a customer on our platform.\n• You create a store or place an order.\n• You engage in delivery transactions.\n• You authenticate using Telegram.',
        },
        {
          subtitle: 'Types of Information:',
          content:
            '• Name\n• Email address\n• Store name or industry type\n• Delivery details\n•Phone number (optional)\n• Telegram authentication information',
        },
      ],
    },
    {
      title: 'Legal Basis for Processing Information',
      content:
        'We process your personal information under the following legal bases:\n\n  • Legal Obligation: To comply with laws and regulations.\n• Legitimate Interests: For purposes such as improving our platform and preventing fraud.',
    },
    {
      title: 'How We Use Your Information',
      content:
        'We use your personal information to:\n\n• Register you as a new customer.\n• Process and deliver your orders.\n• Manage our relationship with you, including providing customer support.\n• Enhance and improve our website.\n• Recommend products or services.\n• Grant you access to products and services.\n• Ensure compliance with legal obligations.\n• Facilitate the delivery of products to buyers.',
    },
    {
      title: 'Data Retention',
      content:
        'We retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy or as required by law. Once this period expires, your data will be securely deleted or anonymized.',
    },
    {
      title: 'Data Security and Storage',
      subsections: [
        {
          subtitle: 'Encryption',
          content:
            'All messages between buyers and sellers on our platform are fully encrypted to ensure the security of your communication.',
        },
        {
          subtitle: 'Storage',
          content:
            'Your personal information is securely stored in our database and is protected from unauthorized access, use, or disclosure.',
        },
      ],
    },
    {
      title: 'Third-Party Services',
      content:
        'We may share your information with trusted third-party services to ensure the smooth operation of our platform, including:\n\n• Payment processors: For facilitating transactions.\n• Delivery partners: For completing delivery services. These third parties are committed to handling your information securely and in accordance with applicable laws.',
    },
    {
      title: 'Your Rights',
      content:
        'As a user, you have the following rights:\n\n• Access: Request access to your personal data\n• Correction: Request correction of incomplete data\n• Deletion: Request deletion of your information\n• Objection: Object to data processing\n• Data Portability: Request data copy\n• Withdraw Consent: Withdraw previous consent',
    },
    {
      title: 'Children’s Privacy',
      content:
        'Titrom does not knowingly collect personal information from individuals under the age of 18. If we discover that such information has been collected, we will take steps to delete it promptly.',
    },
    {
      title: 'Breach Notification',
      content:
        '1.Notify affected users promptly.\n\n 2.Report the breach to relevant authorities if required by law.\n\n 3.Take measures to mitigate potential risks and prevent future breaches.',
    },
    {
      title: 'Changes to This Privacy Policy',
      content:
        'We may update this Privacy Policy from time to time. You will be notified of any changes by posting the updated policy on this page. We encourage you to review this Privacy Policy periodically for updates.',
    },
    {
      title: 'Contact Us',
      content:
        'If you have any questions about this Privacy Policy or need assistance, please contact us: support@titrom.com',
    },
  ],
  footer: {
    Conclusion:
      'At Titrom, we are dedicated to safeguarding your personal information and enhancing your experience on our platform. We value your trust and are committed to maintaining the highest standards of data protection and transparency.',
  },
};
