import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './ComingSoon.module.css';
import LanguageSwitcher from '../Components/LanguageSwitcher';
import { useNavigate } from 'react-router-dom';

const ComingSoon: React.FC<{ isHeader?: boolean }> = ({ isHeader = true }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      {isHeader && (
        <Box className={styles.header}>
          <Container>
            <Box className={styles.headerContent}>
              <Typography variant="h4" className={styles.logo} onClick={() => navigate('/')}>
                {t('home.title')}
              </Typography>
              <LanguageSwitcher />
            </Box>
          </Container>
        </Box>
      )}

      <main>
        <Container>
          <Box className={styles.heroSection}>
            <div className={styles.heroContent}>
              <Typography variant="h1" className={styles.heroTitle}>
                {t('comingSoon.title')}
              </Typography>

              <Typography variant="h2" className={styles.heroSubtitle}>
                {t('comingSoon.subtitle')}
              </Typography>

              <Typography className={styles.description}>{t('comingSoon.description')}</Typography>

              <Box className={styles.sellerSection}>
                <Typography variant="h3" className={styles.sellerTitle}>
                  {t('comingSoon.seller.title')}
                </Typography>
                <Typography className={styles.sellerDescription}>{t('comingSoon.seller.description')}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.registerButton}
                  onClick={() => navigate('/home')}
                >
                  {t('comingSoon.seller.register')}
                </Button>
              </Box>

              <Box className={styles.contactSection}>
                <Typography variant="h3" className={styles.contactTitle}>
                  {t('comingSoon.contact')}
                </Typography>
                <Typography className={styles.contactInfo}>Email: support@titrom.com</Typography>
                <Typography className={styles.contactInfo}>WhatsApp: +212 38 48 18 37</Typography>
              </Box>
            </div>
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default ComingSoon;
