import { Helmet } from 'react-helmet-async';

interface SEOMetaTagsProps {
  title: string;
  description: string;
  keywords?: string;
  image?: string; //Custom image URL for social sharing. If not provided, it will use the default logo
  url?: string;
  language: string;
}

export const SEOMetaTags: React.FC<SEOMetaTagsProps> = ({ title, description, keywords, image, url, language }) => {
  const baseUrl = 'https://titrom.com';
  const finalUrl = url ? `${baseUrl}${url}` : baseUrl;
  const finalImage = image || 'https://titrom.com/logo/logo-1200x630.png';

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={finalImage} />
      <meta property="og:url" content={finalUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={finalImage} />

      <link rel="canonical" href={finalUrl} />
      <meta name="language" content={language} />
    </Helmet>
  );
};
