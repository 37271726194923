import React, { useState, useRef } from 'react';
import { Box, Paper, Typography, Popper, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Category {
  id: number;
  name: string;
  path: string;
  slug: string;
  description?: string;
  level: number;
  children?: Category[];
}

interface MenuItemProps {
  category: Category;
  onSelect: (category: Category) => void;
}

const MenuContainer = styled(Box)<{ level?: number }>(({ theme, level = 0 }) => ({
  position: 'relative',
  cursor: 'pointer',
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor:
    level === 0 ? theme.palette.background.paper : level === 1 ? theme.palette.grey[100] : theme.palette.grey[200],
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SubMenu = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  minWidth: 200,
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
    borderRadius: 0,
    marginLeft: theme.spacing(2),
  },
}));

const PopperWrapper = styled(Box)({
  '&:hover': {
    '& > .MuiPaper-root': {
      visibility: 'visible',
    },
  },
});

const StyledArrow = styled(ArrowForwardIosIcon)<{ open?: boolean }>(({ theme, open }) => ({
  fontSize: '0.8rem',
  marginLeft: theme.spacing(1),
  transition: 'transform 0.2s',
  [theme.breakpoints.up('sm')]: {
    transform: document.dir === 'rtl' ? 'rotate(180deg)' : 'none',
  },
  [theme.breakpoints.down('sm')]: {
    transform: open ? 'rotate(90deg)' : 'none',
  },
}));

const MenuItemCompo: React.FC<MenuItemProps> = ({ category, onSelect }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasChildren = category.children && category.children.length > 0;

  const handleMouseEnter = () => {
    if (!isMobile && hasChildren) {
      setShowSubmenu(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile && hasChildren) {
      setShowSubmenu(false);
    }
  };

  const handleClick = (selectedCategory: Category, event: React.MouseEvent) => {
    event.stopPropagation();
    if (hasChildren) {
      if (isMobile) {
        setShowSubmenu(!showSubmenu);
      }
    } else {
      onSelect(selectedCategory);
    }
  };

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MenuContainer
        ref={anchorRef}
        onClick={e => handleClick(category, e)}
        data-has-children={hasChildren}
        level={category.level}
      >
        <Typography variant="body1">{category.name}</Typography>
        {hasChildren && <StyledArrow open={showSubmenu} />}
      </MenuContainer>

      {hasChildren &&
        (isMobile ? (
          <Box
            sx={{
              height: showSubmenu ? 'auto' : 0,
              overflow: 'hidden',
              transition: 'height 0.3s ease',
            }}
          >
            <SubMenu>
              {category.children?.map(child => (
                <MenuItemCompo key={child.id} category={child} onSelect={onSelect} />
              ))}
            </SubMenu>
          </Box>
        ) : (
          <Popper
            open={showSubmenu}
            anchorEl={anchorRef.current}
            placement={document.dir === 'rtl' ? 'left-start' : 'right-start'}
            sx={{
              zIndex: 1300,
              pointerEvents: 'none',
            }}
          >
            <PopperWrapper
              sx={{
                pointerEvents: 'auto',
              }}
            >
              <SubMenu>
                {category.children?.map(child => (
                  <MenuItemCompo key={child.id} category={child} onSelect={onSelect} />
                ))}
              </SubMenu>
            </PopperWrapper>
          </Popper>
        ))}
    </Box>
  );
};

export default MenuItemCompo;
