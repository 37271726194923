import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  Avatar,
  Chip,
  Skeleton,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EmailIcon from '@mui/icons-material/Email';
import LoginIcon from '@mui/icons-material/Login';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './Product.module.css';
import EndPoints from '../EndPointConfig';
import RatingComponent from '../Components/RatingComponent';
import OrderSuccessModal from '../Components/modals/OrderSuccessModal';
import { useTranslation } from 'react-i18next';
import { SEOMetaTags } from '../Components/SEO/SEOMetaTags';
import { ProductStructuredData } from '../Components/SEO/ProductStructuredData';
import { useAuth } from '../contexts/AuthContext';

interface ProductDetails {
  id: string;
  name: string;
  description: string;
  price: string | number;
  stock: number;
  categoryId: number;
  isActive: boolean;
  minOrder: number;
  attributes?: Record<string, string[]> | null;
  ProductImages: Array<{
    imageUrl: string;
    isMain: boolean;
    displayOrder: number;
  }>;
  Seller: {
    id: number;
    name: string;
    storeName: string;
    profilePhoto: string;
  };
  category?: {
    id: number;
    name: string;
    path: string;
    slug: string;
    parent?: {
      id: number;
      name: string;
      path: string;
    };
  };
  averageRating: number;
  totalReviews: number;
  Ratings?: Array<{
    id: number;
    rating: number;
    comment: string;
    createdAt: string;
    User?: {
      name: string;
      profilePhoto: string;
    };
  }>;
}

interface OrderDialogState {
  open: boolean;
  deliveryAddress: string;
  phoneNumber: string;
  buyerCity: string;
  buyerName: string;
}

const Product: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { isAuthenticated, user, generateHeaders } = useAuth();
  const [product, setProduct] = useState<ProductDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(() => {
    if (!product) return 1;
    return Math.max(product.minOrder || 1, Math.min(product.stock, 1));
  });
  const [feedback, setFeedback] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info' | 'warning',
  });
  const [orderDialog, setOrderDialog] = useState<OrderDialogState>({
    open: false,
    deliveryAddress: '',
    phoneNumber: '',
    buyerCity: '',
    buyerName: '',
  });
  const [selectedAttributes, setSelectedAttributes] = useState<Record<string, string>>({});
  const [hasUserRated, setHasUserRated] = useState(false);
  const [successModal, setSuccessModal] = useState<{ open: boolean; orderId?: string | number }>({
    open: false,
    orderId: undefined,
  });
  const [isCurrentUserSeller, setIsCurrentUserSeller] = useState(false);
  const [categoryTranslation, setCategoryTranslation] = useState<string | null>(null);

  const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
    if (isNaN(numericPrice)) {
      return '0';
    }
    const currencyFormat = t('currency.format');
    const formattedPrice = numericPrice
      .toFixed(Number(t('currency.precision')))
      .replace('.', t('currency.decimal'))
      .replace(/\B(?=(\d{3})+(?!\d))/g, t('currency.thousand'));
    return currencyFormat.replace('{amount}', formattedPrice);
  };

  const productId = slug?.split('-').pop();

  const fetchProduct = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${EndPoints.GET_PRODUCTS}/${productId}`);
      const transformedProduct = {
        ...response.data,
        attributes:
          typeof response.data.attributes === 'string'
            ? JSON.parse(response.data.attributes)
            : response.data.attributes,
      };
      setProduct(transformedProduct);
      setSelectedImage(
        response.data.ProductImages.find((img: any) => img.isMain)?.imageUrl || response.data.ProductImages[0]?.imageUrl
      );
    } catch (error) {
      console.error('Error fetching product:', error);
      setError('Failed to load product details');
    } finally {
      setLoading(false);
    }
  }, [productId]);

  const fetchCategoryTranslation = useCallback(
    async (id: number) => {
      try {
        const response = await axios.get(`${EndPoints.CATEGORIES}/${id}/translation?lang=${i18n.language}`);
        setCategoryTranslation(response.data);
      } catch (error) {
        console.error('Error fetching category translation:', error);
      }
    },
    [i18n.language]
  );

  const checkUserRating = useCallback(async () => {
    if (!isAuthenticated || !user) return;
    try {
      const headers = generateHeaders(user);
      const response = await axios.get(`${EndPoints.RATING}/check/${productId}`, {
        headers,
      });
      setHasUserRated(response.data.hasRated);
    } catch (error) {
      console.error('Error checking user rating:', error);
    }
  }, [productId, isAuthenticated, user, generateHeaders]);

  const checkIfCurrentUserIsSeller = useCallback(async () => {
    if (!isAuthenticated || !user) return;
    try {
      const headers = generateHeaders(user);
      const response = await axios.get(EndPoints.GET_SELLER, {
        headers,
      });
      setIsCurrentUserSeller(response.data.id === product?.Seller?.id);
    } catch (error) {
      console.error('Error checking user status:', error);
    }
  }, [isAuthenticated, user, generateHeaders, product?.Seller?.id]);

  useEffect(() => {
    if (productId) {
      fetchProduct();
      checkUserRating();
      checkIfCurrentUserIsSeller();
    }
  }, [productId, fetchProduct, checkUserRating, checkIfCurrentUserIsSeller]);

  useEffect(() => {
    if (product?.category?.id) {
      fetchCategoryTranslation(product.category.id);
    }
  }, [product?.category?.id, fetchCategoryTranslation]);

  const handleQuantityChange = (value: number) => {
    if (value >= (product?.minOrder || 1) && value <= (product?.stock || 1)) {
      setQuantity(value);
    }
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: product?.name,
        text: product?.description,
        url: window.location.href,
      });
    } catch (error) {
      // Fallback to copying link
      navigator.clipboard.writeText(window.location.href);
      setFeedback({
        open: true,
        message: 'Link copied to clipboard!',
        severity: 'success',
      });
    }
  };

  const handleBuyNow = async () => {
    const headers = isAuthenticated && user ? generateHeaders(user) : {};

    try {
      const orderData = {
        productId: product?.id,
        quantity: quantity,
        deliveryAddress: orderDialog.deliveryAddress,
        phoneNumber: orderDialog.phoneNumber,
        buyerCity: orderDialog.buyerCity,
        buyerName: orderDialog.buyerName,
        attributes: selectedAttributes,
      };

      const response = await axios.post(EndPoints.CREATE_ORDER, orderData, {
        headers,
      });

      if (response.status === 201) {
        setOrderDialog({
          open: false,
          deliveryAddress: '',
          phoneNumber: '',
          buyerCity: '',
          buyerName: '',
        });
        setSuccessModal({
          open: true,
          orderId: response.data.id,
        });
      }
    } catch (error) {
      console.error('Error creating order:', error);
      setFeedback({
        open: true,
        message: 'Failed to place order. Please try again.',
        severity: 'error',
      });
    }
  };

  const isOrderable = (stock: number, minOrder: number): boolean => {
    return stock >= minOrder;
  };

  const isValidOrder = () => {
    const nameValid = orderDialog.buyerName.trim().length >= 3 && orderDialog.buyerName.trim().length <= 50;
    const phoneValid = orderDialog.phoneNumber.trim().length === 10;
    const cityValid = orderDialog.buyerCity.trim().length >= 2 && orderDialog.buyerCity.trim().length <= 30;
    const addressValid =
      orderDialog.deliveryAddress.trim().length >= 3 && orderDialog.deliveryAddress.trim().length <= 200;

    return nameValid && phoneValid && cityValid && addressValid;
  };

  if (loading) {
    return (
      <Container maxWidth="lg" className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={400} className={styles.skeletonImage} />
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={1}>
                {[1, 2, 3, 4].map(i => (
                  <Grid item xs={3} key={i}>
                    <Skeleton variant="rectangular" height={80} className={styles.skeletonThumbnail} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="text" height={40} width="30%" sx={{ animation: 'wave' }} />
            <Skeleton variant="text" height={60} sx={{ animation: 'wave' }} />
            <Skeleton variant="text" height={40} width="20%" sx={{ animation: 'wave' }} />
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (error || !product) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error || 'Product not found'}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" className={styles.container}>
      {product && (
        <>
          <SEOMetaTags
            title={`${product.name} | ${product.Seller?.storeName} | Titrom`}
            description={product.description.slice(0, 160)}
            keywords={`${categoryTranslation}, ${product.name}, wholesale, ${t('seo.product.keywords')}`}
            image={product.ProductImages.find(img => img.isMain)?.imageUrl || product.ProductImages[0]?.imageUrl}
            url={`/product/${slug}`}
            language={i18n.language}
          />
          <ProductStructuredData
            name={product.name}
            description={product.description}
            image={product.ProductImages.find(img => img.isMain)?.imageUrl || product.ProductImages[0]?.imageUrl}
            price={Number(product.price)}
            currency={t('currency.code')}
            seller={product.Seller?.storeName}
          />
        </>
      )}

      <Box className={styles.headerBox}>
        <Button
          startIcon={<ArrowBackIcon className={styles.backButtonIcon} />}
          onClick={() => navigate(-1)}
          className={styles.backButton}
        >
          {t('product.back')}
        </Button>
        <IconButton onClick={handleShare} color="primary" className={styles.shareButton}>
          <ShareIcon />
        </IconButton>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper className={styles.productPaper}>
            <Box className={styles.mainImageBox}>
              <img src={selectedImage} alt={product.name} className={styles.mainImage} />
              <Box className={styles.zoomOverlay} />
            </Box>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              {product.ProductImages.map(image => (
                <Grid item xs={3} key={image.imageUrl}>
                  <Box
                    className={styles.thumbnailBox}
                    sx={{
                      borderRadius: '8px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      border: selectedImage === image.imageUrl ? '2px solid #1976d2' : '2px solid transparent',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      },
                    }}
                    onClick={() => setSelectedImage(image.imageUrl)}
                  >
                    <img src={image.imageUrl} alt={product.name} className={styles.thumbnailImage} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>

          <Paper
            elevation={0}
            sx={{
              p: 3,
              mt: 3,
              borderRadius: 3,
              bgcolor: '#f8f9fa',
              border: '1px solid #e0e0e0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                mb: 2.5,
                pb: 2,
                borderBottom: '2px solid #e3f2fd',
              }}
            >
              <StorefrontIcon
                sx={{
                  color: '#1976d2',
                  fontSize: '1.2rem',
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  background: 'linear-gradient(45deg, #1976d2, #1565c0)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  letterSpacing: '0.5px',
                  textTransform: 'uppercase',
                  fontSize: '0.9rem',
                }}
              >
                {t('product.sellerInfo')}
              </Typography>
            </Box>

            <Box className={styles.sellerBox}>
              <Box className={styles.sellerInfo}>
                <Box
                  className={styles.sellerProfile}
                  onClick={() => navigate(`/user-profile/${product.Seller?.id}`)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                >
                  <Avatar src={product.Seller?.profilePhoto} alt={product.Seller?.storeName}>
                    <StorefrontIcon />
                  </Avatar>
                  <Box sx={{ minWidth: 0 }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                      }}
                    >
                      {product.Seller?.storeName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                      }}
                    >
                      {isCurrentUserSeller ? t('product.youAreSeller') : product.Seller?.name}
                    </Typography>
                  </Box>
                </Box>
                {!isCurrentUserSeller && (
                  <Button
                    className={styles.messageSellerButton}
                    startIcon={<EmailIcon />}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      if (!isAuthenticated) {
                        setFeedback({
                          open: true,
                          message: t('product.loginToMessage'),
                          severity: 'info',
                        });
                        return;
                      }
                      navigate(`/messages?seller=${product.Seller.id}`);
                    }}
                  >
                    {t('product.messageSeller')}
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ position: 'sticky', top: 100 }}>
            <Box sx={{ mb: 3, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <Chip
                label={categoryTranslation}
                sx={{
                  bgcolor: '#e3f2fd',
                  color: '#1976d2',
                  fontWeight: 500,
                  '&:hover': { bgcolor: '#bbdefb' },
                }}
              />
              <Chip
                label={
                  !isOrderable(product.stock, product.minOrder)
                    ? t('product.stockBelowMin', { stock: product.stock, minOrder: product.minOrder })
                    : product.stock > 0
                    ? t('product.inStock', { stock: product.stock })
                    : t('product.outOfStock')
                }
                color={
                  !isOrderable(product.stock, product.minOrder) ? 'warning' : product.stock > 0 ? 'success' : 'error'
                }
                sx={{ fontWeight: 500 }}
              />
              {!product.isActive && (
                <Chip
                  icon={<VisibilityOffIcon />}
                  label={t('product.productUnavailable')}
                  sx={{
                    bgcolor: '#fff3e0',
                    color: '#ed6c02',
                    fontWeight: 500,
                    '& .MuiChip-icon': {
                      color: '#ed6c02',
                    },
                    '&:hover': { bgcolor: '#ffe0b2' },
                  }}
                />
              )}
            </Box>

            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontFamily: '"Inter", "Roboto", "Helvetica Neue", sans-serif',
                fontWeight: 600,
                color: '#2f3542',
                letterSpacing: '-0.5px',
                wordBreak: 'break-word',
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.5rem',
                  md: '1.75rem',
                },
                lineHeight: 1.2,
                mb: 2,
              }}
            >
              {product.name}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Rating value={product.averageRating} precision={0.5} readOnly />
              <Typography variant="body2" color="text.secondary">
                ({product.totalReviews} {product.totalReviews === 1 ? 'review' : 'reviews'})
              </Typography>
            </Box>

            <Typography
              variant="h5"
              sx={{
                color: '#1976d2',
                fontWeight: 600,
                mb: 3,
              }}
            >
              {formatPrice(product.price)}
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#666666',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {t('product.minOrder', { count: product.minOrder })}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              {product.attributes && Object.keys(product.attributes).length > 0 && (
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    bgcolor: '#f8f9fa',
                    borderRadius: 3,
                    border: '1px solid #e0e0e0',
                    mb: 3,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      mb: 2,
                      color: '#1a237e',
                    }}
                  >
                    {t('product.specifications')}
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(product.attributes).map(([key, values]) => (
                      <Grid item xs={12} sm={6} key={key}>
                        <Box sx={{ mb: 2 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: '#666666',
                              fontWeight: 500,
                              mb: 1,
                            }}
                          >
                            {key}
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {Array.isArray(values) &&
                              values.map((value, index) => (
                                <Chip
                                  key={index}
                                  label={value}
                                  onClick={() => {
                                    setSelectedAttributes(prev => ({
                                      ...prev,
                                      [key]: value,
                                    }));
                                  }}
                                  variant="filled"
                                  sx={{
                                    bgcolor: selectedAttributes[key] === value ? '#1976d2' : '#e3f2fd',
                                    color: selectedAttributes[key] === value ? '#ffffff' : '#1976d2',
                                    cursor: 'pointer',
                                    '&:hover': {
                                      bgcolor: selectedAttributes[key] === value ? '#1565c0' : '#bbdefb',
                                    },
                                  }}
                                />
                              ))}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              )}
            </Box>

            <Box sx={{ mb: 4 }}>
              {/* here I make the buy now available for all users even if they are not logged in
              when I want to make it available only for logged in users I will use isAuthenticated*/}
              {true ? (
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: 3,
                    bgcolor: '#f8f9fa',
                    border: '1px solid #e0e0e0',
                  }}
                >
                  {isCurrentUserSeller ? (
                    <Typography color="text.secondary" align="center">
                      {t('product.youAreSeller')}
                    </Typography>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                      <Box className={styles.quantityBox}>
                        <IconButton
                          size="small"
                          onClick={() => handleQuantityChange(quantity - 1)}
                          disabled={quantity <= product.minOrder}
                          className={styles.quantityButton}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <TextField
                          size="small"
                          value={quantity}
                          onChange={e => handleQuantityChange(parseInt(e.target.value) || product.minOrder)}
                          inputProps={{
                            min: product.minOrder,
                            max: product.stock,
                            type: 'number',
                            style: { textAlign: 'center', width: '40px' },
                          }}
                          className={styles.quantityInput}
                        />
                        <IconButton
                          size="small"
                          onClick={() => handleQuantityChange(quantity + 1)}
                          disabled={quantity >= product.stock}
                          className={styles.quantityButton}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                      <Button
                        variant="contained"
                        onClick={() => setOrderDialog({ ...orderDialog, open: true })}
                        className={styles.buyNowButton}
                        disabled={!isOrderable(product.stock, product.minOrder)}
                      >
                        {t('product.buyNow')}
                      </Button>
                    </Box>
                  )}
                </Paper>
              ) : (
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    bgcolor: '#f8f9fa',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.primary', fontWeight: 500 }}>
                      {t('product.wantToPurchase')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t('product.loginToBuy')}
                    </Typography>
                  </Box>
                  <Button
                    className={styles.loginButton}
                    variant="contained"
                    color="primary"
                    startIcon={<LoginIcon />}
                    onClick={() => {
                      navigate('/login', { state: { from: window.location.pathname } });
                    }}
                    sx={{
                      px: 3,
                      py: 1,
                      fontSize: '0.9rem',
                      textTransform: 'none',
                      borderRadius: 2,
                      whiteSpace: 'nowrap',
                      boxShadow: 1,
                      '&:hover': {
                        boxShadow: 2,
                        transform: 'translateY(-1px)',
                      },
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    {t('product.login')}
                  </Button>
                </Paper>
              )}
            </Box>

            <Paper
              elevation={0}
              sx={{
                p: 3,
                mb: 4,
                bgcolor: '#f8f9fa',
                borderRadius: 3,
                border: '1px solid #e0e0e0',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                  color: '#1a237e',
                }}
              >
                {t('product.description')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1.8,
                  color: '#424242',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                }}
              >
                {product.description}
              </Typography>
            </Paper>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              p: 4,
              mt: 4,
              bgcolor: '#fff',
              borderRadius: 3,
              border: '1px solid #e0e0e0',
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: '#1a237e' }}>
              {t('product.customerReviews')}
            </Typography>
            {!isCurrentUserSeller ? (
              !hasUserRated ? (
                <RatingComponent
                  productId={Number(productId)}
                  onRatingSubmitted={() => {
                    fetchProduct();
                    setHasUserRated(true);
                  }}
                />
              ) : (
                <Typography color="text.secondary" sx={{ mt: 2, mb: 3 }}>
                  {t('product.alreadyRated')}
                </Typography>
              )
            ) : (
              <Typography color="text.secondary" sx={{ mt: 2, mb: 3 }}>
                {t('product.cannotRateOwn')}
              </Typography>
            )}
            {product.Ratings && product.Ratings.length > 0 ? (
              <Box sx={{ mt: 3 }}>
                {product.Ratings.map(rating => (
                  <Box key={rating.id} sx={{ mb: 2, p: 2, bgcolor: '#f8f9fa', borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <Avatar src={rating.User?.profilePhoto} alt={rating.User?.name} />
                      <Typography variant="subtitle2">{rating.User?.name}</Typography>
                    </Box>
                    <Rating value={rating.rating} readOnly size="small" />
                    {rating.comment && (
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {rating.comment}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography color="text.secondary">{t('product.noReviews')}</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={orderDialog.open}
        onClose={() => setOrderDialog({ ...orderDialog, open: false })}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: '600px',
          },
        }}
      >
        <DialogTitle
          component="div"
          sx={{
            borderBottom: '1px solid #e0e0e0',
            px: 3,
            py: 2,
            bgcolor: '#f8f9fa',
          }}
        >
          <Typography variant="h6" component="h2" fontWeight={600}>
            {t('product.completeOrder')}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ p: 3 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" component="h3" fontWeight={600} color="primary" gutterBottom>
              {t('product.orderSummary')}
            </Typography>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                bgcolor: '#f8f9fa',
                borderRadius: 2,
                border: '1px solid #e0e0e0',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">
                    {t('product.product')}
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    {product.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">
                    {t('product.quantity')}
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    {quantity} units
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">
                    {t('product.pricePerUnit')}
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    {formatPrice(product.price)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="text.secondary">
                    {t('product.totalAmount')}
                  </Typography>
                  <Typography variant="h6" component="div" color="primary" fontWeight={600}>
                    {formatPrice(Number(product.price) * quantity)}
                  </Typography>
                </Grid>

                {Object.keys(selectedAttributes).length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {t('product.selectedSpecs')}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                      {Object.entries(selectedAttributes).map(([key, value]) => (
                        <Chip
                          key={key}
                          label={`${key}: ${value}`}
                          size="small"
                          sx={{ bgcolor: '#e3f2fd', color: '#1976d2' }}
                        />
                      ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Box>

          <Typography variant="subtitle1" component="h3" fontWeight={600} color="primary" sx={{ mb: 2 }}>
            {t('product.deliveryDetails')}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label={t('product.fullName')}
                value={orderDialog.buyerName}
                onChange={e => {
                  const value = e.target.value.slice(0, 20);
                  setOrderDialog({ ...orderDialog, buyerName: value });
                }}
                placeholder={t('product.placeholders.fullName')}
                variant="outlined"
                inputProps={{
                  maxLength: 20,
                }}
                InputProps={{
                  sx: { borderRadius: 1.5 },
                }}
                FormHelperTextProps={{
                  component: 'div',
                  sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                }}
                helperText={t('product.charactersCount', { current: orderDialog.buyerName.length, max: 20 })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label={t('product.phoneNumber')}
                value={orderDialog.phoneNumber}
                onChange={e => {
                  const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                  setOrderDialog({ ...orderDialog, phoneNumber: value });
                }}
                placeholder={t('product.placeholders.phoneNumber')}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 10,
                }}
                variant="outlined"
                InputProps={{
                  sx: { borderRadius: 1.5 },
                }}
                FormHelperTextProps={{
                  component: 'div',
                  sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                }}
                helperText={t('product.digitsCount', { current: orderDialog.phoneNumber.length, max: 10 })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label={t('product.city')}
                value={orderDialog.buyerCity}
                onChange={e => {
                  const value = e.target.value.slice(0, 30);
                  setOrderDialog({ ...orderDialog, buyerCity: value });
                }}
                placeholder={t('product.placeholders.city')}
                variant="outlined"
                inputProps={{
                  maxLength: 30,
                }}
                InputProps={{
                  sx: { borderRadius: 1.5 },
                }}
                FormHelperTextProps={{
                  component: 'div',
                  sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                }}
                helperText={t('product.charactersCount', { current: orderDialog.buyerCity.length, max: 30 })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label={t('product.deliveryAddress')}
                multiline
                rows={3}
                value={orderDialog.deliveryAddress}
                onChange={e => {
                  const value = e.target.value.slice(0, 100);
                  setOrderDialog({ ...orderDialog, deliveryAddress: value });
                }}
                placeholder={t('product.placeholders.deliveryAddress')}
                variant="outlined"
                inputProps={{
                  maxLength: 100,
                }}
                InputProps={{
                  sx: { borderRadius: 1.5 },
                }}
                FormHelperTextProps={{
                  component: 'div',
                  sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                  },
                }}
                helperText={t('product.charactersCount', { current: orderDialog.deliveryAddress.length, max: 100 })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            py: 2.5,
            borderTop: '1px solid #e0e0e0',
            bgcolor: '#f8f9fa',
          }}
        >
          <Button
            onClick={() => setOrderDialog({ ...orderDialog, open: false })}
            sx={{
              color: 'text.secondary',
              px: 3,
            }}
          >
            {t('product.buttons.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleBuyNow}
            disabled={!isValidOrder()}
            sx={{
              px: 4,
              py: 1,
              borderRadius: 1.5,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 3,
              },
            }}
          >
            {t('product.buttons.placeOrder')}
          </Button>
        </DialogActions>
      </Dialog>

      <OrderSuccessModal
        open={successModal.open}
        orderId={successModal.orderId}
        onClose={() => setSuccessModal({ open: false })}
      />

      <Snackbar open={feedback.open} autoHideDuration={6000} onClose={() => setFeedback({ ...feedback, open: false })}>
        <Alert severity={feedback.severity} onClose={() => setFeedback({ ...feedback, open: false })}>
          {feedback.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Product;
