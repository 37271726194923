import i18n from '../config/i18n';

export const formatDate = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString(i18n.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatPrice = (price: number | string): string => {
  // Convert price to number if it's a string
  const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

  // Check if it's a valid number
  if (isNaN(numericPrice)) {
    return '0';
  }

  const currencyFormat = i18n.t('currency.format');
  const formattedPrice = numericPrice
    .toFixed(Number(i18n.t('currency.precision')))
    .replace('.', i18n.t('currency.decimal'))
    .replace(/\B(?=(\d{3})+(?!\d))/g, i18n.t('currency.thousand'));

  return currencyFormat.replace('{amount}', formattedPrice);
};

export const truncateText = (text: string, maxLength: number, mobileMaxLength?: number) => {
  if (!text) return ''; // Return an empty string if text is null or undefined

  //mobileMaxLength is optional, if not provided, use maxLength
  const isMobile = window.innerWidth <= 600; //screen size of mobile
  const limit = isMobile && mobileMaxLength ? mobileMaxLength : maxLength;

  if (text.length <= limit) return text;
  return `${text.slice(0, limit)}...`;
};
