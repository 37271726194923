import React, { useState } from 'react';
import { Rating, Box, Typography, TextField, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import EndPoints from '../EndPointConfig';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface RatingComponentProps {
  productId: number;
  onRatingSubmitted: () => void;
}

const RatingComponent: React.FC<RatingComponentProps> = ({ productId, onRatingSubmitted }) => {
  const { t } = useTranslation();
  const { isAuthenticated, user, generateHeaders } = useAuth();
  const navigate = useNavigate();
  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!isAuthenticated || !user) {
      navigate('/login', { state: { from: window.location.pathname } });
      return;
    }

    if (!rating) return;

    try {
      setSubmitting(true);
      const headers = generateHeaders(user);

      await axios.post(
        `${EndPoints.RATING}`,
        {
          productId,
          rating,
          comment,
        },
        { headers }
      );
      setRating(null);
      setComment('');
      onRatingSubmitted();
    } catch (error) {
      console.error('Error submitting rating:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        {t('rating.title')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Rating value={rating} onChange={(_, newValue) => setRating(newValue)} size="large" />
        <TextField
          multiline
          rows={3}
          value={comment}
          onChange={e => setComment(e.target.value)}
          placeholder={t('rating.reviewPlaceholder')}
        />
        <Button variant="contained" onClick={handleSubmit} disabled={!rating || submitting}>
          {t('rating.submitButton')}
        </Button>
      </Box>
    </Box>
  );
};

export default RatingComponent;
