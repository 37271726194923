export const enContent = {
  title: 'Who We Are?',
  introduction:
    "Welcome to Titrom, your trusted platform for seamless wholesale buying and selling in Morocco. Whether you're a seller looking to showcase your products or a buyer searching for the best deals, Titrom provides an intuitive, secure, and transparent marketplace designed with your needs in mind.",
  sections: [
    {
      title: 'Our Vision',
      content:
        'At Titrom, we believe in the power of local businesses and the potential of connecting wholesalers and buyers in an efficient and transparent way. We’re here to explore what works for you, gather your feedback, and continuously improve our platform to ensure it becomes a cornerstone of the wholesale ecosystem in Morocco.',
    },
    {
      title: 'What We Offer Currently',
      subsections: [
        {
          subtitle: 'Wholesale Marketplace',
          content:
            'Titrom enables sellers to list their products with clear terms, including minimum order quantities, to meet the specific needs of wholesale buyers.',
        },
        {
          subtitle: 'Ease of Use',
          content:
            'Buyers can browse products without an account, while sellers and buyers can engage in transactions effortlessly once registered.',
        },
        {
          subtitle: 'Cash on Delivery',
          content:
            'Our payment method ensures a secure and reliable transaction process, where buyers inspect goods before making payments.',
        },
        {
          subtitle: 'Ethical Standards',
          content:
            'We adhere to Islamic principles, prohibiting the sale of any immoral or prohibited materials on our platform.',
        },
      ],
    },
    {
      title: 'Why Choose Titrom?',
      subsections: [
        {
          subtitle: 'Free to Use',
          content:
            'Our platform is currently free for all users, making it accessible to businesses of all sizes. Future updates and premium services will be communicated transparently.',
        },
        {
          subtitle: 'User-Centric Features',
          content: 'From secure transactions to accurate product listings, we prioritize your experience and success.',
        },
      ],
    },

    {
      title: 'Join Us Today',
      content:
        "Whether you're a seasoned seller or a first-time buyer, Titrom is your partner in wholesale commerce. Register today to start buying or selling with confidence and discover the benefits of a marketplace built for you.",
    },
  ],
  footer: {
    contactInfo: "For any questions or support, feel free to contact us via email or WhatsApp. We're here to help!",
  },
};
