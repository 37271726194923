export const frContent = {
  title: 'Politique de confidentialité',
  introduction:
    "Bienvenue sur Titrom. Nous nous engageons à protéger la confidentialité et la sécurité de vos informations personnelles. Cette politique décrit comment nous collectons, utilisons, stockons et sécurisons vos informations, ainsi que vos droits en relation avec ces informations. En utilisant Titrom, vous consentez à la collecte et à l'utilisation de vos informations telles que décrites dans cette Politique de confidentialité et nos Conditions d'utilisation.",
  sections: [
    {
      title: 'Informations que nous collectons',

      subsections: [
        {
          subtitle: 'Nous collectons des informations personnelles que vous fournissez directement à nous lorsque :',
          content:
            '• Vous vous inscrivez en tant que client sur notre plateforme.\n• Vous créez un magasin ou passez une commande.\n• Vous engagez des transactions de livraison.\n• Vous authentifiez en utilisant Telegram.',
        },
        {
          subtitle: "Types d'informations :",
          content:
            "• Nom\n• Adresse e-mail\n• Nom du magasin ou type d'industrie\n• Détails de la livraison\n• Numéro de téléphone (optionnel)\n• Informations d'authentification Telegram",
        },
      ],
    },
    {
      title: 'Base légale pour le traitement des informations',
      content:
        "Nous traitons vos informations personnelles sur la base des lois suivantes :\n\n  • Obligation légale : Pour respecter les lois et réglementations.\n• Intérêts légitimes : Pour des objectifs tels que l'amélioration de notre plateforme et la prévention du fraude.",
    },
    {
      title: 'Comment nous utilisons vos informations',
      content:
        'Nous utilisons vos informations personnelles pour :\n\n• Vous inscrire en tant que nouveau client.\n• Traiter et livrer vos commandes.\n• Gérer notre relation avec vous, y compris en fournissant un support client.\n• Améliorer et améliorer notre site web.\n• Recommander des produits ou services.\n• Vous accorder accès aux produits et services.\n• Assurer le respect des obligations légales.\n• Faciliter la livraison des produits aux acheteurs.',
    },
    {
      title: 'Conservation des données',
      content:
        'Nous conservez vos informations personnelles uniquement pour la durée nécessaire pour répondre aux objectifs décrits dans cette politique ou en raison de la loi. Une fois cette période expirée, vos données seront sécurisément supprimées ou anonymisées.',
    },
    {
      title: 'Sécurité et stockage des données',
      subsections: [
        {
          subtitle: 'Chiffrement',
          content:
            'Tous les messages entre acheteurs et vendeurs sur notre plateforme sont entièrement chiffrés pour assurer la sécurité de votre communication.',
        },
        {
          subtitle: 'Stockage',
          content:
            "Vos informations personnelles sont stockées de manière sécurisée dans notre base de données et protégées contre l'accès non autorisé, l'utilisation ou la divulgation.",
        },
      ],
    },
    {
      title: 'Services tierces',
      content:
        'Nous pouvons partager vos informations avec des services tiers de confiance pour assurer le fonctionnement fluide de notre plateforme, y compris :\n\n• Processseurs de paiement : Pour faciliter les transactions.\n• Partenaires de livraison : Pour la livraison des services. Ces tiers sont engagés à traiter vos informations de manière sécurisée et conformément aux lois applicables.',
    },
    {
      title: 'Vos droits',
      content:
        "En tant qu'utilisateur, vous avez les droits suivants :\n\n• Accès : Demande d'accès à vos données personnelles\n• Correction : Demande de correction des données incomplètes\n• Suppression : Demande de suppression de vos informations\n• Objection : Objection au traitement des données\n• Portabilité des données : Demande de copie des données\n• Retrait de consentement : Retrait de consentement précédent",
    },
    {
      title: 'Confidentialité des enfants',
      content:
        "Titrom ne collecte pas volontairement des informations personnelles d'individus de moins de 18 ans. Si nous découvrons que de telles informations ont été collectées, nous prendrons les mesures nécessaires pour les supprimer rapidement.",
    },
    {
      title: "Notification d'une violation de données",
      content:
        '1.Notifier les utilisateurs affectés rapidement.\n\n 2.Notifier la violation aux autorités compétentes si nécessaire.\n\n 3.Prendre les mesures nécessaires pour atténuer les risques potentiels et empêcher les violations futures.',
    },
    {
      title: 'Modifications de cette Politique de confidentialité',
      content:
        'Nous pouvons mettre à jour cette Politique de confidentialité de temps à autre. Vous serez informé de toutes les modifications en publiant la politique mise à jour sur cette page. Nous vous encourageons à consulter cette Politique de confidentialité régulièrement pour les mises à jour.',
    },
    {
      title: 'Contactez-nous',
      content:
        "Si vous avez des questions concernant cette Politique de confidentialité ou besoin d'aide, veuillez nous contacter : privacy@titrom.com",
    },
  ],
  footer: {
    Conclusion:
      'À Titrom, nous nous engageons à protéger vos informations personnelles et à améliorer votre expérience sur notre plateforme. Nous apprécions votre confiance et nous nous engageons à maintenir les plus hauts standards de protection des données et de transparence.',
  },
};
