import React from 'react';
import { enContent } from './content/en';
import { arContent } from './content/ar';
import { frContent } from './content/fr';
import styles from './aboutUs.module.css';

interface ContentType {
  title: string;
  introduction: string;
  sections: {
    title: string;
    content?: string;
    subsections?: {
      subtitle: string;
      content: string;
    }[];
  }[];
  footer: {
    contactInfo: string;
  };
}

interface AboutUsProps {
  language: 'en' | 'ar' | 'fr';
}

const AboutUs: React.FC<AboutUsProps> = ({ language }) => {
  const content: { [key: string]: ContentType } = {
    en: enContent,
    ar: arContent,
    fr: frContent,
  };

  const currentContent = content[language] || content.en;

  return (
    <div className={`${styles.aboutContainer} ${language === 'ar' ? styles.rtl : styles.ltr}`}>
      <h1 className={styles.aboutTitle}>{currentContent.title}</h1>

      <div className={styles.introduction}>
        <p>{currentContent.introduction}</p>
      </div>

      <div className={styles.sections}>
        {currentContent.sections.map((section, index) => (
          <div key={index} className={styles.section}>
            <h2>{section.title}</h2>

            {section.content && (
              <div className={styles.content}>
                {section.content.split('\n').map((paragraph, pIndex) => (
                  <p key={pIndex}>{paragraph.trim()}</p>
                ))}
              </div>
            )}

            {section.subsections && (
              <div className={styles.subsections}>
                {section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} className={styles.subsection}>
                    <h3>{subsection.subtitle}</h3>
                    <div className={styles.content}>
                      <p>{subsection.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <footer className={styles.aboutFooter}>
        <p>{currentContent.footer.contactInfo}</p>
      </footer>
    </div>
  );
};

export default AboutUs;
