import { Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

import Login from './Pages/Login';

import Home from './Pages/Home';
import UserDashboard from './Pages/UserDashboard';
import Product from './Pages/Product';
import MessagePage from './Pages/MessagePage';
import { SocketProvider } from './contexts/SocketContext';
import UserProfilePage from './Pages/UserProfilePage';

import './config/i18n';
import { useTranslation } from 'react-i18next';
import ComingSoon from './Pages/ComingSoon';
import Terms from './Pages/FotterPages/terms/terms';
import AboutUs from './Pages/FotterPages/aboutUs/aboutUs';
import Privacy from './Pages/FotterPages/privacy/privacy';
import FAQ from './Pages/FotterPages/faq/faq';
import { useEffect } from 'react';
import ProtectedRoute from './Components/ProtectedRoute';

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  // Handle language based on URL
  useEffect(() => {
    const lang = pathname.split('/')[1];
    if (lang && ['ar', 'fr'].includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [pathname, i18n]);

  // Global RTL/LTR handling
  document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <AuthProvider>
      <SocketProvider>
        <Routes>
          <Route path="/">
            <Route path=":lang?">
              <Route index element={<Home />} />
              <Route path="product/:slug" element={<Product />} />
              <Route path="coming-soon" element={<ComingSoon />} />
              <Route path="login" element={<Login />} />
              <Route path="user-profile/:slug" element={<UserProfilePage />} />
              {/* footer pages */}
              <Route path="terms" element={<Terms language={i18n.language as 'ar' | 'fr' | 'en'} />} />
              <Route path="about" element={<AboutUs language={i18n.language as 'ar' | 'fr' | 'en'} />} />
              <Route path="privacy" element={<Privacy language={i18n.language as 'ar' | 'fr' | 'en'} />} />
              <Route path="faq" element={<FAQ language={i18n.language as 'ar' | 'fr' | 'en'} />} />
              {/* protected routes */}
              <Route
                path="messages"
                element={
                  <ProtectedRoute>
                    <MessagePage onMessagesRead={() => {}} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="User-Dashboard"
                element={
                  <ProtectedRoute>
                    <UserDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <UserDashboard />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Routes>
      </SocketProvider>
    </AuthProvider>
  );
};

export default App;
