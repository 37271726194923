import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import EndPoints from '../EndPointConfig';
import { Send as SendIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import styles from './MessagePage.module.css';
import { useSocket } from '../contexts/SocketContext';
import { useTranslation } from 'react-i18next';
import { truncateText } from '../utils/formatters';
import i18n from '../config/i18n';

interface Message {
  id: number;
  content: string;
  senderId: number;
  receiverId: number;
  isRead: boolean;
  createdAt: string;
  conversationId: string;
  Sender: {
    id: number;
    name: string;
    profilePhoto: string;
    storeName: string;
  };
  Receiver: {
    id: number;
    name: string;
    profilePhoto: string;
    storeName: string;
  };
}

interface Conversation {
  otherUser: number;
  lastMessage?: Message;
  unreadCount: number;
}

interface MessagePageProps {
  onMessagesRead: () => void;
}

const MessagePage: React.FC<MessagePageProps> = ({ onMessagesRead }) => {
  const { user, generateHeaders } = useAuth();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [initialMessage, setInitialMessage] = useState('');
  const [newSellerId, setNewSellerId] = useState<string | null>(null);
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);
  const location = useLocation();
  const socket = useSocket();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sellerId = params.get('seller');
    if (sellerId) {
      setNewSellerId(sellerId);
    }
  }, [location]);

  // Fetch conversations
  const fetchConversations = useCallback(async () => {
    try {
      if (!user) return;
      const headers = generateHeaders(user);
      const response = await axios.get(EndPoints.GET_CONVERSATIONS, {
        headers: headers,
      });
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  }, [user, generateHeaders]);

  // Fetch messages for selected conversation
  const fetchMessages = useCallback(
    async (conversationId: string, pageNum: number = 0) => {
      try {
        if (!user) return;
        const headers = generateHeaders(user);
        const response = await axios.get(`${EndPoints.GET_MESSAGES}/${conversationId}`, {
          params: { page: pageNum },
          headers: headers,
        });

        if (pageNum === 0) {
          setMessages(response.data.messages);
        } else {
          setMessages(prev => [...response.data.messages, ...prev]);
        }

        setHasMore(response.data.hasMore);
        onMessagesRead();
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    [user, generateHeaders, onMessagesRead]
  );

  // Add a function to load more messages
  const loadMoreMessages = async () => {
    if (!selectedConversation || isLoadingMore) return;

    setIsLoadingMore(true);
    await fetchMessages(selectedConversation, page + 1);
    setPage(prev => prev + 1);
    setIsLoadingMore(false);
  };

  // Send message
  const sendMessage = async () => {
    if (!newMessage.trim() || !selectedConversation || !user) return;

    try {
      const headers = generateHeaders(user);
      const conversation = conversations.find(conv => conv?.lastMessage?.conversationId === selectedConversation);

      // If we can't find the conversation or otherUser, return
      if (!conversation?.otherUser) return;

      const response = await axios.post(
        EndPoints.SEND_MESSAGE,
        {
          receiverId: conversation.otherUser,
          content: newMessage,
        },
        {
          headers: headers,
        }
      );

      // Emit the new message through socket
      if (socket) {
        socket.emit('newMessage', response.data);
      }

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const startNewConversation = async () => {
    if (!initialMessage.trim() || !newSellerId || !user) return;

    try {
      const headers = generateHeaders(user);
      await axios.post(
        EndPoints.NEW_MESSAGE,
        {
          sellerId: parseInt(newSellerId),
          initialMessage: initialMessage,
        },
        {
          headers: headers,
        }
      );

      setInitialMessage('');
      setNewSellerId(null);
      fetchConversations();
    } catch (error) {
      console.error('Error starting new conversation:', error);
    }
  };

  useEffect(() => {
    const initializePage = async () => {
      await fetchConversations();
      setLoading(false);
    };
    initializePage();
  }, [fetchConversations]);

  useEffect(() => {
    if (selectedConversation) {
      setPage(0);
      setHasMore(true);
      fetchMessages(selectedConversation, 0);
    }
  }, [selectedConversation, fetchMessages]);

  useEffect(() => {
    if (socket && selectedConversation) {
      // Join the conversation room
      socket.emit('joinConversation', selectedConversation);

      // Listen for new messages
      socket.on('messageReceived', (newMessage: Message) => {
        setMessages(prevMessages => [...prevMessages, newMessage]);
        // Update conversations list
        fetchConversations();
      });

      return () => {
        socket.off('messageReceived');
      };
    }
  }, [socket, selectedConversation, fetchConversations]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const fetchCurrentUserId = useCallback(async () => {
    try {
      if (!user) return;
      const headers = generateHeaders(user);
      const response = await axios.get(EndPoints.GET_SELLER, {
        headers: headers,
      });
      setCurrentUserId(response.data.id);
    } catch (error) {
      console.error('Error fetching user ID:', error);
    }
  }, [user, generateHeaders]);

  useEffect(() => {
    fetchCurrentUserId();
  }, [fetchCurrentUserId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" className={styles.container}>
      <Paper elevation={3} className={styles.paperWrapper}>
        {/* Mobile Header */}
        {selectedConversation && (
          <Box className={styles.headerBar}>
            <IconButton onClick={() => setSelectedConversation(null)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography className={styles.headerTitle}>
              {currentUserId === messages[0]?.Sender?.id
                ? messages[0]?.Receiver?.storeName || messages[0]?.Receiver?.name
                : messages[0]?.Sender?.storeName || messages[0]?.Sender?.name}
            </Typography>
          </Box>
        )}

        {/* Conversations List */}
        <Box className={`${styles.conversationsList} ${selectedConversation ? styles.conversationsListHidden : ''}`}>
          <Typography variant="h6" className={styles.conversationsHeader}>
            {t('messages.conversations')}
          </Typography>
          <List className={styles.conversationsListScroll}>
            {conversations.map(conversation => (
              <ListItem
                className={`${styles.conversationItem} ${
                  selectedConversation === conversation?.lastMessage?.conversationId ? styles.selectedConversation : ''
                }`}
                key={conversation?.lastMessage?.conversationId || conversation.otherUser}
                onClick={() =>
                  conversation?.lastMessage?.conversationId &&
                  setSelectedConversation(conversation.lastMessage.conversationId)
                }
              >
                <ListItemAvatar>
                  <Avatar
                    src={
                      currentUserId === conversation?.lastMessage?.Sender?.id
                        ? conversation?.lastMessage?.Receiver?.profilePhoto
                        : conversation?.lastMessage?.Sender?.profilePhoto
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    currentUserId === conversation?.lastMessage?.Sender?.id
                      ? truncateText(conversation?.lastMessage?.Receiver?.storeName, 10) ||
                        truncateText(conversation?.lastMessage?.Receiver?.name, 10)
                      : truncateText(conversation?.lastMessage?.Sender?.storeName || '', 10) ||
                        truncateText(conversation?.lastMessage?.Sender?.name || '', 10)
                  }
                  secondary={truncateText(conversation?.lastMessage?.content || '', 10)}
                />
                {conversation.unreadCount > 0 && <span className={styles.unreadBadge}>{conversation.unreadCount}</span>}
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Messages Area */}
        <Box className={styles.messagesArea}>
          {newSellerId ? (
            <Box className={styles.newConversationArea}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('messages.startNewConversation')}
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                placeholder={t('messages.typeMessage')}
                value={initialMessage}
                onChange={e => setInitialMessage(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                endIcon={
                  <SendIcon
                    style={{
                      transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none',

                      marginRight: i18n.dir() === 'rtl' ? '8px' : '',
                    }}
                  />
                }
                onClick={startNewConversation}
                disabled={!initialMessage.trim()}
              >
                {t('messages.send')}
              </Button>
            </Box>
          ) : selectedConversation ? (
            <>
              <Box className={styles.messagesScroll}>
                {hasMore && (
                  <Box display="flex" justifyContent="center" my={2}>
                    <Button
                      variant="outlined"
                      onClick={loadMoreMessages}
                      disabled={isLoadingMore}
                      startIcon={isLoadingMore ? <CircularProgress size={16} /> : null}
                      sx={{ borderRadius: '20px', textTransform: 'none' }}
                    >
                      {isLoadingMore ? t('messages.loading') : t('messages.loadPrevious')}
                    </Button>
                  </Box>
                )}
                {messages.map(message => {
                  const isSentMessage = message.senderId === currentUserId;
                  return (
                    <Box
                      key={message.id}
                      className={`${styles.messageWrapper} ${
                        isSentMessage ? styles.sentMessage : styles.receivedMessage
                      }`}
                    >
                      <Box
                        className={`${styles.messageBubble} ${
                          isSentMessage ? styles.sentMessageBubble : styles.receivedMessageBubble
                        }`}
                      >
                        <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                          {message.content}
                        </Typography>
                        <Typography className={styles.messageTime}>{formatDate(message.createdAt)}</Typography>
                        {isSentMessage && (
                          <Typography className={styles.messageStatus}>
                            {message.isRead ? t('messages.messageStatus.read') : t('messages.messageStatus.sent')}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  );
                })}
                <div ref={messagesEndRef} />
              </Box>
              <Box className={styles.inputArea}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={t('messages.typeMessage')}
                  value={newMessage}
                  onChange={e => setNewMessage(e.target.value)}
                  className={styles.messageInput}
                  onKeyPress={e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  endIcon={
                    <SendIcon
                      style={{
                        transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none',
                        fontSize: '30px',
                      }}
                    />
                  }
                  onClick={sendMessage}
                  className={styles.sendButton}
                ></Button>
              </Box>
            </>
          ) : (
            <Box className={styles.emptyState}>
              <Typography variant="h6" color="text.secondary">
                {t('messages.selectConversation')}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default MessagePage;
