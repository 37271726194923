export const frContent = {
  title: "Conditions d'Utilisation",
  lastUpdated: 'Dernière mise à jour: Novembre 2024',
  introduction: `Bienvenue sur Titrom ! En accédant à notre site web et en utilisant nos services, vous acceptez d’être lié par ces Conditions d'Utilisation ("Conditions"), toutes les lois et réglementations applicables, et vous reconnaissez que vous êtes responsable du respect des lois locales applicables. Si vous n'acceptez pas ces conditions, vous êtes interdit d'utiliser ou d'accéder à ce site.`,
  sections: [
    {
      title: 'Comptes Utilisateurs',
      subsections: [
        {
          subtitle: '1.Inscription ',
          content:
            "Les utilisateurs doivent créer un compte pour vendre des produits. L'inscription nécessite de fournir des informations valides demandées par le formulaire d'inscription. Les utilisateurs doivent avoir au moins 18 ans pour créer un compte et utiliser les services de Titrom.",
        },
        {
          subtitle: '2.Rôles des Comptes',
          content:
            "Les utilisateurs peuvent s'inscrire en tant que vendeur. Les vendeurs peuvent lister leurs produits pour la vente en gros en spécifiant des quantités minimales de commande.",
        },
        {
          subtitle: '3.Accès',
          content:
            'Les utilisateurs peuvent consulter les produits sans inscription. Cependant, pour acheter ou vendre sur Titrom, un compte est requis.',
        },
        {
          subtitle: '4.Suspension/Terminaison du Compte',
          content:
            " Titrom se réserve le droit de suspendre ou de clôturer votre compte à tout moment, sans préavis, en cas de suspicion d'activités frauduleuses ou de violation de ces conditions.",
        },
      ],
    },
    {
      title: 'Transactions',
      subsections: [
        {
          subtitle: '1.Vente et Achat',
          content:
            " Les vendeurs répertorient leurs articles en précisant les conditions de vente, y compris les commandes minimales. Les acheteurs peuvent acheter des produits soit en se connectant à leur compte, soit en tant qu'invités, en fournissant les informations de livraison nécessaires comme requis par le formulaire de commande.",
        },
        {
          subtitle: '2.Paiement et Livraison',
          content:
            'Les transactions sont réalisées en paiement à la livraison, géré par les vendeurs. Les vendeurs et les acheteurs sont responsables respectivement des processus de livraison et de paiement.',
        },
        {
          subtitle: '3.Responsabilité',
          content:
            "Les acheteurs sont responsables de l'inspection des produits à la livraison et ont le droit de refuser le paiement et l'acceptation des produits si ceux-ci ne répondent pas aux normes convenues.",
        },
      ],
    },
    {
      title: 'Utilisation du Service',
      subsections: [
        {
          subtitle: '1.Frais',
          content:
            "L'utilisation de Titrom est gratuite pour les vendeurs et les acheteurs. Toute mise à jour future concernant des frais d'abonnement ou des services supplémentaires sera communiquée via la plateforme.",
        },
        {
          subtitle: '2.Contenu',
          content:
            "Les utilisateurs sont responsables de l'exactitude du contenu qu'ils fournissent lors de l'inscription de produits. En utilisant notre plateforme, les vendeurs accordent à Titrom le droit d'utiliser leur contenu à des fins publicitaires.",
        },
        {
          subtitle: '3.Activités Interdites',
          content:
            "Il est interdit de vendre des matériaux immoraux ou contraires aux principes islamiques. L'utilisation des logos, designs et autres propriétés intellectuelles de Titrom sans autorisation est strictement interdite.",
        },
      ],
    },
    {
      title: 'Conformité Légale',
      content:
        'Les utilisateurs acceptent de se conformer à toutes les lois locales applicables au commerce en ligne au Maroc, y compris celles relatives aux transactions en gros.',
    },
    {
      title: 'Propriété Intellectuelle',
      subsections: [
        {
          subtitle: 'Contenu Utilisateur',
          content:
            "Le contenu fourni sur Titrom par les utilisateurs reste la propriété intellectuelle des propriétaires respectifs. Les utilisateurs accordent à Titrom une licence non exclusive et gratuite pour utiliser, reproduire, modifier, adapter, publier et afficher ce contenu dans le but d'exploiter et de promouvoir le site.",
        },
        {
          subtitle: 'Propriété Intellectuelle de Titrom ',
          content:
            ' Tous les droits relatifs au design, à la marque et aux logos de Titrom sont la propriété de Titrom et protégés par les lois sur la propriété intellectuelle. Toute utilisation non autorisée est strictement interdite.',
        },
      ],
    },
    {
      title: 'Résolution des Conflits',
      content:
        "Les litiges doivent être résolus directement entre l'acheteur et le vendeur. Titrom ne joue pas le rôle de médiateur dans les litiges mais recommande que les utilisateurs négocient de bonne foi pour résoudre les conflits.",
    },
    {
      title: 'Modifications des Conditions',
      content:
        "Titrom se réserve le droit de modifier ces conditions à tout moment. Les utilisateurs seront informés des modifications via la plateforme. L'utilisation continue de la plateforme après de telles modifications constitue votre consentement à ces changements.",
    },
    {
      title: 'Politique de Confidentialité',
      content:
        'Pour plus de détails sur la façon dont vos informations sont collectées, utilisées et protégées, veuillez consulter notre page sur la Politique de Confidentialité.',
    },
  ],
  footer: {
    contactInfo:
      'Pour toute question ou support concernant ces Conditions, veuillez nous contacter par e-mail ou par chat WhatsApp.',
  },
};
