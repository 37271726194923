import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Avatar,
  IconButton,
  Typography,
} from '@mui/material';
import { Store as StoreIcon, PhotoCamera as PhotoCameraIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { uploadProfilePhotoToS3 } from '../../utils/awsUtils';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import EndPoints from '../../EndPointConfig';
import { useTranslation } from 'react-i18next';

export interface StoreCreationData {
  name: string; // required
  email: string; // required
  storeName: string;
  address: string;
  bio: string;
  profilePhoto: string;
  phoneNumber: string;
}

interface CreateStoreModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: StoreCreationData) => Promise<void>;
  initialData: StoreCreationData;
  charLimits: {
    storeName: number;
    name: number;
    address: number;
    phoneNumber: number;
    bio: number;
  };
}

const CreateStoreModal: React.FC<CreateStoreModalProps> = ({ open, onClose, onSubmit, initialData, charLimits }) => {
  const { t } = useTranslation();
  const [storeData, setStoreData] = React.useState<StoreCreationData>({
    name: initialData.name,
    email: initialData.email,
    storeName: '',
    address: '',
    bio: '',
    profilePhoto: '',
    phoneNumber: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({
    storeName: false,
    address: false,
    bio: false,
  });
  const [storeNameError, setStoreNameError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    setStoreData(prev => ({
      ...prev,
      name: initialData.name,
      email: initialData.email,
    }));
  }, [initialData]);

  const handleStoreDataChange =
    (field: keyof StoreCreationData) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      if (field === 'phoneNumber') {
        value = value.replace(/\D/g, '').slice(0, charLimits.phoneNumber);
      }

      setStoreData(prev => ({
        ...prev,
        [field]: value,
      }));

      // Clear error when user types
      if (formErrors[field as keyof typeof formErrors]) {
        setFormErrors(prev => ({
          ...prev,
          [field]: false,
        }));
      }

      // Check store name availability
      if (field === 'storeName' && value.trim()) {
        try {
          const isAvailable = await checkStoreNameAvailability(value);
          setStoreNameError(isAvailable ? null : 'This store name is already taken');
        } catch (error) {
          console.error('Error checking store name:', error);
          setStoreNameError('Error checking store name availability');
        }
      }
    };

  const handleProfilePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      // You might want to add error handling here
      return;
    }

    try {
      setIsSubmitting(true);
      const imageUrl = await uploadProfilePhotoToS3(file);
      setStoreData(prev => ({
        ...prev,
        profilePhoto: imageUrl,
      }));
    } catch (error) {
      console.error('Error uploading profile photo:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    // Validate required fields
    const newFormErrors = {
      storeName: !storeData.storeName.trim(),
      address: !storeData.address.trim(),
      bio: !storeData.bio.trim(),
    };

    setFormErrors(newFormErrors);

    // Check if any required field is empty or if there's a store name error
    if (Object.values(newFormErrors).some(error => error) || storeNameError) {
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmit(storeData);
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkStoreNameAvailability = async (storeName: string): Promise<boolean> => {
    try {
      const token = await getAccessTokenSilently();
      await axios.get(`${EndPoints.CHECK_STORE_NAME_AVAILABILITY}/${encodeURIComponent(storeName)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return true;
    } catch (error: any) {
      if (error.response?.status === 409) {
        return false;
      }
      throw error;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <StoreIcon /> {t('store.createStore.title')}
      </DialogTitle>
      <DialogContent sx={{ pt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="store-photo-upload"
                type="file"
                onChange={handleProfilePhotoChange}
              />
              <label htmlFor="store-photo-upload">
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <Avatar
                    src={storeData.profilePhoto}
                    sx={{
                      width: 100,
                      height: 100,
                      cursor: 'pointer',
                      '&:hover': { opacity: 0.8 },
                    }}
                  >
                    <PhotoCameraIcon />
                  </Avatar>
                  {storeData.profilePhoto && (
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        setStoreData(prev => ({ ...prev, profilePhoto: '' }));
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  {t('store.createStore.uploadPhoto')}
                </Typography>
              </label>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={t('store.createStore.storeName')}
              value={storeData.storeName}
              onChange={handleStoreDataChange('storeName')}
              inputProps={{ maxLength: charLimits.storeName }}
              error={formErrors.storeName || !!storeNameError}
              helperText={
                formErrors.storeName
                  ? t('store.createStore.errors.storeNameRequired')
                  : storeNameError
                  ? storeNameError
                  : `${storeData.storeName.length}/${charLimits.storeName}`
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={t('store.createStore.address')}
              value={storeData.address}
              onChange={handleStoreDataChange('address')}
              multiline
              rows={2}
              inputProps={{ maxLength: charLimits.address }}
              error={formErrors.address}
              helperText={
                formErrors.address
                  ? t('store.createStore.errors.addressRequired')
                  : `${storeData.address.length}/${charLimits.address}`
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('store.createStore.phoneNumber')}
              value={storeData.phoneNumber}
              onChange={handleStoreDataChange('phoneNumber')}
              inputProps={{
                maxLength: charLimits.phoneNumber,
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              helperText={`${storeData.phoneNumber.length}/${charLimits.phoneNumber} - ${t(
                'store.createStore.numbersOnly'
              )}`}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={t('store.createStore.bio')}
              value={storeData.bio}
              onChange={handleStoreDataChange('bio')}
              multiline
              rows={4}
              inputProps={{ maxLength: charLimits.bio }}
              error={formErrors.bio}
              helperText={
                formErrors.bio ? t('store.createStore.errors.bioRequired') : `${storeData.bio.length}/${charLimits.bio}`
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={onClose} sx={{ color: 'text.secondary' }}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting || !storeData.storeName.trim() || !!storeNameError}
          sx={{
            bgcolor: 'secondary.main',
            '&:hover': { bgcolor: 'secondary.dark' },
          }}
        >
          {isSubmitting ? t('store.createStore.creating') : t('store.createStore.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateStoreModal;
