import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styles from './Home.module.css';
import ShopCard from '../Components/ShopCard';
import { Button, Container, Typography, Box, TextField, IconButton, Select, MenuItem } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EndPoints from '../EndPointConfig';
import UnifiedNotificationBell from '../Components/UnifiedNotificationBell';
import SearchIcon from '@mui/icons-material/Search';
import Skeleton from '@mui/material/Skeleton';
import CategoryIcon from '@mui/icons-material/Category';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Footer from '../Components/Footer';
import MenuItemCompo from '../Components/MenuItemCompo';
import ExploreIcon from '@mui/icons-material/Explore';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../Components/LanguageSwitcher';
import { clearLocaleStorage } from '../clearLocaleStorage/clear';
import { SEOMetaTags } from '../Components/SEO/SEOMetaTags';
import ComingSoon from './ComingSoon';
import { useAuth } from '../contexts/AuthContext';

interface Product {
  id: number;
  name: string;
  description: string;
  price: number;
  stock: number;
  categoryId: number;
  minOrder: number;
  averageRating: number;
  totalReviews: number;
  ProductImages: Array<{
    imageUrl: string;
    isMain: boolean;
    displayOrder: number;
  }>;
  Seller: {
    id: number;
    name: string;
    storeName: string;
    profilePhoto: string;
  };
}

interface UserProfile {
  id: number;
  name: string;
  email: string;
  profilePhoto: string;
  storeName: string;
}

interface Category {
  id: number;
  name: string;
  path: string;
  slug: string;
  description?: string;
  level: number;
  children?: Category[];
}

const Home: React.FC = () => {
  const isProductsReady = false;
  // Clear the dashboardActiveTab from local storage when the home page is loaded
  useEffect(() => {
    clearLocaleStorage();
  }, []);

  const navigate = useNavigate();
  const { user, isAuthenticated, logout, generateHeaders } = useAuth();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isSeller, setIsSeller] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedSearchCategory, setSelectedSearchCategory] = useState<Category | null>(null);
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  const [priceSort, setPriceSort] = useState<'asc' | 'desc' | null>(null);
  const [ratingSort, setRatingSort] = useState<'asc' | 'desc' | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(30); // Number of products per page
  const { t, i18n } = useTranslation();
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const phrases = [
    t('home.phrases.discover'),
    t('home.phrases.connect'),
    t('home.phrases.expand'),
    t('home.phrases.trade'),
    t('home.phrases.wholesale'),
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPhraseIndex(prev => (prev + 1) % phrases.length);
        setIsAnimating(false);
      }, 500);
    }, 4000);

    return () => clearInterval(interval);
  }, [phrases.length]);

  const checkSellerStatus = useCallback(async () => {
    try {
      if (isAuthenticated && user) {
        const headers = generateHeaders(user);
        const response = await axios.get(EndPoints.GET_SELLER, { headers });
        setIsSeller(response.data.isSeller);
      }
    } catch (error) {
      console.error('Error checking seller status:', error);
      setIsSeller(false);
    }
  }, [isAuthenticated, user, generateHeaders]);

  useEffect(() => {
    checkSellerStatus();
  }, [checkSellerStatus]);

  const handleNotificationsRead = () => {
    try {
    } catch (error) {
      console.error('Error handling notifications:', error);
      setError('Failed to mark notifications as read');
    }
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      if (!isAuthenticated || !user) return;
      const headers = generateHeaders(user);
      const response = await axios.get(EndPoints.GET_SELLER, { headers });
      setUserProfile(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to load user profile');
    }
  }, [isAuthenticated, user, generateHeaders]);

  const generateSlug = (): string => {
    const nameToUse = userProfile?.storeName ? userProfile.storeName : userProfile?.name || '';

    return `${nameToUse
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '')}-${userProfile?.id}`;
  };

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(EndPoints.GET_PRODUCTS);
      setProducts(response.data);
    } catch (error) {
      console.error('Error details:', error);
      setError('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserProfile();
    } else {
      setUserProfile(null);
    }
    fetchProducts();
  }, [isAuthenticated, fetchUserProfile]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = useMemo(() => {
    try {
      return products
        .filter(product => {
          const searchValue = searchTerm.trim().toLowerCase();
          const nameMatch = searchValue === '' || product.name.toLowerCase().includes(searchValue);
          const categoryMatch = selectedSearchCategory ? product.categoryId === selectedSearchCategory.id : true;
          return nameMatch && categoryMatch;
        })
        .sort((a, b) => {
          if (priceSort === 'asc') {
            return a.price - b.price;
          } else if (priceSort === 'desc') {
            return b.price - a.price;
          } else if (ratingSort === 'asc') {
            return a.averageRating - b.averageRating;
          } else if (ratingSort === 'desc') {
            return b.averageRating - a.averageRating;
          }
          return 0;
        });
    } catch (error) {
      console.error('Error filtering products:', error);
      setError('Failed to filter products');
      return [];
    }
  }, [products, searchTerm, selectedSearchCategory, priceSort, ratingSort]);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    logout();
    setUserProfile(null);
  };

  const fetchUnreadMessagesCount = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const headers = user ? generateHeaders(user) : {};

      const response = await axios.get(EndPoints.GET_UNREAD_MESSAGES_COUNT, { headers });

      setUnreadMessages(response.data.count);
    } catch (error) {
      console.error('Error fetching unread messages:', error);
    }
  }, [isAuthenticated, user, generateHeaders]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUnreadMessagesCount();
      const interval = setInterval(fetchUnreadMessagesCount, 30000);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated, fetchUnreadMessagesCount]);

  const handleDashboardClick = () => {
    try {
      navigate('/User-Dashboard');
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error navigating to dashboard:', error);
      setError('Failed to navigate to dashboard');
    }
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get(`${EndPoints.CATEGORIES}?lang=${i18n.language}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories');
    }
  }, [i18n.language]);

  useEffect(() => {
    fetchCategories();
  }, [i18n.language, fetchCategories]);

  const paginatedProducts = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    return filteredProducts.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredProducts, page, itemsPerPage]);

  const pageCount = useMemo(() => {
    return Math.ceil(filteredProducts.length / itemsPerPage);
  }, [filteredProducts, itemsPerPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.root}>
      <SEOMetaTags
        title={t('seo.home.title')}
        description={t('seo.home.description')}
        keywords={t('seo.home.keywords')}
        language={i18n.language}
        url="/"
      />
      <header className={`${styles.header} ${styles.stickyHeader}`}>
        <Container className={styles.headerContent} maxWidth={false}>
          {/* Logo Section */}
          <div className={styles.logoSection}>
            <Typography variant="h1" className={styles.logo}>
              {t('home.title')}
            </Typography>
          </div>

          {/* Enhanced Search Section */}
          <div className={styles.searchSection}>
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
              <TextField
                label={t('search.placeholder')}
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon color="action" className={styles.searchIcon} />,
                }}
                className={styles.searchField}
                sx={{ flex: 1 }}
              />
            </Box>
          </div>

          {/* Enhanced Header Actions */}
          <div className={styles.headerActions}>
            <LanguageSwitcher />
            {isAuthenticated ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <UnifiedNotificationBell
                  isSeller={isSeller}
                  onNotificationsRead={handleNotificationsRead}
                  unreadMessages={unreadMessages}
                />
                <div className={styles.userSection}>
                  <IconButton
                    color="primary"
                    onClick={() => navigate(`/user-profile/${generateSlug()}`)}
                    className={styles.profileButton}
                    title="View Profile"
                    disabled={!userProfile} // Disable the button if userProfile is not loaded yet
                  >
                    {userProfile?.profilePhoto ? (
                      <img src={userProfile.profilePhoto} alt="Profile" className={styles.profilePhoto} />
                    ) : (
                      <AccountCircleIcon />
                    )}
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AccountCircleIcon />}
                    onClick={handleDashboardClick}
                    className={styles.dashboardButton}
                  >
                    {t('auth.dashboard')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<LogoutIcon />}
                    onClick={handleLogout}
                    className={styles.logoutButton}
                  >
                    {t('auth.logout')}
                  </Button>
                </div>
              </Box>
            ) : (
              <div className={styles.authButtons}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AppRegistrationIcon />}
                  onClick={handleLogin}
                  className={styles.registerButton}
                >
                  {t('auth.register')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<LoginIcon />}
                  onClick={handleLogin}
                  className={styles.loginButton}
                >
                  {t('auth.login')}
                </Button>
              </div>
            )}
          </div>
        </Container>
      </header>

      <main className={styles.main}>
        <Container maxWidth={false}>
          <div className={styles.heroSection}>
            <div className={styles.heroContent}>
              <Typography variant="h1" className={styles.heroTitle}>
                {t('home.heroTitle')}
              </Typography>
              <Typography
                variant="h2"
                className={`${styles.heroSubtitle} ${isAnimating ? styles.fadeOut : styles.fadeIn}`}
              >
                {phrases[currentPhraseIndex]}
              </Typography>
              <div className={styles.heroActions}>
                <Button
                  variant="contained"
                  size="large"
                  className={styles.primaryCTA}
                  onClick={() => navigate('/coming-soon')}
                  startIcon={<ExploreIcon />}
                >
                  {t('home.exploreProducts')}
                </Button>
                {!isAuthenticated && (
                  <Button
                    variant="outlined"
                    size="large"
                    className={styles.secondaryCTA}
                    onClick={handleLogin}
                    startIcon={<StorefrontIcon />}
                  >
                    {t('home.startSelling')}
                  </Button>
                )}
              </div>
            </div>
          </div>

          {!isProductsReady ? (
            <ComingSoon isHeader={false} />
          ) : (
            <>
              {/* New Filter Section */}
              <Box className={styles.filterSection}>
                <Box className={styles.filterControls}>
                  {/* Category Filter */}
                  {showCategoryMenu ? (
                    <Select
                      value=""
                      onChange={e => setShowCategoryMenu(false)}
                      displayEmpty
                      className={styles.filterSelect}
                      open={isSelectOpen} // drop the menu down when showCategoryMenu true
                      onClose={() => {
                        setIsSelectOpen(false);
                        setShowCategoryMenu(false);
                      }}
                      onOpen={() => setIsSelectOpen(true)}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 300,
                            borderRadius: '8px',
                            mt: 1,
                          },
                        },
                      }}
                    >
                      <MenuItemCompo
                        key="all"
                        category={{
                          id: 0,
                          name: t('search.allCategories'),
                          path: '',
                          slug: '',
                          level: 0,
                        }}
                        onSelect={() => {
                          setSelectedSearchCategory(null);
                          setShowCategoryMenu(false);
                        }}
                      />
                      {categories.map(category => (
                        <MenuItemCompo
                          key={category.id}
                          category={category}
                          onSelect={selectedCategory => {
                            setSelectedSearchCategory(selectedCategory);
                            setShowCategoryMenu(false);
                          }}
                        />
                      ))}
                    </Select>
                  ) : (
                    <Button
                      onClick={() => {
                        setShowCategoryMenu(true);
                        setIsSelectOpen(true);
                      }}
                      startIcon={<CategoryIcon />}
                      variant="outlined"
                      className={styles.filterButton}
                    >
                      {selectedSearchCategory ? selectedSearchCategory.name : t('search.allCategories')}
                    </Button>
                  )}

                  {/* Sort Controls */}
                  <Select
                    value={priceSort || ''}
                    onChange={e => {
                      setPriceSort(e.target.value as 'asc' | 'desc' | null);
                      setRatingSort(null);
                    }}
                    displayEmpty
                    className={styles.filterSelect}
                  >
                    <MenuItem value="">{t('filters.sortByPrice')}</MenuItem>
                    <MenuItem value="asc">{t('filters.priceLowToHigh')}</MenuItem>
                    <MenuItem value="desc">{t('filters.priceHighToLow')}</MenuItem>
                  </Select>

                  <Select
                    value={ratingSort || ''}
                    onChange={e => {
                      setRatingSort(e.target.value as 'asc' | 'desc' | null);
                      setPriceSort(null);
                    }}
                    displayEmpty
                    className={styles.filterSelect}
                  >
                    <MenuItem value="">{t('filters.sortByRating')}</MenuItem>
                    <MenuItem value="desc">{t('filters.ratingHighToLow')}</MenuItem>
                    <MenuItem value="asc">{t('filters.ratingLowToHigh')}</MenuItem>
                  </Select>
                </Box>
              </Box>

              {loading ? (
                <div className={styles.productsGrid}>
                  {[1, 2, 3, 4].map(item => (
                    <div key={item} className={styles.skeletonCard}>
                      <Skeleton variant="rectangular" height={200} />
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="60%" />
                    </div>
                  ))}
                </div>
              ) : error ? (
                <Box display="flex" justifyContent="center" p={4}>
                  <Typography color="error">{t('errors.products')}</Typography>
                </Box>
              ) : filteredProducts.length === 0 ? (
                <Box display="flex" justifyContent="center" p={4}>
                  <Typography>{t('home.noProductsFound')}</Typography>
                </Box>
              ) : (
                <>
                  <div className={styles.productsGrid}>
                    {paginatedProducts.map(product => (
                      <ShopCard
                        key={product.id}
                        productId={product.id}
                        productName={product.name}
                        productPrice={product.price}
                        productImage={
                          product.ProductImages.find(img => img.isMain)?.imageUrl ||
                          product.ProductImages[0]?.imageUrl ||
                          '/placeholder-image.jpg'
                        }
                        storeName={product.Seller?.storeName}
                        sellerProfilePhoto={product.Seller?.profilePhoto}
                        minOrder={product.minOrder}
                        averageRating={product.averageRating}
                        totalReviews={product.totalReviews}
                      />
                    ))}
                  </div>
                  <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                    <Pagination
                      count={pageCount}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      size="large"
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default Home;
