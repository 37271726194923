export const arContent = {
  title: 'الأسئلة الشائعة (FAQ)',
  introduction:
    'مرحبًا بكم في صفحة الأسئلة الشائعة الخاصة بـ تيتروم! هنا ستجدون إجابات على الأسئلة الشائعة حول استخدام منصتنا. إذا كانت لديكم أي استفسارات إضافية، لا تترددوا في التواصل مع فريق الدعم لدينا عبر البريد الإلكتروني أو الواتساب.',
  sections: [
    {
      title: 'البدء',
      subsections: [
        {
          subtitle: 'كيف يمكنني إنشاء حساب؟',
          content:
            'لإنشاء حساب:\n• انتقل إلى الصفحة الرئيسية.\n• انقر على "تسجيل" إذا لم يكن لديك حساب، أو "تسجيل الدخول" إذا كان لديك حساب.\n• استخدم تطبيق Telegram للتحقق من هويتك.\n• بمجرد التسجيل، يمكنك البدء في استكشاف المنصة وإجراء الطلبات أو عرض منتجاتك.',
        },
        {
          subtitle: 'هل يمكنني تصفح المنتجات دون تسجيل حساب؟',
          content: 'نعم، يمكنك تصفح وشراء المنتجات دون الحاجة إلى حساب. ومع ذلك، لبيع المنتجات، تحتاج إلى إنشاء حساب.',
        },
        {
          subtitle: 'هل هناك شرط عمر لإنشاء حساب؟',
          content: 'نعم، يجب أن يكون عمرك 18 عامًا أو أكثر لإنشاء حساب واستخدام خدمات تيتروم.',
        },
      ],
    },
    {
      title: 'إدارة الحساب',
      subsections: [
        {
          subtitle: 'ماذا أفعل إذا لم أتمكن من تسجيل الدخول؟',
          content:
            'إذا كنت تواجه صعوبة في تسجيل الدخول:\n• تأكد من أنك مصادق باستخدام تيليجرام.\n• إذا استمرت المشكلة، يرجى الاتصال بفريق الدعم لدينا للمساعدة.',
        },
        {
          subtitle: 'كيف يمكنني تحديث تفاصيل حسابي؟',
          content:
            'لتحديث تفاصيل حسابك:\n• انتقل إلى علامة التبويب "حسابي" في لوحة التحكم.\n• أو انقر على أيقونة الملف الشخصي في رأس الصفحة الرئيسية.\n• من هنا، يمكنك تحديث معلوماتك الشخصية أو معلومات المتجر.',
        },
      ],
    },
    {
      title: 'الشراء والبيع',
      subsections: [
        {
          subtitle: 'كيف يمكنني تقديم طلب؟',
          content:
            'لتقديم طلب:\n• انقر على المنتج الذي ترغب في شراؤه.\n• حدد الكمية المطلوبة (تأكد من توفر الكمية المطلوبة للتجزئة).\n• انقر على "شراء الآن" واكمال علم التسليم.\n• يمكنك شراء المنتجات كعميل دون الحاجة إلى حساب.',
        },
        {
          subtitle: 'هل يمكنني تغيير أو إلغاء طلبي؟',
          content:
            'نعم، يمكنك تغيير أو إلغاء طلبك من خلال الاتصال بالبائع مباشرة عبر المنصة. تأكد من التحرك بشكل مبكر قبل إرسال الطلب.',
        },
        {
          subtitle: 'كيف يمكنني عرض منتجاتي كبائع؟',
          content:
            'لعرض منتجاتك كبائع:\n• قم بتسجيل الدخول إلى حسابك.\n• انتقل إلى لوحة التحكم.\n• حدد علامة "حسابي" وقم إنشاء متجر إذا لم يكن لديك واحد.\n• انتقل إلى قسم "متجري" في لوحة التحكم.\n• أضف تفاصيل المنتج، بما في ذلك الاسم والوصف والسعر والكمية المطلوبة للتجزئة.\n• بمجرد الموافقة، سيكونون مرئيين للمشترين.',
        },
      ],
    },
    {
      title: 'الدفع والتسليم',
      subsections: [
        {
          subtitle: 'ما هي طرق الدفع المعتمدة؟',
          content:
            'حالياً، تيتروم تدعم الدفع عند التسليم (COD). يضمن هذا الخيار المعاملات الآمنة حيث يتم التحقق من جودة المنتجات قبل الدفع.',
        },
        {
          subtitle: 'هل يمكنني رفض الدفع إذا كان المنتج غير مطابق للتوقعات؟',
          content:
            'نعم، لدى المشترين الحق في التحقق من المنتجات عند التسليم. إذا كان المنتج غير مطابق للشرط الموافق عليه، يمكنك رفض الدفع.',
        },
        {
          subtitle: 'كيف يمكنني تتبع طلبي؟',
          content:
            'إذا اشتريت أثناء تسجيل الدخول:\n• انتقل إلى علامة التبويب "مشترياتي" في لوحة التحكم الخاصة بك لتتبع طلباتك.\n• إذا اشتريت كضيف: استخدم معلومات التتبع المقدمة في بريد تأكيد الطلب الخاص بك لمراقبة حالة طلبك.',
        },
      ],
    },
    {
      title: 'الدعم والتقييم',
      subsections: [
        {
          subtitle: 'كيف يمكنني الاتصال بالدعم؟',
          content:
            'يمكنك الاتصال بنا عبر:\n• البريد الإلكتروني: support@titrom.com\n• WhatsApp :+212 638 481 837\nنحن هنا لمساعدتك بأي أسئلة أو مخاوف.',
        },
        {
          subtitle: 'كيف يمكنني تقديم تعليقات؟',
          content:
            'نحن نؤمن بتعليقاتك! يمكنك مشاركة أفكارك أو اقتراحاتك عبر صفحة تواصلنا أو إرسال بريد إلكتروني إلىنا مباشرة.',
        },
      ],
    },
  ],
  footer: {
    contactInfo: 'تحتاج إلى مزيد من المساعدة؟ اتصل بفريق الدعم',
    additionalInfo: 'للمعلومات التفصيلية، يرجى الرجوع إلى سياسة الخدمة الموجودة على الموقع',
  },
};
