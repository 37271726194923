import React from 'react';
import { enContent } from './content/en';
import { arContent } from './content/ar';
import { frContent } from './content/fr';
import styles from './faq.module.css';

interface FAQProps {
  language: 'en' | 'ar' | 'fr';
}

const FAQ: React.FC<FAQProps> = ({ language }) => {
  const content = {
    en: enContent,
    ar: arContent,
    fr: frContent,
  };

  const currentContent = content[language] || content.en;

  return (
    <div className={`${styles.faqContainer} ${language === 'ar' ? styles.rtl : styles.ltr}`}>
      <h1 className={styles.faqTitle}>{currentContent.title}</h1>

      <div className={styles.introduction}>
        <p>{currentContent.introduction}</p>
      </div>

      <div className={styles.sections}>
        {currentContent.sections.map((section, index) => (
          <div key={index} className={styles.section}>
            <h2>{section.title}</h2>

            <div className={styles.subsections}>
              {section.subsections.map((subsection, subIndex) => (
                <div key={subIndex} className={styles.subsection}>
                  <h3>{subsection.subtitle}</h3>
                  <div className={styles.content}>
                    {subsection.content.split('\n').map((paragraph, pIndex) => (
                      <p key={pIndex}>{paragraph.trim()}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <footer className={styles.faqFooter}>
        <p>{currentContent.footer.contactInfo}</p>
        <p>{currentContent.footer.additionalInfo}</p>
      </footer>
    </div>
  );
};

export default FAQ;
