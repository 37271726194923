interface ProductStructuredDataProps {
  name: string;
  description: string;
  image: string;
  price: number;
  currency: string;
  seller: string;
}

export const ProductStructuredData: React.FC<ProductStructuredDataProps> = ({
  name,
  description,
  image,
  price,
  currency,
  seller,
}) => {
  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: name,
    description: description,
    image: image,
    offers: {
      '@type': 'Offer',
      priceCurrency: currency,
      price: price,
      seller: {
        '@type': 'Organization',
        name: seller,
      },
    },
  };

  return <script type="application/ld+json">{JSON.stringify(structuredData)}</script>;
};
