import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Paper,
  Typography,
  Box,
  Chip,
  Grid,
  Divider,
  Alert,
  Button,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Select,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Skeleton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Pagination,
  Avatar,
} from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import SearchIcon from '@mui/icons-material/Search';
import styles from './SalesOrders.module.css';
import EndPoints from '../EndPointConfig';
import RouteIcon from '@mui/icons-material/Route';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeIcon from '@mui/icons-material/Home';
import { useAuth } from '../contexts/AuthContext';

interface Order {
  id: number;
  quantity: number;
  totalPrice: number;
  status: 'pending' | 'processing' | 'enroute' | 'delivered' | 'cancelled';
  deliveryAddress: string;
  orderDate: string;
  phoneNumber: string;
  buyerCity: string;
  buyerName: string;
  attributes?: Record<string, string[]> | null;
  Product: {
    name: string;
    price: number;
    ProductImages: Array<{
      imageUrl: string;
      isMain: boolean;
    }>;
  };
  Buyer: {
    id: number;
    name: string;
    profilePhoto?: string;
    email: string;
  };
}

const SalesOrders: React.FC = () => {
  const { t } = useTranslation();

  const { user, generateHeaders } = useAuth();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [tabValue, setTabValue] = useState<Order['status'] | 'all'>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [feedback, setFeedback] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [cancelDialog, setCancelDialog] = useState({
    open: false,
    orderId: null as number | null,
  });
  const [page, setPage] = useState(1);
  const ordersPerPage = 15;
  const navigate = useNavigate();
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<Order | null>(null);
  const [orderDetailsOpen, setOrderDetailsOpen] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (!user) return;
        const headers = generateHeaders(user);
        const response = await axios.get(EndPoints.SELLER_ORDERS, { headers });
        const transformedOrders = response.data.map((order: Order) => ({
          ...order,
          attributes: typeof order.attributes === 'string' ? JSON.parse(order.attributes) : order.attributes,
        }));
        setOrders(transformedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to load orders');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user, generateHeaders]);

  const handleStatusChange = async (newStatus: Order['status'], orderToUpdate?: Order) => {
    try {
      if (!user) return;
      const headers = generateHeaders(user);
      const orderId = orderToUpdate?.id || selectedOrder?.id || cancelDialog.orderId;

      if (!orderId) return;

      await axios.put(
        `${EndPoints.UPDATE_ORDER_STATUS}/${orderId}`,
        { status: newStatus },
        {
          headers,
        }
      );

      setOrders(orders.map(order => (order.id === orderId ? { ...order, status: newStatus } : order)));

      setTabValue(newStatus);
      setPage(1);

      setOrderDetailsOpen(false);

      setError(null);
      const statusMessages: Record<Order['status'], string> = {
        pending: t('salesOrders.statusCard.pending'),
        processing: t('salesOrders.statusCard.processing'),
        enroute: t('salesOrders.statusCard.enroute'),
        delivered: t('salesOrders.statusCard.delivered'),
        cancelled: t('salesOrders.statusCard.cancelled'),
      };
      setFeedback({
        open: true,
        message: statusMessages[newStatus],
        severity: 'success',
      });
    } catch (error) {
      console.error('Error updating order status:', error);
      setError('Failed to update order status');
      setFeedback({
        open: true,
        message: 'Failed to update order status',
        severity: 'error',
      });
    }
    setAnchorEl(null);
  };

  const handleCancelClick = (order: Order) => {
    setCancelDialog({ open: true, orderId: order.id });
    setSelectedOrder(order);
    setAnchorEl(null);
  };

  const handleCancelConfirm = async () => {
    if (!cancelDialog.orderId) return;

    const orderToCancel = orders.find(order => order.id === cancelDialog.orderId);
    if (!orderToCancel) return;

    await handleStatusChange('cancelled');
    setCancelDialog({ open: false, orderId: null });
  };

  const getStatusIcon = (status: Order['status']): React.ReactElement => {
    switch (status) {
      case 'pending':
        return <PendingIcon className={styles.pendingIcon} />;
      case 'processing':
        return <InventoryIcon className={styles.processingIcon} />;
      case 'enroute':
        return <RouteIcon className={styles.enrouteIcon} />;
      case 'delivered':
        return <CheckCircleIcon className={styles.deliveredIcon} />;
      case 'cancelled':
        return <CancelIcon className={styles.cancelledIcon} />;
      default:
        return <PendingIcon />;
    }
  };

  const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

    if (isNaN(numericPrice)) {
      return '0';
    }

    const currencyFormat = t('currency.format');
    const formattedPrice = numericPrice
      .toFixed(Number(t('currency.precision')))
      .replace('.', t('currency.decimal'))
      .replace(/\B(?=(\d{3})+(?!\d))/g, t('currency.thousand'));

    return currencyFormat.replace('{amount}', formattedPrice);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const getStatusCount = (status: Order['status'] | 'all') => {
    if (status === 'all') return orders.length;
    return orders.filter(order => order.status === status).length;
  };

  const filteredOrders = orders
    .filter(
      order =>
        (tabValue === 'all' || order.status === tabValue) &&
        (order.Product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.Buyer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.id.toString().includes(searchTerm))
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime();
        case 'price':
          return b.totalPrice - a.totalPrice;
        default:
          return 0;
      }
    });

  // Calculate pagination
  const indexOfLastOrder = page * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    // Scroll to top of the page when changing pages
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getStatusLabel = (status: Order['status']): string => {
    return t(`orders.status.${status}`);
  };

  const handleOrderClick = (order: Order) => {
    setSelectedOrderDetails(order);
    setOrderDetailsOpen(true);
  };

  if (loading) {
    return <OrdersSkeleton />;
  }

  if (error) {
    return (
      <Container maxWidth="lg" className={styles.container}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className={styles.container}>
      <Box className={styles.header}>
        <Box className={styles.controls}>
          <TextField
            size="small"
            placeholder={t('salesOrders.search.placeholder')}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>{t('salesOrders.search.sortBy.label')}</InputLabel>
            <Select
              value={sortBy}
              label={t('salesOrders.search.sortBy.label')}
              onChange={e => setSortBy(e.target.value)}
            >
              <MenuItem value="date">{t('salesOrders.search.sortBy.latest')}</MenuItem>
              <MenuItem value="price">{t('salesOrders.search.sortBy.highestPrice')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box className={styles.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-scrollButtons': {
              '&.Mui-disabled': { opacity: 0.3 },
              '@media (max-width: 600px)': {
                display: 'flex',
              },
            },
          }}
          className={`${styles.tabs} ${styles[`tabs${tabValue}`]}`}
        >
          <Tab
            label={
              <Box className={styles.tabContent}>
                <ReceiptLongIcon />
                <span>
                  {t('salesOrders.tabs.all')} ({getStatusCount('all')})
                </span>
              </Box>
            }
            value="all"
            className={`${styles.tab} ${styles.tabAll}`}
          />
          <Tab
            label={
              <Box className={styles.tabContent}>
                <PendingIcon />
                <Box
                  className={getStatusCount('pending') > 0 ? styles.tabPendingPulse : ''}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <span>
                    {t('salesOrders.tabs.pending')} ({getStatusCount('pending')})
                  </span>
                </Box>
              </Box>
            }
            value="pending"
            className={`${styles.tab} ${styles.tabPending}`}
          />
          <Tab
            label={
              <Box className={styles.tabContent}>
                <InventoryIcon />
                <span>
                  {t('salesOrders.tabs.processing')} ({getStatusCount('processing')})
                </span>
              </Box>
            }
            value="processing"
            className={`${styles.tab} ${styles.tabProcessing}`}
          />
          <Tab
            label={
              <Box className={styles.tabContent}>
                <LocalShippingIcon />
                <span>
                  {t('salesOrders.tabs.enroute')} ({getStatusCount('enroute')})
                </span>
              </Box>
            }
            value="enroute"
            className={`${styles.tab} ${styles.tabEnroute}`}
          />
          <Tab
            label={
              <Box className={styles.tabContent}>
                <CheckCircleIcon />
                <span>
                  {t('salesOrders.tabs.delivered')} ({getStatusCount('delivered')})
                </span>
              </Box>
            }
            value="delivered"
            className={`${styles.tab} ${styles.tabDelivered}`}
          />
          <Tab
            label={
              <Box className={styles.tabContent}>
                <CancelIcon />
                <span>
                  {t('salesOrders.tabs.cancelled')} ({getStatusCount('cancelled')})
                </span>
              </Box>
            }
            value="cancelled"
            className={`${styles.tab} ${styles.tabCancelled}`}
          />
        </Tabs>
      </Box>

      {filteredOrders.length === 0 ? (
        <Paper className={styles.emptyState}>
          <Typography variant="h6" color="textSecondary">
            {t('salesOrders.noOrders.title')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('salesOrders.noOrders.subtitle')}
          </Typography>
        </Paper>
      ) : (
        <>
          <Grid container spacing={3}>
            <div className={styles.orderSummaryGrid}>
              {currentOrders.map(order => (
                <Grid item xs={12} key={order.id}>
                  <Box>
                    <OrderSummary
                      order={order}
                      formatDate={formatDate}
                      formatPrice={formatPrice}
                      getStatusIcon={getStatusIcon}
                      getStatusLabel={getStatusLabel}
                      t={t}
                      onOrderClick={handleOrderClick}
                    />
                  </Box>
                </Grid>
              ))}
            </div>
          </Grid>

          {/* Pagination */}
          <Box className={styles.paginationContainer}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
              className={styles.pagination}
            />
          </Box>
        </>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {selectedOrder &&
          selectedOrder.status !== 'cancelled' && [
            <MenuItem key="processing" onClick={() => handleStatusChange('processing', selectedOrder)}>
              {t('salesOrders.actions.markProcessing')}
            </MenuItem>,
            <MenuItem key="enroute" onClick={() => handleStatusChange('enroute', selectedOrder)}>
              {t('salesOrders.actions.markEnRoute')}
            </MenuItem>,
            <MenuItem key="delivered" onClick={() => handleStatusChange('delivered', selectedOrder)}>
              {t('salesOrders.actions.markDelivered')}
            </MenuItem>,
            <MenuItem key="cancel" onClick={() => handleCancelClick(selectedOrder)}>
              {t('salesOrders.actions.cancelOrder')}
            </MenuItem>,
          ]}
        {selectedOrder && selectedOrder.status === 'cancelled' && (
          <MenuItem disabled>{t('salesOrders.order.cancelled')}</MenuItem>
        )}
      </Menu>

      <Dialog open={cancelDialog.open} onClose={() => setCancelDialog({ open: false, orderId: null })}>
        <DialogTitle>{t('salesOrders.cancelDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedOrder?.status === 'pending'
              ? t('salesOrders.cancelDialog.messagePending')
              : t('salesOrders.cancelDialog.messageOther')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialog({ open: false, orderId: null })} color="primary">
            {t('salesOrders.cancelDialog.keepOrder')}
          </Button>
          <Button onClick={handleCancelConfirm} color="error" variant="contained">
            {selectedOrder?.status === 'pending'
              ? t('salesOrders.cancelDialog.yesRefuseOrder')
              : t('salesOrders.cancelDialog.yesCancelOrder')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={feedback.severity} onClose={() => setFeedback(prev => ({ ...prev, open: false }))}>
          {feedback.message}
        </Alert>
      </Snackbar>

      <Dialog open={orderDetailsOpen} onClose={() => setOrderDetailsOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 24px',
          }}
        >
          <Typography component="span" sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
            {t('salesOrders.orderDetails.title')}
          </Typography>
          <IconButton
            onClick={() => setOrderDetailsOpen(false)}
            sx={{
              marginLeft: '16px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedOrderDetails && (
            <OrderCard
              order={selectedOrderDetails}
              onStatusChange={handleStatusChange}
              onCancelClick={handleCancelClick}
              getStatusIcon={getStatusIcon}
              getStatusLabel={getStatusLabel}
              formatDate={formatDate}
              formatPrice={formatPrice}
              t={t}
              navigate={navigate}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

const OrdersSkeleton: React.FC = () => (
  <Container maxWidth="lg">
    <Skeleton height={48} width={200} sx={{ mb: 3 }} />
    <Skeleton height={48} width="100%" sx={{ mb: 2 }} />
    {[1, 2, 3].map(i => (
      <Paper key={i} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton height={32} width="60%" sx={{ mb: 1 }} />
            <Skeleton height={24} width="40%" sx={{ mb: 1 }} />
            <Skeleton height={24} width="30%" sx={{ mb: 1 }} />
            <Skeleton height={24} width="50%" sx={{ mb: 1 }} />
          </Grid>
        </Grid>
      </Paper>
    ))}
  </Container>
);

interface OrderCardProps {
  order: Order;
  onStatusChange: (status: Order['status'], order: Order) => void;
  onCancelClick: (order: Order) => void;
  getStatusIcon: (status: Order['status']) => React.ReactElement;
  getStatusLabel: (status: Order['status']) => string;
  formatDate: (date: string) => string;
  formatPrice: (price: number | string) => string;
  t: TFunction<'translation', undefined>;
  navigate: (path: string) => void;
}

const OrderCard: React.FC<OrderCardProps> = ({
  order,
  onStatusChange,
  onCancelClick,
  getStatusIcon,
  getStatusLabel,
  formatDate,
  formatPrice,
  t,
  navigate,
}) => {
  const [statusDialog, setStatusDialog] = React.useState<{
    open: boolean;
    newStatus: Order['status'] | null;
  }>({ open: false, newStatus: null });
  const [acceptDialog, setAcceptDialog] = React.useState<{
    open: boolean;
    order: Order | null;
  }>({ open: false, order: null });

  const handleStatusClick = (newStatus: Order['status']) => {
    setStatusDialog({ open: true, newStatus });
  };

  const handleStatusConfirm = () => {
    if (statusDialog.newStatus) {
      onStatusChange(statusDialog.newStatus, order);
      setStatusDialog({ open: false, newStatus: null });
    }
  };

  const handleAcceptClick = (orderToAccept: Order) => {
    setAcceptDialog({ open: true, order: orderToAccept });
  };

  const handleAcceptConfirm = () => {
    if (acceptDialog.order) {
      onStatusChange('processing', acceptDialog.order);
      setAcceptDialog({ open: false, order: null });
    }
  };

  const getNextStatus = (currentStatus: Order['status']): Order['status'] | null => {
    const statusFlow: Record<Order['status'], Order['status'] | null> = {
      pending: 'processing',
      processing: 'enroute',
      enroute: 'delivered',
      delivered: null,
      cancelled: null,
    };
    return statusFlow[currentStatus];
  };

  return (
    <Grid item xs={12}>
      <Paper className={styles.orderCard}>
        <Box className={styles.orderHeader}>
          <Box className={styles.orderHeaderLeft}>
            <Box className={styles.orderIdSection}>
              <Typography variant="h6" className={styles.orderId}>
                ID: {order.id}
              </Typography>
              <Typography variant="body2" className={styles.orderDate}>
                {formatDate(order.orderDate)}
              </Typography>
            </Box>
            <Box className={styles.buyerInfo}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  padding: '8px 16px',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    transform: 'translateY(-1px)',
                  },
                }}
                onClick={() =>
                  // Guest account does not have a profile page
                  order.Buyer.email !== `guest_12345@guestaccount.com` && navigate(`/user-profile/${order.Buyer.id}`)
                }
              >
                <Avatar
                  src={order.Buyer.profilePhoto}
                  alt={order.Buyer.name}
                  sx={{
                    width: 40,
                    height: 40,
                    border: '2px solid #fff',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  }}
                >
                  {order.Buyer.name.charAt(0)}
                </Avatar>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme => theme.palette.primary.main }}>
                    {order.Buyer.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                    {t('salesOrders.order.buyer')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={styles.orderHeaderRight}>
            <Box className={styles.statusContainer}>
              <Chip
                icon={getStatusIcon(order.status)}
                label={getStatusLabel(order.status)}
                className={`${styles.statusChip} ${
                  styles[`status${order.status.charAt(0).toUpperCase() + order.status.slice(1)}`]
                }`}
              />
            </Box>
            {order.status === 'pending' ? (
              <Box className={styles.actionButtons}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleAcceptClick(order)}
                  size="small"
                  startIcon={<CheckCircleIcon />}
                  className={styles.actionButton}
                >
                  {t('salesOrders.actions.accept')}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => onCancelClick(order)}
                  size="small"
                  startIcon={<CancelIcon />}
                  className={styles.actionButton}
                >
                  {t('salesOrders.actions.refuse')}
                </Button>
              </Box>
            ) : (
              <>
                {(order.status === 'processing' || order.status === 'enroute') && (
                  <Box className={styles.actionButtons}>
                    {getNextStatus(order.status) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleStatusClick(getNextStatus(order.status)!)}
                        size="small"
                        startIcon={getStatusIcon(getNextStatus(order.status)!)}
                        className={styles.actionButton}
                      >
                        {t(`salesOrders.actions.markAs.${getNextStatus(order.status)}`)}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => onCancelClick(order)}
                      size="small"
                      startIcon={<CancelIcon />}
                      className={styles.actionButton}
                    >
                      {t('salesOrders.actions.cancelOrder')}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box className={styles.imageContainer}>
              {order.Product?.ProductImages && order.Product.ProductImages.length > 0 ? (
                <img
                  src={
                    order.Product.ProductImages.find(img => img.isMain)?.imageUrl ||
                    order.Product.ProductImages[0].imageUrl
                  }
                  alt={order.Product?.name}
                  className={styles.productImage}
                  onError={e => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = '/no-image-placeholder.png';
                  }}
                />
              ) : (
                <Box className={styles.noImageBox}>
                  <InventoryIcon sx={{ fontSize: 40, color: '#bdbdbd' }} />
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.noImage.title')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box className={styles.productDetails}>
              <Typography variant="h6" className={styles.productName}>
                {order.Product.name}
              </Typography>

              <Box className={styles.orderInfo}>
                <Box className={styles.summaryItem}>
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.order.quantity')}
                  </Typography>
                  <Typography className={styles.summaryValue}>{order.quantity}</Typography>
                </Box>
                <Box className={styles.summaryItem}>
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.order.totalPrice')}
                  </Typography>
                  <Typography className={styles.summaryValue}>{formatPrice(order.totalPrice)}</Typography>
                </Box>
                <Box className={styles.summaryItem}>
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.order.unitPrice')}
                  </Typography>
                  <Typography variant="body1" className={styles.summaryValue}>
                    {formatPrice(order.Product.price)}
                  </Typography>
                </Box>
              </Box>

              {order.attributes && Object.keys(order.attributes).length > 0 && (
                <Box className={styles.specificationsBox}>
                  <Typography variant="subtitle2" className={styles.specTitle}>
                    {t('salesOrders.order.specifications')}
                  </Typography>
                  {Object.entries(order.attributes).map(([key, values]) => (
                    <Box key={key} className={styles.specificationItem}>
                      <Typography variant="body2" color="textSecondary" className={styles.specKey}>
                        {key}:
                      </Typography>
                      <Typography variant="body2" className={styles.specValue}>
                        {Array.isArray(values) ? values.join(', ') : values}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Box className={styles.deliverySection}>
          <Typography variant="h6" className={styles.deliveryTitle}>
            <LocalShippingIcon sx={{ fontSize: 20 }} />
            {t('salesOrders.order.deliveryInfo.title')}
          </Typography>
          <Box className={styles.deliveryGrid}>
            <Box className={styles.deliveryRow}>
              <Box className={styles.deliveryItem}>
                <Box className={styles.deliveryLabel}>
                  <PersonIcon sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.order.deliveryInfo.name')}
                  </Typography>
                </Box>
                <Typography variant="body1" className={styles.deliveryValue}>
                  {order.buyerName}
                </Typography>
              </Box>
              <Box className={styles.deliveryItem}>
                <Box className={styles.deliveryLabel}>
                  <PhoneIcon sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.order.deliveryInfo.contact')}
                  </Typography>
                </Box>
                <Typography variant="body1" className={styles.deliveryValue}>
                  {order.phoneNumber}
                </Typography>
              </Box>
            </Box>
            <Box className={styles.deliveryRow}>
              <Box className={styles.deliveryItem}>
                <Box className={styles.deliveryLabel}>
                  <LocationCityIcon sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.order.deliveryInfo.city')}
                  </Typography>
                </Box>
                <Typography variant="body1" className={styles.deliveryValue}>
                  {order.buyerCity}
                </Typography>
              </Box>
              <Box className={styles.deliveryItem}>
                <Box className={styles.deliveryLabel}>
                  <HomeIcon sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="textSecondary">
                    {t('salesOrders.order.deliveryInfo.address')}
                  </Typography>
                </Box>
                <Typography variant="body1" className={styles.deliveryValue}>
                  {order.deliveryAddress}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Dialog open={statusDialog.open} onClose={() => setStatusDialog({ open: false, newStatus: null })}>
        <DialogTitle>{t('salesOrders.statusDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('salesOrders.statusDialog.message', {
              currentStatus: getStatusLabel(order.status),
              newStatus: statusDialog.newStatus ? getStatusLabel(statusDialog.newStatus) : '',
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialog({ open: false, newStatus: null })}>
            {t('salesOrders.statusDialog.cancel')}
          </Button>
          <Button onClick={handleStatusConfirm} color="primary" variant="contained">
            {t('salesOrders.statusDialog.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={acceptDialog.open} onClose={() => setAcceptDialog({ open: false, order: null })}>
        <DialogTitle>{t('salesOrders.acceptDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('salesOrders.acceptDialog.message', {
              orderNumber: acceptDialog.order?.id,
              buyerName: acceptDialog.order?.Buyer.name,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAcceptDialog({ open: false, order: null })}>
            {t('salesOrders.acceptDialog.cancel')}
          </Button>
          <Button onClick={handleAcceptConfirm} color="success" variant="contained">
            {t('salesOrders.acceptDialog.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

interface OrderSummaryProps {
  order: Order;
  formatDate: (date: string) => string;
  formatPrice: (price: number | string) => string;
  getStatusIcon: (status: Order['status']) => React.ReactElement;
  getStatusLabel: (status: Order['status']) => string;
  t: TFunction<'translation', undefined>;
  onOrderClick: (order: Order) => void;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  order,
  formatDate,
  formatPrice,
  getStatusIcon,
  getStatusLabel,
  t,
  onOrderClick,
}) => {
  return (
    <Paper
      className={styles.orderSummary}
      onClick={() => onOrderClick(order)}
      sx={{
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 3,
        },
      }}
    >
      <Box className={styles.summaryContent}>
        <Box className={styles.summaryImageContainer}>
          <img
            src={order.Product?.ProductImages?.[0]?.imageUrl || '/placeholder-image.jpg'}
            alt={order.Product?.name}
            className={styles.summaryImage}
          />
        </Box>

        <Box className={styles.summaryDetails}>
          <Typography variant="subtitle1" className={styles.summaryProductName}>
            {order.Product.name}
          </Typography>
          <Typography variant="body2" className={styles.summaryDate}>
            {formatDate(order.orderDate)}
          </Typography>
        </Box>

        <Box className={styles.summaryQuantity}>
          <Typography variant="body1">
            {t('salesOrders.orderInfo.quantity')}: {order.quantity}
          </Typography>
        </Box>

        <Box className={styles.summaryPrice}>
          <Typography variant="body1">{formatPrice(order.totalPrice)}</Typography>
        </Box>

        <Box className={styles.summaryStatus}>
          <Chip
            icon={getStatusIcon(order.status)}
            label={getStatusLabel(order.status)}
            className={styles[`status${order.status}`]}
            size="small"
          />
        </Box>

        <Box>
          <Typography className={styles.summaryOrderId}>ID: {order.id}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default SalesOrders;
