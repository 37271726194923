import React, { useEffect } from 'react';

interface User {
  id: number;
  auth_date: number;
  hash: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
}

interface Props {
  onAuthSuccess: (user: User) => void;
  botName: string;
}

const TelegramLogin: React.FC<Props> = ({ onAuthSuccess, botName }) => {
  useEffect(() => {
    // Define the callback function
    (window as any).onTelegramAuth = (user: User) => {
      onAuthSuccess(user);
    };

    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', 'medium');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    script.setAttribute('data-userpic', 'false');
    script.setAttribute('data-radius', '4');
    script.async = true;

    // Find the container element
    const container = document.getElementById('telegram-login-container');
    if (container) {
      // Clear any existing content
      container.innerHTML = '';
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.innerHTML = '';
      }
    };
  }, [botName, onAuthSuccess]);

  return (
    <div
      id="telegram-login-container"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '40px',
      }}
    />
  );
};

export default TelegramLogin;
