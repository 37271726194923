import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Paper,
  Typography,
  Avatar,
  Box,
  Button,
  Grid,
  Divider,
  CircularProgress,
  Chip,
  TextField,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Store as StoreIcon,
  Email as EmailIcon,
  LocationOn as LocationIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  Login as LoginIcon,
} from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';
import EndPoints from '../EndPointConfig';
import { deleteFromS3, uploadProfilePhotoToS3 } from '../utils/awsUtils';
import CreateStoreModal, { StoreCreationData } from '../Components/modals/CreateStoreModal';
import { useTranslation } from 'react-i18next';
import styles from './UserProfilePage.module.css';
import { SEOMetaTags } from '../Components/SEO/SEOMetaTags';
import { useAuth } from '../contexts/AuthContext';
import ActiveSellerProducts from '../Components/ActiveSellerProducts';

interface UserProfile {
  id: number;
  name: string;
  email: string;
  profilePhoto: string;
  storeName: string;
  address: string;
  bio: string;
  isSeller: boolean;
  phoneNumber: string;
  telegramUserId?: string | null;
}

interface CurrentUserInfo {
  //current account user info
  id: number;
  name: string;
  email: string;
  profilePhoto: string;
  storeName: string;
  address: string;
  bio: string;
  isSeller: boolean;
  phoneNumber: string;
  telegramUserId?: string | null;
}

const CHAR_LIMITS = {
  name: 20,
  storeName: 50,
  address: 100,
  phoneNumber: 10,
  bio: 200,
};

// Add userId to props interface
interface UserProfilePageProps {
  userId?: string; // Optional prop for direct usage
}

const UserProfilePage: React.FC<UserProfilePageProps> = ({ userId: propUserId }) => {
  const { t, i18n } = useTranslation();
  const { slug } = useParams<{ slug: string }>();

  const navigate = useNavigate();
  const { isAuthenticated, user, generateHeaders, logout } = useAuth();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [currentUserInfo, setCurrentUserInfo] = useState<CurrentUserInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState<UserProfile | null>(null);
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
  const [storeNameError, setStoreNameError] = useState<string | null>(null);
  const [isHeaderExist, setIsHeaderExist] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const MIN_CHARS = {
    name: 3,
  };
  const userIdFromSlug = slug?.split('-').pop();

  const effectiveUserId = userIdFromSlug || propUserId;

  // Set the header visibility based on the URL parameter
  useEffect(() => {
    setIsHeaderExist(!!userIdFromSlug);
  }, [userIdFromSlug]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        //get user profile
        const response = await axios.get(`${EndPoints.GET_SELLER}/${effectiveUserId}`);
        setUserProfile(response.data);

        //get current user info, to check if the user is the same as the one being viewed
        if (user) {
          const headers = generateHeaders(user);
          const currentUserResponse = await axios.get(EndPoints.GET_SELLER, { headers });
          setCurrentUserInfo(currentUserResponse.data);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError('Failed to load user profile');
      } finally {
        setLoading(false);
      }
    };

    if (effectiveUserId) {
      fetchUserProfile();
    }
  }, [effectiveUserId, user, generateHeaders]);

  useEffect(() => {
    if (userProfile) {
      setEditedProfile(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    if (error) {
      setIsErrorModalOpen(true);
    }
  }, [error]);

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setError(null);
  };

  const handleMessageClick = () => {
    if (!isAuthenticated || !user) {
      navigate('/login');
      return;
    }
    navigate(`/messages?seller=${effectiveUserId}`);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const CheckStoreNameUniqueness = async (storeName: string | undefined) => {
    try {
      if (user && storeName) {
        const headers = generateHeaders(user);
        const encodedStoreName = encodeURIComponent(storeName);
        const response = await axios.get(`${EndPoints.CHECK_STORE_NAME_AVAILABILITY}/${encodedStoreName}`, { headers });
        return response.data;
      }
    } catch (error: any) {
      // Type the error and handle specific status codes
      if (error.response?.status === 409) {
        setStoreNameError(t('userProfile.errors.storeNameTaken'));
        return false;
      }
      console.error('Error checking store name uniqueness:', error);
      setError(t('userProfile.errors.storeNameTaken'));
      return false;
    }
    return true;
  };

  const handleSaveClick = async () => {
    try {
      setIsSaving(true);
      const updatedFields = {} as any;

      // Check store name uniqueness before proceeding with save
      if (editedProfile?.storeName && editedProfile.storeName !== userProfile?.storeName) {
        const isStoreNameAvailable = await CheckStoreNameUniqueness(editedProfile.storeName);
        if (!isStoreNameAvailable) {
          setIsSaving(false);
          return;
        }
      }

      if (selectedFile) {
        // Delete old profile photo from S3 if it exists
        if (userProfile?.profilePhoto && userProfile.profilePhoto.startsWith('http')) {
          try {
            await deleteFromS3(userProfile.profilePhoto);
          } catch (error) {
            console.error('Error deleting old profile photo:', error);
          }
        }

        const photoUrl = await uploadProfilePhotoToS3(selectedFile);
        updatedFields.profilePhoto = photoUrl;
      }

      if (editedProfile) {
        Object.keys(editedProfile).forEach(key => {
          const k = key as keyof UserProfile;
          // Profile photos are stored in AWS S3, not directly in the database, and require special handling
          if (k !== 'profilePhoto' && editedProfile[k] !== userProfile?.[k]) {
            updatedFields[k] = editedProfile[k];
          }
        });
      }

      if (Object.keys(updatedFields).length === 0) {
        setIsEditing(false);
        return;
      }

      if (user) {
        const headers = generateHeaders(user);
        await axios.put(EndPoints.UPSERT_SELLER, { ...editedProfile, ...updatedFields }, { headers });
      }

      setUserProfile(prev => (prev ? { ...prev, ...updatedFields } : null));
      setIsEditing(false);
      setError(null);
      setStoreNameError(null);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(t('userProfile.errors.updateFailed'));
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (field: keyof UserProfile) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editedProfile) {
      let value = event.target.value;

      // Special handling for phone number
      if (field === 'phoneNumber') {
        // Remove any non-digit characters and limit length
        value = value.replace(/\D/g, '').slice(0, CHAR_LIMITS.phoneNumber);
      }

      // Prevent the field from being just whitespace
      if (value.trim().length === 0) {
        value = '';
      }

      setEditedProfile({
        ...editedProfile,
        [field]: value,
      });
    }
  };

  const handleCancelClick = () => {
    setEditedProfile(userProfile);
    setPreviewPhoto(null);
    setSelectedFile(null);
    setIsEditing(false);
  };

  const handleProfilePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) return;

    try {
      const file = event.target.files[0];
      // Store the file for later upload
      setSelectedFile(file);
      // Create preview URL
      const previewUrl = URL.createObjectURL(file);
      setPreviewPhoto(previewUrl);
    } catch (error) {
      console.error('Error handling profile photo:', error);
      setPreviewPhoto(null);
    }
  };

  // Add cleanup for preview URL
  useEffect(() => {
    return () => {
      if (previewPhoto) {
        URL.revokeObjectURL(previewPhoto);
      }
    };
  }, [previewPhoto]);

  const handleStoreModalOpen = () => {
    setIsStoreModalOpen(true);
  };

  const handleStoreModalClose = () => {
    setIsStoreModalOpen(false);
  };

  const handleCreateStore = async (storeData: StoreCreationData) => {
    try {
      if (user) {
        const headers = generateHeaders(user);
        await axios.post(EndPoints.UPSERT_SELLER, { ...storeData, isSeller: true }, { headers });
        window.location.reload();
      }
    } catch (error) {
      console.error('Error creating store:', error);
      setError('Failed to create store');
    }
    setIsStoreModalOpen(false);
  };

  // Add this helper function at the component level
  const getCharacterCount = (text: string, limit: number) => `${text.length}/${limit}`;

  // Update the isFormValid function
  const isFormValid = () => {
    if (!editedProfile) return false;

    // Basic validation for all users
    if (!editedProfile.name?.trim() || editedProfile.name.trim().length < MIN_CHARS.name) {
      return false;
    }

    // Additional validation only for sellers
    if (userProfile?.isSeller) {
      if (!editedProfile.bio?.trim()) {
        return false;
      }
      if (!editedProfile.storeName?.trim()) {
        return false;
      }
      if (!editedProfile.address?.trim()) {
        return false;
      }
    }

    return true;
  };

  //  helper function to check if a field is empty
  const isFieldEmpty = (field: string | undefined): boolean => {
    return !field || field.trim().length === 0;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  // Remove the error-only return and instead show error as a banner if it exists
  return (
    <Container maxWidth="lg" sx={{ py: 3 }} className={styles.container}>
      <Dialog
        open={isErrorModalOpen}
        onClose={handleCloseErrorModal}
        className={styles.errorModal}
        PaperProps={{
          elevation: 3,
        }}
      >
        <DialogTitle className={styles.errorModalTitle}>{t('common.error')}</DialogTitle>
        <DialogContent className={styles.errorModalContent}>
          <Typography>{error}</Typography>
        </DialogContent>
        <DialogActions className={styles.errorModalActions}>
          <Button
            onClick={handleCloseErrorModal}
            variant="contained"
            color="primary"
            className={styles.errorModalButton}
          >
            {t('common.close')}
          </Button>
        </DialogActions>
      </Dialog>

      {userProfile && (
        <SEOMetaTags
          title={
            userProfile.isSeller
              ? `${userProfile.storeName} | ${t('seo.userProfile.sellerTitle')}`
              : `${userProfile.name} | ${t('seo.userProfile.userTitle')}`
          }
          description={
            userProfile.isSeller
              ? `${userProfile.storeName} - ${userProfile.bio?.slice(0, 150)}`
              : t('seo.userProfile.description', { name: userProfile.name })
          }
          keywords={
            userProfile.isSeller
              ? `${userProfile.storeName}, ${t('seo.userProfile.sellerKeywords')}`
              : t('seo.userProfile.userKeywords')
          }
          image={userProfile.profilePhoto}
          url={`/profile/${effectiveUserId}`}
          language={i18n.language}
        />
      )}

      {isHeaderExist ? (
        <Box className={styles.headerSection}>
          <Container maxWidth="lg">
            <Box className={styles.topNav}>
              <Link to="/" className={styles.platformName}>
                {t('home.title')}
              </Link>
              <Box className={styles.headerButtons}>
                <Button
                  variant="outlined"
                  startIcon={<HomeIcon />}
                  onClick={() => navigate('/')}
                  className={styles.headerButton}
                >
                  {t('dashboard.buttons.home')}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={isAuthenticated ? <LogoutIcon /> : <LoginIcon />}
                  onClick={() => (isAuthenticated ? logout() : navigate('/login'))}
                  className={styles.headerButton}
                >
                  {isAuthenticated ? t('auth.logout') : t('auth.login')}
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : null}

      <Grid
        container
        spacing={{ xs: 1, sm: 1.5, md: 2 }}
        sx={{
          flexDirection: { xs: 'column ', lg: 'row' },
          '& > .MuiGrid-item': {
            width: '100%', // Ensure full width when stacked
          },
          mb: 4,
        }}
      >
        {/* Left Column - Profile Header */}
        <Grid
          item
          xs={12}
          lg={4} // Change breakpoint from md to lg (1200px)
        >
          <Paper
            elevation={3}
            sx={{
              p: { xs: 1.5, sm: 2, md: 3 },
              height: '100%',
              mb: { xs: 2, lg: 0 }, // Add bottom margin when stacked
            }}
          >
            <Box sx={{ textAlign: 'center', mb: 3 }}>
              <Box position="relative" sx={{ display: 'inline-block' }}>
                <Avatar
                  src={previewPhoto || userProfile?.profilePhoto}
                  sx={{
                    width: 120,
                    height: 120,
                    border: '4px solid rgba(25, 118, 210, 0.1)',
                    mb: 2,
                    cursor: isEditing ? 'pointer' : 'default',
                  }}
                  onClick={() => isEditing && document.getElementById('profile-photo-input')?.click()}
                />
                {isEditing && (
                  <input
                    type="file"
                    id="profile-photo-input"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleProfilePhotoUpload}
                  />
                )}
              </Box>

              {/* Name Field */}
              {isEditing ? (
                <TextField
                  required
                  fullWidth
                  label={t('userProfile.labels.name')}
                  value={editedProfile?.name || ''}
                  onChange={handleInputChange('name')}
                  inputProps={{ maxLength: CHAR_LIMITS.name }}
                  helperText={
                    editedProfile?.name && editedProfile.name.length < MIN_CHARS.name
                      ? `Minimum ${MIN_CHARS.name} characters required`
                      : getCharacterCount(editedProfile?.name || '', CHAR_LIMITS.name)
                  }
                  error={
                    !!(
                      isFieldEmpty(editedProfile?.name) ||
                      (editedProfile?.name && editedProfile.name.length < MIN_CHARS.name)
                    )
                  }
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                  {userProfile?.name}
                </Typography>
              )}

              {userProfile?.isSeller && (
                <Chip
                  {...(document.dir === 'rtl'
                    ? {
                        deleteIcon: <StoreIcon />,
                        onDelete: () => {},
                        sx: {
                          mb: 2,
                          '& .MuiChip-deleteIcon': {
                            color: 'inherit',
                            order: 1,
                          },
                        },
                      }
                    : {
                        icon: <StoreIcon />,
                        sx: { mb: 2 },
                      })}
                  label={t('userProfile.seller.tag')}
                  color="primary"
                  className={styles.sellerChip}
                />
              )}

              {/* Action Buttons */}
              <Box sx={{ mt: 2 }}>
                {currentUserInfo && currentUserInfo?.id !== Number(effectiveUserId) ? (
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<EmailIcon />}
                    className={styles.userMessage}
                    onClick={handleMessageClick}
                  >
                    {t('userProfile.buttons.message')}
                  </Button>
                ) : (
                  <Stack spacing={1}>
                    {isEditing ? (
                      <>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={handleSaveClick}
                          disabled={!isFormValid() || isSaving}
                        >
                          {isSaving ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            t('userProfile.buttons.saveProfile')
                          )}
                        </Button>
                        <Button fullWidth variant="outlined" onClick={handleCancelClick}>
                          {t('userProfile.buttons.cancel')}
                        </Button>
                      </>
                    ) : (
                      <>
                        {currentUserInfo?.id === Number(effectiveUserId) && (
                          <>
                            <Button fullWidth variant="contained" onClick={handleEditClick}>
                              {t('userProfile.buttons.editProfile')}
                            </Button>

                            {!userProfile?.isSeller && (
                              <Button fullWidth variant="contained" color="secondary" onClick={handleStoreModalOpen}>
                                {t('userProfile.buttons.createStore')}
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Stack>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Right Column - Profile Details */}
        <Grid
          item
          xs={12}
          lg={8} // Change breakpoint from md to lg (1200px)
        >
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            {userProfile?.isSeller ? (
              <Grid container spacing={3}>
                {/* Store Information */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StoreIcon color="primary" /> {t('userProfile.labels.storeInformation')}
                  </Typography>
                  {isEditing ? (
                    <TextField
                      required
                      fullWidth
                      label={t('userProfile.labels.storeName')}
                      value={editedProfile?.storeName || ''}
                      onChange={handleInputChange('storeName')}
                      inputProps={{ maxLength: CHAR_LIMITS.storeName }}
                      helperText={
                        storeNameError || getCharacterCount(editedProfile?.storeName || '', CHAR_LIMITS.storeName)
                      }
                      error={isFieldEmpty(editedProfile?.storeName) || !!storeNameError}
                      sx={{ mt: 1 }}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={styles.userInfoField}
                      sx={{
                        height: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                      }}
                    >
                      {userProfile?.storeName}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Contact Information */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {t('userProfile.labels.contactInformation')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocationIcon color="primary" /> {t('userProfile.labels.address')}
                  </Typography>
                  {isEditing ? (
                    <TextField
                      required
                      fullWidth
                      multiline
                      label={t('userProfile.labels.address')}
                      minRows={2}
                      maxRows={4}
                      value={editedProfile?.address || ''}
                      onChange={handleInputChange('address')}
                      inputProps={{ maxLength: CHAR_LIMITS.address }}
                      helperText={getCharacterCount(editedProfile?.address || '', CHAR_LIMITS.address)}
                      error={isFieldEmpty(editedProfile?.address)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 'auto',
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={styles.userInfoField}
                      sx={{
                        height: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                      }}
                    >
                      {userProfile.address || t('userProfile.placeholders.noAddress')}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PhoneIcon color="primary" /> {t('userProfile.labels.phone')}
                  </Typography>
                  {isEditing ? (
                    <TextField
                      fullWidth
                      label={t('userProfile.labels.phone')}
                      value={editedProfile?.phoneNumber || ''}
                      onChange={handleInputChange('phoneNumber')}
                      inputProps={{ maxLength: CHAR_LIMITS.phoneNumber }}
                      helperText={getCharacterCount(editedProfile?.phoneNumber || '', CHAR_LIMITS.phoneNumber)}
                    />
                  ) : (
                    <Typography variant="body2" color="text.secondary" className={styles.userInfoField}>
                      {userProfile.phoneNumber || t('userProfile.placeholders.noPhone')}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>

                {/* Bio */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <PersonIcon color="primary" /> {t('userProfile.labels.about')}
                  </Typography>
                  {isEditing ? (
                    <TextField
                      required
                      fullWidth
                      multiline
                      label={t('userProfile.labels.about')}
                      minRows={3}
                      maxRows={6}
                      value={editedProfile?.bio || ''}
                      onChange={handleInputChange('bio')}
                      inputProps={{ maxLength: CHAR_LIMITS.bio }}
                      helperText={getCharacterCount(editedProfile?.bio || '', CHAR_LIMITS.bio)}
                      error={isFieldEmpty(editedProfile?.bio)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: 'auto',
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={styles.userInfoField}
                      sx={{
                        height: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                      }}
                    >
                      {userProfile.bio || t('userProfile.placeholders.noBio')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              // Non-seller view
              <Box sx={{ textAlign: 'center', py: 4 }}>
                {currentUserInfo && currentUserInfo?.id === Number(effectiveUserId) ? (
                  <>
                    <Typography variant="h6" color="text.secondary">
                      {t('userProfile.seller.createPrompt')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {t('userProfile.seller.createDescription')}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    {t('userProfile.seller.notSeller')}
                  </Typography>
                )}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      <CreateStoreModal
        open={isStoreModalOpen}
        onClose={handleStoreModalClose}
        onSubmit={handleCreateStore}
        initialData={{
          email: userProfile?.email || '',
          name: userProfile?.name || '',
          storeName: '',
          profilePhoto: '',
          address: '',
          phoneNumber: '',
          bio: '',
        }}
        charLimits={CHAR_LIMITS}
      />
      {isHeaderExist && <ActiveSellerProducts sellerId={userProfile?.id || 0} />}
    </Container>
  );
};

export default UserProfilePage;
