import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Rating } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './ShopCard.module.css';

interface ShopCardProps {
  productId: number;
  productName: string;
  productPrice: number | string;
  productImage: string;
  storeName?: string;
  sellerProfilePhoto?: string;
  minOrder: number;
  averageRating?: number;
  totalReviews?: number;
  availableStock?: number;
}

const ShopCard: React.FC<ShopCardProps> = ({
  productId,
  productName,
  productPrice,
  productImage,
  storeName,
  sellerProfilePhoto,
  minOrder,
  averageRating = 0,
  totalReviews = 0,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formatPrice = (price: number | string): string => {
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

    if (isNaN(numericPrice)) {
      return '0';
    }

    const currencyFormat = t('currency.format');
    const formattedPrice = numericPrice
      .toFixed(Number(t('currency.precision')))
      .replace('.', t('currency.decimal'))
      .replace(/\B(?=(\d{3})+(?!\d))/g, t('currency.thousand'));

    return currencyFormat.replace('{amount}', formattedPrice);
  };

  const generateSlug = (name: string): string => {
    return `${name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '')}-${productId}`;
  };

  const handleClick = () => {
    navigate(`/product/${generateSlug(productName)}`);
  };

  const truncateStoreName = (name: string) => {
    return name.length > 20 ? `${name.substring(0, 20)}...` : name;
  };

  return (
    <Card className={styles.shopCard} onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div className={styles.imageSection}>
        <img src={productImage} alt={productName} className={styles.productImage} />
        <div className={styles.imageOverlay} />
        {storeName && (
          <Chip
            avatar={
              sellerProfilePhoto ? (
                <img src={sellerProfilePhoto} alt={storeName} className={styles.sellerAvatar} />
              ) : (
                <StorefrontIcon />
              )
            }
            label={truncateStoreName(storeName)}
            size="small"
            className={styles.storeNameBadge}
            sx={{ maxWidth: '70%' }}
          />
        )}
      </div>

      <CardContent className={styles.contentSection}>
        <Typography variant="h6" className={styles.productName}>
          {productName}
        </Typography>

        {averageRating > 0 && (
          <Box className={styles.ratingSection}>
            <Rating value={averageRating} precision={0.5} readOnly size="small" className={styles.rating} />
            <Typography variant="body2" className={styles.ratingValue}>
              {averageRating.toFixed(1)}
            </Typography>
            <Typography variant="body2" className={styles.reviewCount}>
              (
              {t(totalReviews === 1 ? 'shopCard.reviews.single' : 'shopCard.reviews.multiple', { count: totalReviews })}
              )
            </Typography>
          </Box>
        )}

        <Box className={styles.priceActionSection}>
          <Box className={styles.priceContainer}>
            <Typography variant="h6" className={styles.price}>
              {formatPrice(productPrice)}
            </Typography>
            <Box className={styles.minOrderContainer}>
              <LocalShippingIcon className={styles.minOrderIcon} />
              <Typography variant="body2" className={styles.minOrderText}>
                {t('shopCard.minOrder', { count: minOrder })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ShopCard;
