import React from 'react';
import { Container, Grid, Typography, Divider } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <Container maxWidth={false}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className={styles.footerTitle}>
              {t('footer.about.title')}
            </Typography>
            <Typography variant="body2" className={styles.footerText}>
              {t('footer.about.description')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className={styles.footerTitle}>
              {t('footer.quickLinks.title')}
            </Typography>
            <ul className={styles.footerLinks}>
              <li>
                <a href="/about" target="_blank" rel="noopener noreferrer">
                  {t('footer.quickLinks.aboutUs')}
                </a>
              </li>
              <li>
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  {t('footer.quickLinks.terms')}
                </a>
              </li>
              <li>
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  {t('footer.quickLinks.privacy')}
                </a>
              </li>
              <li>
                <a href="/faq" target="_blank" rel="noopener noreferrer">
                  {t('footer.quickLinks.faq')}
                </a>
              </li>
              <li>
                <a href="/seller-center" target="_blank" rel="noopener noreferrer">
                  {t('footer.quickLinks.becomeSeller')}
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className={styles.footerTitle}>
              {t('footer.contact.title')}
            </Typography>
            <div className={styles.contactInfo}>
              <div className={styles.contactItem}></div>
              <div className={styles.contactItem}>
                <EmailIcon className={styles.contactIcon} />
                <Typography variant="body2">support@titrom.com</Typography>
              </div>
              <div className={styles.contactItem}>
                <a
                  href="https://wa.me/212638481837"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.whatsappLink}
                >
                  <WhatsAppIcon className={`${styles.contactIcon} ${styles.whatsappIcon}`} />
                  <Typography variant="body2" className={styles.phoneNumber}>
                    (+212) 638 481 837
                  </Typography>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
        <Divider className={styles.footerDivider} />
      </Container>
    </footer>
  );
};

export default Footer;
