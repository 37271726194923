import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TelegramLogin from '../Components/TelegramLogin';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import styles from './Login.module.css';

interface TelegramUser {
  id: number;
  auth_date: number;
  hash: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
}

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const handleTelegramResponse = async (user: TelegramUser) => {
    try {
      await login(user);
      const from = (location.state as { from?: string })?.from || '/dashboard';
      navigate(from, { replace: true });
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <h1 className={styles.title}>{t('login.welcome')}</h1>
        <p className={styles.subtitle}>{t('login.loginWith')}</p>

        <div className={styles.loginButton}>
          <TelegramLogin
            botName={process.env.REACT_APP_TELEGRAM_BOT_NAME || ''}
            onAuthSuccess={handleTelegramResponse}
          />
        </div>

        <p className={styles.tosText}>
          {t('login.agreeTos')}{' '}
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            {t('login.termsOfService')}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
