export const enContent = {
  title: 'Frequently Asked Questions (FAQ)',
  introduction:
    "Welcome to the Titrom FAQ page! Here, you'll find answers to common questions about using our platform. If you have additional queries, feel free to contact our support team via email or WhatsApp.",
  sections: [
    {
      title: 'Getting Started',
      subsections: [
        {
          subtitle: 'How do I create an account?',
          content:
            'To create an account:\n• Go to the homepage.\n• Click on "Register" if you don\'t have an account, or "Login" if you already have one.\n• Authenticate using Telegram.\n• Once registered, you can start listing your products for sale.',
        },
        {
          subtitle: 'Can I browse products without registering?',
          content:
            'Yes, you can browse and buy products without an account. However, to sell products, you need to create an account.',
        },
        {
          subtitle: 'Is there an age requirement to create an account?',
          content: 'Yes, you must be 18 years or older to create an account and use the services provided by Titrom.',
        },
      ],
    },
    {
      title: 'Account Management',
      subsections: [
        {
          subtitle: "What should I do if I can't log in?",
          content:
            "If you're having trouble logging in:\n• Ensure you're authenticated using Telegram.\n• If issues persist, please contact our support team for assistance.",
        },
        {
          subtitle: 'How can I update my account details?',
          content:
            'To update your account details:\n• Navigate to the "My Account" tab on the dashboard.\n• Alternatively, click on your profile icon in the homepage header.\n• From there, you can update your personal or store information.',
        },
      ],
    },
    {
      title: 'Buying and Selling',
      subsections: [
        {
          subtitle: 'How do I place an order?',
          content:
            'To place an order:\n• Click on the product you wish to buy.\n• Select the desired quantity (ensure you meet the minimum order quantity for wholesale).\n• Click "Buy Now" and complete the delivery information form.\n•You can purchase products as a guest without needing an account.',
        },
        {
          subtitle: 'Can I change or cancel my order?',
          content:
            'Yes, you can change or cancel your order by contacting the seller directly through the platform. Ensure to act promptly before the order is shipped.',
        },
        {
          subtitle: 'How do I list my products as a seller?',
          content:
            'To list products:\n• Log in to your account.\n• Go to dashboard.\n• select my account tab and creat store if you don\'t have one.\n• Go to the "My Store" section \n• Add product details, including name, description, price, and minimum order quantity.\n• Once approved, your products will be visible to buyers.',
        },
      ],
    },
    {
      title: 'Payments and Delivery',
      subsections: [
        {
          subtitle: 'What payment methods are accepted?',
          content:
            'Currently, Titrom supports cash on delivery (COD). This ensures secure transactions where buyers inspect the goods before making payments.',
        },
        {
          subtitle: "Can I refuse to pay if the product doesn't meet expectations?",
          content:
            'Yes, buyers have the right to inspect goods upon delivery. If the product does not meet the agreed condition, you can refuse payment.',
        },
        {
          subtitle: 'How can I track my order?',
          content:
            'If you purchased while logged in:• Go to the "My Purchases" tab in your dashboard to track your orders.\nIf you purchased as a guest:• Contact the seller directly through the platform for order status updates.',
        },
      ],
    },
    {
      title: 'Support and Feedback',
      subsections: [
        {
          subtitle: 'How can I contact support?',
          content:
            "You can reach us through:\n• Email: support@titrom.com\n• WhatsApp: +212 XXXXXXXXX\nWe're here to help with any questions or concerns.",
        },
        {
          subtitle: 'How can I provide feedback?',
          content:
            'We value your feedback! You can share your thoughts or suggestions through our Contact Us page or email us directly.',
        },
      ],
    },
  ],
  footer: {
    contactInfo: 'Need more help? Contact our support team',
    additionalInfo: 'For detailed information, please refer to our Terms of Service',
  },
};
