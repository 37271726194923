import React from 'react';
import { Card, CardContent, Typography, Box, Chip, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NoImageIcon from '@mui/icons-material/Image';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './ProductCard.module.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Product {
  id: number;
  name: string;
  categoryId: number;
  description: string;
  price: number;
  stock: number;
  ProductImages: Array<{
    displayOrder: number;
    imageUrl: string;
    isMain: boolean;
  }>;
  isActive: boolean;
  minOrder: number;
  attributes: Record<string, string[]>;
  createdAt: string;
  category?: {
    id: number;
    name: string;
    path: string;
    slug: string;
    parent?: {
      id: number;
      name: string;
      path: string;
    };
  };
}

interface ProductCardProps {
  product: Product;
  onEdit: (product: Product) => void;
  onDelete: (product: Product) => void;
  onToggleActive: (product: Product) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, onEdit, onDelete, onToggleActive }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formatPrice = (price: number | string) => {
    // Convert price to number if it's a string
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

    // Check if it's a valid number
    if (isNaN(numericPrice)) {
      return '0'; // or handle invalid price differently
    }

    const currencyFormat = t('currency.format');
    const formattedPrice = numericPrice
      .toFixed(Number(t('currency.precision')))
      .replace('.', t('currency.decimal'))
      .replace(/\B(?=(\d{3})+(?!\d))/g, t('currency.thousand'));

    return currencyFormat.replace('{amount}', formattedPrice);
  };

  const handleCardClick = (event: React.MouseEvent) => {
    // Prevent navigation if clicking on action buttons
    if ((event.target as HTMLElement).closest('.productActions')) {
      return;
    }
    navigate(`/product/${product.id}`);
  };

  return (
    <Card elevation={0} className={styles.productCard} onClick={handleCardClick} sx={{ cursor: 'pointer' }}>
      {/* Image Section with Overlay */}
      <Box className={styles.imageSection}>
        {/* Main Image */}
        {product.ProductImages && product.ProductImages.length > 0 ? (
          <img
            src={product.ProductImages.find(img => img.isMain)?.imageUrl || product.ProductImages[0].imageUrl}
            alt={product.name}
            className={styles.productImage}
          />
        ) : (
          <Box className={styles.noImageBox}>
            <NoImageIcon sx={{ fontSize: 60, color: '#bdbdbd' }} />
          </Box>
        )}

        {/* Overlay with gradient */}
        <Box className={styles.imageOverlay} />

        {/* Stock Badge */}
        <Chip
          label={
            product.stock > 0
              ? product.stock > 10
                ? t('productCard.stock.inStock', { count: product.stock })
                : t('productCard.stock.lowStock', { count: product.stock })
              : t('productCard.stock.outOfStock')
          }
          color={product.stock > 0 ? (product.stock > 10 ? 'success' : 'warning') : 'error'}
          size="small"
          className={styles.stockBadge}
        />
      </Box>

      {/* Content Section */}
      <CardContent className={styles.productContent}>
        <Typography variant="h6" className={styles.productName}>
          {product.name}
        </Typography>

        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" color="text.secondary" className={styles.minOrder}>
            {t('productCard.minOrder', { count: product.minOrder })}
          </Typography>
          <Typography variant="h6" className={styles.productPrice}>
            {formatPrice(product.price)}
          </Typography>
        </Box>
      </CardContent>

      {/* Action Buttons - Add stopPropagation to prevent card click when clicking buttons */}
      <Box className={`${styles.productActions} productActions`} onClick={e => e.stopPropagation()}>
        <Tooltip title={product.isActive ? t('productCard.actions.deactivate') : t('productCard.actions.activate')}>
          <IconButton
            onClick={() => onToggleActive(product)}
            size="small"
            className={product.isActive ? styles.deactivateButton : styles.activateButton}
          >
            {product.isActive ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
          </IconButton>
        </Tooltip>

        <Tooltip title={t('productCard.actions.edit')}>
          <IconButton onClick={() => onEdit(product)} size="small" className={styles.editButton}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('productCard.actions.delete')}>
          <IconButton onClick={() => onDelete(product)} size="small" className={styles.deleteButton}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
};

export default ProductCard;
