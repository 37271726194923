import React, { useState, useEffect, useCallback } from 'react';
import { Container, Tabs, Tab, Box, Paper, Badge, Button, Tooltip, IconButton } from '@mui/material';
import axios from 'axios';
import UserProfilePage from './UserProfilePage';
import Store from './Store';
import styles from './UserDashboard.module.css';
import {
  Person as UserIcon,
  Inventory as ProductIcon,
  Message as MessageIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  ShoppingBag as ShoppingBagIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import EndPoints from '../EndPointConfig';
import MessagePage from './MessagePage';
import { useSocket } from '../contexts/SocketContext';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import PurchaseOrders from './PurchaseOrders';
import { useTranslation } from 'react-i18next';
import { Drawer } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import TelegramIcon from '@mui/icons-material/Telegram';
const UserDashboard: React.FC = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<number>(() => {
    // First check localStorage, then location state, then default to 0
    const savedTab = localStorage.getItem('dashboardActiveTab');
    if (savedTab !== null) {
      return parseInt(savedTab);
    }
    return location.state?.activeTab ?? 0;
  });
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [unreadSellerNotifications, setUnreadSellerNotifications] = useState<number>(0);
  const [unreadBuyerNotifications, setUnreadBuyerNotifications] = useState<number>(0);
  const { isAuthenticated, user, logout, generateHeaders } = useAuth();
  const [sellerInfo, setSellerInfo] = useState<{ id: number | null; isSeller: boolean }>({
    id: null,
    isSeller: false,
  });
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const socket = useSocket();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Combined function to fetch seller information
  const fetchSellerInfo = useCallback(async () => {
    try {
      const headers = user ? generateHeaders(user) : {};
      const response = await axios.get(EndPoints.GET_SELLER, { headers });
      setSellerInfo({
        id: response.data.id,
        isSeller: response.data.isSeller,
      });
    } catch (error) {
      console.error('Error fetching seller information:', error);
      setSellerInfo({ id: null, isSeller: false });
    }
  }, [user, generateHeaders]);

  // Single useEffect for seller information
  useEffect(() => {
    if (isAuthenticated) {
      fetchSellerInfo();
    }
  }, [isAuthenticated, fetchSellerInfo]);

  // Add this function to fetch unread messages count
  const fetchUnreadMessagesCount = useCallback(async () => {
    try {
      const headers = user ? generateHeaders(user) : {};
      const response = await axios.get(EndPoints.GET_UNREAD_MESSAGES_COUNT, { headers });
      setUnreadMessages(response.data.count);
    } catch (error) {
      console.error('Error fetching unread messages:', error);
    }
  }, [user, generateHeaders]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUnreadMessagesCount();
    }
  }, [isAuthenticated, fetchUnreadMessagesCount]);

  // Add this function to fetch unread seller notifications
  const fetchUnreadSellerNotifications = useCallback(async () => {
    try {
      const headers = user ? generateHeaders(user) : {};
      const response = await axios.get(EndPoints.GET_UNREAD_SELLER_NOTIFICATIONS_COUNT, { headers });
      setUnreadSellerNotifications(response.data.count);
    } catch (error) {
      console.error('Error fetching unread seller notifications:', error);
    }
  }, [user, generateHeaders]);

  useEffect(() => {
    if (isAuthenticated && sellerInfo.isSeller) {
      fetchUnreadSellerNotifications();
    }
  }, [isAuthenticated, sellerInfo.isSeller, fetchUnreadSellerNotifications]);

  // Add this function to fetch unread buyer notifications
  const fetchUnreadBuyerNotifications = useCallback(async () => {
    try {
      const headers = user ? generateHeaders(user) : {};
      const response = await axios.get(EndPoints.GET_UNREAD_BUYER_NOTIFICATIONS_COUNT, { headers });
      setUnreadBuyerNotifications(response.data.count);
    } catch (error) {
      console.error('Error fetching unread buyer notifications:', error);
    }
  }, [user, generateHeaders]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUnreadBuyerNotifications();
    }
  }, [isAuthenticated, fetchUnreadBuyerNotifications]);

  // Add this useEffect after your existing useEffects
  useEffect(() => {
    if (isAuthenticated) {
      // Initial fetch for both buyer and seller notifications
      fetchUnreadBuyerNotifications();
      if (sellerInfo.isSeller) {
        fetchUnreadSellerNotifications();
      }

      // Set up polling every 30 seconds
      const interval = setInterval(() => {
        fetchUnreadBuyerNotifications();
        if (sellerInfo.isSeller) {
          fetchUnreadSellerNotifications();
        }
      }, 30000);

      // Cleanup interval on unmount
      return () => clearInterval(interval);
    }
  }, [isAuthenticated, sellerInfo.isSeller, fetchUnreadBuyerNotifications, fetchUnreadSellerNotifications]);

  // Function to handle tab changes
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    try {
      setActiveTab(newValue);
      localStorage.setItem('dashboardActiveTab', newValue.toString());
    } catch (error) {
      console.error('Error changing tab:', error);
    }
  };

  // Add inside the UserDashboard component after the existing useEffects
  useEffect(() => {
    if (socket && isAuthenticated && sellerInfo.id) {
      try {
        socket.emit('authenticate', sellerInfo.id);

        socket.on('error', error => {
          console.error('Socket error:', error);
        });

        socket.on('connect_error', error => {
          console.error('Socket connection error:', error);
        });

        socket.on('newMessageNotification', data => {
          try {
            setUnreadMessages(prev => prev + data.count);
          } catch (error) {
            console.error('Error handling message notification:', error);
          }
        });

        return () => {
          socket.off('newMessageNotification');
          socket.off('error');
          socket.off('connect_error');
        };
      } catch (error) {
        console.error('Error setting up socket connection:', error);
      }
    }
  }, [socket, isAuthenticated, sellerInfo.id]);

  useEffect(() => {
    try {
      // Update activeTab when location state changes
      if (location.state?.activeTab !== undefined) {
        setActiveTab(location.state.activeTab);
        localStorage.setItem('dashboardActiveTab', location.state.activeTab.toString());
      }
    } catch (error) {
      console.error('Error handling location state change:', error);
      // Fallback to default tab
      setActiveTab(0);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state?.activeTab === 'My_Purchases') {
      // Set the appropriate tab index for purchases
      // If user is a seller, purchases is tab 2, otherwise it's tab 1
      setActiveTab(sellerInfo.isSeller ? 2 : 1);
    }
  }, [location.state, sellerInfo.isSeller]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const totalUnreadNotifications =
    unreadMessages + unreadBuyerNotifications + (sellerInfo.isSeller ? unreadSellerNotifications : 0);

  return (
    <Box className={styles.pageWrapper}>
      <Box className={styles.headerSection}>
        <Container maxWidth="lg">
          {/* Top Navigation Bar */}
          <Box className={styles.topNav}>
            <Link to="/" className={styles.platformName}>
              {t('home.title')}
            </Link>
            <Box className={styles.headerButtons}>
              <Button
                variant="outlined"
                startIcon={<HomeIcon />}
                onClick={() => navigate('/')}
                className={styles.headerButton}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
              >
                {t('dashboard.buttons.home')}
              </Button>
              <Button
                variant="outlined"
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
                className={styles.headerButton}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
              >
                {t('auth.logout')}
              </Button>
            </Box>
          </Box>
          <Box className={styles.telegramAdSection}>
            <div className={styles.telegramAdContent}>
              <div className={styles.telegramAdTitle}>{t('telegram.title', 'Manage Your Store with Telegram')}</div>
            </div>
            <Button
              variant="contained"
              className={styles.telegramButton}
              startIcon={<TelegramIcon />}
              onClick={() => window.open(`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}`, '_blank')}
            >
              {t('telegram.connect')}
            </Button>
          </Box>
        </Container>
      </Box>

      {/* fixed hamburger menu button */}
      <div className={styles.MenuHamburgerWrapper}>
        <IconButton
          className={`${styles.fixedMenuButton} ${
            totalUnreadNotifications > 0 ? styles.fixedMenuButtonWithNotification : ''
          }`}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <Badge badgeContent={totalUnreadNotifications} color="error">
            <MenuIcon />
          </Badge>
        </IconButton>
      </div>
      <Box className={styles.mainContent}>
        <Paper className={styles.tabsContainer}>
          <div className={styles.tabsWrapper}>
            <Tabs
              orientation="vertical"
              value={activeTab}
              onChange={handleTabChange}
              className={styles.verticalTabs}
              sx={{
                borderRight: 1,
                borderColor: 'divider',
              }}
            >
              <Tooltip title={t('dashboard.tabs.myAccount')} placement="right">
                <Tab
                  className={styles.tab}
                  label={t('dashboard.tabs.myAccount')}
                  icon={
                    <Badge color="error" max={99}>
                      <UserIcon className={styles.tabIcon} />
                    </Badge>
                  }
                  iconPosition="start"
                />
              </Tooltip>
              {sellerInfo.isSeller && (
                <Tooltip
                  title={`${t('dashboard.tabs.myStore')} ${
                    unreadSellerNotifications
                      ? `(${t('dashboard.notifications.notifications', {
                          count: unreadSellerNotifications,
                        })})`
                      : ''
                  }`}
                >
                  <Tab
                    className={styles.tab}
                    label={t('dashboard.tabs.myStore')}
                    icon={
                      <Badge badgeContent={unreadSellerNotifications} color="error" max={99}>
                        <ProductIcon className={styles.tabIcon} />
                      </Badge>
                    }
                    iconPosition="start"
                  />
                </Tooltip>
              )}
              <Tooltip
                title={`${t('dashboard.tabs.myPurchases')} ${
                  unreadBuyerNotifications
                    ? `(${t('dashboard.notifications.notifications', {
                        count: unreadBuyerNotifications,
                      })})`
                    : ''
                }`}
              >
                <Tab
                  className={styles.tab}
                  label={t('dashboard.tabs.myPurchases')}
                  icon={
                    <Badge badgeContent={unreadBuyerNotifications} color="error" max={99}>
                      <ShoppingBagIcon className={styles.tabIcon} />
                    </Badge>
                  }
                  iconPosition="start"
                />
              </Tooltip>
              <Tooltip
                title={`${t('dashboard.tabs.messages')} ${
                  unreadMessages
                    ? `(${t('dashboard.notifications.unreadMessages', {
                        count: unreadMessages,
                      })})`
                    : ''
                }`}
              >
                <Tab
                  className={styles.tab}
                  label={t('dashboard.tabs.messages')}
                  icon={
                    <Badge badgeContent={unreadMessages} color="error" max={99}>
                      <MessageIcon className={styles.tabIcon} />
                    </Badge>
                  }
                  iconPosition="start"
                />
              </Tooltip>
            </Tabs>
          </div>
          <Box className={styles.tabContent}>
            {activeTab === 0 && <UserProfilePage userId={sellerInfo.id?.toString()} />}
            {sellerInfo.isSeller && activeTab === 1 && <Store onNotificationsRead={fetchUnreadSellerNotifications} />}
            {(sellerInfo.isSeller ? activeTab === 2 : activeTab === 1) && (
              <PurchaseOrders onNotificationsRead={fetchUnreadBuyerNotifications} />
            )}
            {(sellerInfo.isSeller ? activeTab === 3 : activeTab === 2) && (
              <MessagePage onMessagesRead={fetchUnreadMessagesCount} />
            )}
          </Box>
        </Paper>
      </Box>

      <Drawer
        anchor={document.dir === 'rtl' ? 'right' : 'left'}
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <Box className={styles.mobileMenu}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={(e, value) => {
              handleTabChange(e, value);
              setMobileMenuOpen(false);
            }}
            className={styles.verticalTabs}
          >
            <Tab
              className={styles.tab}
              label={t('dashboard.tabs.myAccount')}
              icon={
                <Badge color="error" max={99}>
                  <UserIcon className={styles.tabIcon} />
                </Badge>
              }
              iconPosition="start"
            />
            {sellerInfo.isSeller && (
              <Tab
                className={styles.tab}
                label={t('dashboard.tabs.myStore')}
                icon={
                  <Badge badgeContent={unreadSellerNotifications} color="error" max={99}>
                    <ProductIcon className={styles.tabIcon} />
                  </Badge>
                }
                iconPosition="start"
              />
            )}
            <Tab
              className={styles.tab}
              label={t('dashboard.tabs.myPurchases')}
              icon={
                <Badge badgeContent={unreadBuyerNotifications} color="error" max={99}>
                  <ShoppingBagIcon className={styles.tabIcon} />
                </Badge>
              }
              iconPosition="start"
            />
            <Tab
              className={styles.tab}
              label={t('dashboard.tabs.messages')}
              icon={
                <Badge badgeContent={unreadMessages} color="error" max={99}>
                  <MessageIcon className={styles.tabIcon} />
                </Badge>
              }
              iconPosition="start"
            />
          </Tabs>
        </Box>
      </Drawer>
    </Box>
  );
};

export default UserDashboard;
