import React, { useState, useEffect, useCallback } from 'react';
import { Badge, IconButton, Menu, MenuItem, Typography, Box, CircularProgress } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EndPoints from '../EndPointConfig';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';

interface Notification {
  id: number;
  type: string;
  orderId: number;
  message: string;
  isSellerRead?: boolean;
  isBuyerRead?: boolean;
  createdAt: string;
}

interface UnifiedNotificationBellProps {
  isSeller: boolean;
  onNotificationsRead: () => void;
  unreadMessages: number;
}

const UnifiedNotificationBell: React.FC<UnifiedNotificationBellProps> = ({
  isSeller,
  onNotificationsRead,
  unreadMessages,
}) => {
  const [buyerNotifications, setBuyerNotifications] = useState<Notification[]>([]);
  const [sellerNotifications, setSellerNotifications] = useState<Notification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, generateHeaders } = useAuth();
  // Merge notifications and sort by date
  const allNotifications = [...buyerNotifications, ...sellerNotifications].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const fetchNotifications = useCallback(async () => {
    try {
      setLoading(true);
      const headers = user ? generateHeaders(user) : {};

      const buyerResponse = await axios.get(EndPoints.BUYER_NOTIFICATIONS, { headers });
      setBuyerNotifications(buyerResponse.data);

      if (isSeller) {
        const sellerResponse = await axios.get(EndPoints.SELLER_NOTIFICATIONS, { headers });
        setSellerNotifications(sellerResponse.data);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  }, [user, generateHeaders, isSeller]);

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 30000);
    return () => clearInterval(interval);
  }, [fetchNotifications]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: Notification, isBuyerNotification: boolean) => {
    try {
      const headers = user ? generateHeaders(user) : {};
      const endpoint = isBuyerNotification
        ? `${EndPoints.BUYER_NOTIFICATIONS}/${notification.id}/read`
        : `${EndPoints.SELLER_NOTIFICATIONS}/${notification.id}/read`;

      await axios.put(endpoint, {}, { headers });

      if (isBuyerNotification) {
        setBuyerNotifications(notifications =>
          notifications.map(n => (n.id === notification.id ? { ...n, isBuyerRead: true } : n))
        );
      } else {
        setSellerNotifications(notifications =>
          notifications.map(n => (n.id === notification.id ? { ...n, isSellerRead: true } : n))
        );
      }
      onNotificationsRead();
      handleClose();

      // Navigate to the dashboard with the appropriate tab selected
      navigate('/User-Dashboard', {
        state: {
          activeTab: isBuyerNotification && !isSeller ? 1 : isBuyerNotification && isSeller ? 2 : 1,
        },
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  // Modified renderNotifications to handle both types
  const renderNotifications = () => {
    if (allNotifications.length === 0) {
      return (
        <MenuItem disabled>
          <Typography variant="body2">{t('notifications.noNotifications')}</Typography>
        </MenuItem>
      );
    }

    return allNotifications.map(notification => {
      const isBuyerNotification = buyerNotifications.some(n => n.id === notification.id);
      return (
        <MenuItem
          key={notification.id}
          onClick={() => handleNotificationClick(notification, isBuyerNotification)}
          sx={{
            whiteSpace: 'normal',
            backgroundColor: isBuyerNotification
              ? notification.isBuyerRead
                ? 'inherit'
                : 'action.hover'
              : notification.isSellerRead
              ? 'inherit'
              : 'action.hover',
            '&:hover': {
              backgroundColor: 'action.selected',
            },
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: isBuyerNotification
                  ? notification.isBuyerRead
                    ? 400
                    : 600
                  : notification.isSellerRead
                  ? 400
                  : 600,
              }}
            >
              {t(`notifications.orders.${notification.type}`, { orderId: notification.orderId })}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {formatDate(notification.createdAt)}
            </Typography>
          </Box>
        </MenuItem>
      );
    });
  };

  // Calculate total unread count including messages
  const unreadCount =
    allNotifications.filter(notification => {
      const isBuyerNotification = buyerNotifications.some(n => n.id === notification.id);
      return isBuyerNotification ? !notification.isBuyerRead : !notification.isSellerRead;
    }).length + unreadMessages;

  const handleMessageClick = () => {
    handleClose();
    navigate('/User-Dashboard', {
      state: {
        activeTab: isSeller ? 3 : 2, // Messages tab index
      },
    });
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        sx={{
          color: 'inherit',
          position: 'relative',
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon />
          </Badge>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxHeight: 400,
            width: 320,
          },
        }}
      >
        {unreadMessages > 0 && (
          <MenuItem onClick={handleMessageClick} sx={{ backgroundColor: 'action.hover' }}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                {t('messages.title')}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {unreadMessages > 1
                  ? t('messages.unread_plural', { count: unreadMessages })
                  : t('messages.unread', { count: unreadMessages })}
              </Typography>
            </Box>
          </MenuItem>
        )}
        <Box sx={{ maxHeight: 300, overflow: 'auto' }}>{renderNotifications()}</Box>
      </Menu>
    </Box>
  );
};

export default UnifiedNotificationBell;
