export const arContent = {
  title: 'من نحن',
  introduction:
    'مرحبًا بكم في تيتروم، منصتكم الموثوقة للتجارة بالجملة في المغرب. سواء كنتم بائعين ترغبون في عرض منتجاتكم أو مشترين يبحثون عن أفضل الصفقات، فإن تيتروم يوفر سوقًا إلكترونيًا مريحًا وآمنًا وشفافًا مصممًا لتلبية احتياجاتكم.',
  sections: [
    {
      title: 'رؤيتنا',
      content:
        'في تيتروم، نؤمن بقدرة الأعمال المحلية وإمكانية ربط البائعين والمشترين بالجملة بطريقة فعالة وشفافة. نحن هنا لاستكشاف ما يناسبكم، جمع ملاحظاتكم، والعمل باستمرار لتحسين منصتنا لضمان أن تصبح ركيزة أساسية في مجال التجارة بالجملة في المغرب.',
    },
    {
      title: 'ما نقدمه حاليًا',
      subsections: [
        {
          subtitle: 'سوق للبيع بالجملة',
          content:
            'تتيح تيتروم للبائعين عرض منتجاتهم بشروط واضحة، بما في ذلك الحد الأدنى للطلبات، لتلبية احتياجات المشترين بالجملة.',
        },
        {
          subtitle: 'سهولة الاستخدام',
          content:
            'يمكن للمشترين تصفح المنتجات دون الحاجة إلى إنشاء حساب، بينما يمكن للبائعين والمشترين إجراء المعاملات بسهولة بمجرد التسجيل.',
        },
        {
          subtitle: 'الدفع عند الاستلام',
          content: 'يضمن أسلوب الدفع هذا عمليات آمنة وموثوقة، حيث يمكن للمشترين فحص البضائع قبل الدفع.',
        },
        {
          subtitle: 'المبادئ الأخلاقية',
          content: 'نلتزم بالمبادئ الإسلامية، مما يمنع بيع أي مواد غير أخلاقية أو محظورة على منصتنا.',
        },
      ],
    },
    {
      title: 'لماذا تختار تيتروم؟',
      subsections: [
        {
          subtitle: 'مجاني',
          content:
            'منصتنا حاليًا مجانية لجميع المستخدمين، مما يجعلها متاحة للشركات من جميع الأحجام. سيتم الإعلان عن أي تحديثات أو خدمات متميزة مستقبلية بشفافية.',
        },
        {
          subtitle: 'ميزات تركز على المستخدم',
          content: 'بدءًا من المعاملات الآمنة إلى قوائم المنتجات الدقيقة، نضع تجربة المستخدم ونجاحه في أولويتنا.',
        },
      ],
    },

    {
      title: 'انضموا إلينا اليوم',
      content:
        'سواء كنتم بائعين محترفين أو مشترين لأول مرة، تيتروم هو شريككم في التجارة بالجملة. سجلوا اليوم لبدء البيع أو الشراء بثقة، واكتشفوا فوائد سوق مصمم خصيصًا لكم',
    },
  ],
  footer: {
    contactInfo:
      'لأي استفسارات أو دعم، لا تترددوا في التواصل معنا عبر البريد الإلكتروني أو الواتساب. نحن هنا لمساعدتكم!',
  },
};
