const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const EndPoints = {
  // Seller endpoints
  GET_SELLER: `${API_BASE_URL}/api/seller`,
  UPSERT_SELLER: `${API_BASE_URL}/api/upsert-seller`,

  // Check store name availability
  CHECK_STORE_NAME_AVAILABILITY: `${API_BASE_URL}/api/check-store-name`,

  // Product endpoints
  GET_PRODUCTS: `${API_BASE_URL}/api/products`,
  ADD_PRODUCT: `${API_BASE_URL}/api/add-product`,
  UPDATE_PRODUCT: `${API_BASE_URL}/api/update-product`,
  DELETE_PRODUCT: `${API_BASE_URL}/api/delete-product`,
  SELLER_PRODUCTS: `${API_BASE_URL}/api/seller-products`,

  // Order endpoints
  CREATE_ORDER: `${API_BASE_URL}/api/create-order`,
  BUYER_ORDERS: `${API_BASE_URL}/api/buyer-orders`,
  SELLER_ORDERS: `${API_BASE_URL}/api/seller-orders`,
  UPDATE_ORDER_STATUS: `${API_BASE_URL}/api/update-order-status`,

  // Notification endpoints
  SELLER_NOTIFICATIONS: `${API_BASE_URL}/api/seller-notifications`,
  BUYER_NOTIFICATIONS: `${API_BASE_URL}/api/buyer-notifications`,
  GET_UNREAD_SELLER_NOTIFICATIONS_COUNT: `${API_BASE_URL}/api/seller-notifications/count`,
  GET_UNREAD_BUYER_NOTIFICATIONS_COUNT: `${API_BASE_URL}/api/buyer-notifications/count`,

  // Message endpoints
  GET_CONVERSATIONS: `${API_BASE_URL}/api/messages/conversations`,
  GET_MESSAGES: `${API_BASE_URL}/api/messages`,
  SEND_MESSAGE: `${API_BASE_URL}/api/messages`,
  NEW_MESSAGE: `${API_BASE_URL}/api/messages/new`,
  GET_UNREAD_MESSAGES_COUNT: `${API_BASE_URL}/api/messages/unread/count`,

  // Category endpoints
  CATEGORIES: `${API_BASE_URL}/api/categories`,

  // Rating endpoints
  RATING: `${API_BASE_URL}/api/productRatings`,

  // Telegram auth endpoints
  VERIFY_TELEGRAM_AUTH: `${API_BASE_URL}/api/auth/validate`,
  LOGIN_TELEGRAM_USER: `${API_BASE_URL}/api/auth/login`,
};

export default EndPoints;
