export const frContent = {
  title: 'Qui sommes-nous ?',
  introduction:
    "Bienvenue sur Titrom, votre plateforme de confiance pour l'achat et la vente en gros en Morocoo. Que vous soyez un vendeur cherchant à présenter vos produits ou un acheteur à la recherche des meilleurs prix, Titrom propose un espace intuitif, sécurisé et transparent conçu avec vos besoins en tête.",
  sections: [
    {
      title: 'Notre vision',
      content:
        "Titrom a été fondé avec la mission de connecter les vendeurs en gros et les acheteurs dans un espace pratique, intuitif. Notre plateforme est construite sur les valeurs de la confiance, de la transparence et du respect des principes éthiques, garantissant que chaque transaction respecte les plus hautes normes. Nous sommes fiers d'être un marché communautaire qui encourage les entreprises locales à grandir et à réussir.",
    },
    {
      title: 'Ce que nous offrons actuellement',
      subsections: [
        {
          subtitle: 'Marché en gros',
          content:
            "Titrom permet aux vendeurs de lister leurs produits avec des termes clairs, y compris les quantités minimales d'ordre, pour répondre aux besoins spécifiques des acheteurs en gros.",
        },
        {
          subtitle: "Facilité d'utilisation",
          content:
            "Les acheteurs peuvent parcourir les produits sans avoir besoin d'un compte, tandis que les vendeurs et les acheteurs peuvent entamer des transactions sans effort une fois enregistrés.",
        },
        {
          subtitle: 'Cash on Delivery',
          content:
            'Notre méthode de paiement garantit un processus de transaction sécurisé et fiable, où les acheteurs inspectent les biens avant de faire des paiements.',
        },
        {
          subtitle: 'Normes éthiques',
          content:
            'Nous nous conformons aux principes islamiques, interdisant la vente de tout matériel immoral ou interdit sur notre plateforme.',
        },
      ],
    },
    {
      title: 'Pourquoi choisir Titrom?',
      subsections: [
        {
          subtitle: 'Utilisation gratuite',
          content:
            'Notre plateforme est actuellement gratuite pour tous les utilisateurs, rendant accessible les entreprises de toutes les tailles. Les futures mises à jour et services premium seront communiqués de manière transparente.',
        },
        {
          subtitle: "Fonctionnalités centrées sur l'utilisateur",
          content:
            'De la sécurité des transactions aux listes de produits précises, nous privilégions votre expérience et votre succès.',
        },
      ],
    },

    {
      title: "Rejoignez-nous aujourd'hui",
      content:
        "Que vous soyez un vendeur expérimenté ou un acheteur pour la première fois, Titrom est votre partenaire dans le commerce en gros. Inscrivez-vous aujourd'hui pour commencer à acheter ou à vendre avec confiance et découvrir les avantages d'une plateforme conçue pour vous.",
    },
  ],
  footer: {
    contactInfo:
      "Pour toute question ou support, n'hésitez pas à nous contacter par e-mail ou WhatsApp. Nous sommes là pour vous aider !",
  },
};
