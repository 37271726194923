export const enContent = {
  title: 'Terms and Conditions',
  lastUpdated: 'Last Updated: November 2024',
  introduction: `Welcome to Titrom! By accessing our website and using our services, you agree to be bound by these Terms of Service ("Terms"), all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.`,
  sections: [
    {
      title: 'User Accounts',
      subsections: [
        {
          subtitle: '1.Registration',
          content:
            'Users must register an account to sell products. Registration requires providing valid information as prompted by the account registration form. Users must be 18 years of age or older to create an account and use the services of Titrom.',
        },
        {
          subtitle: '2.Account Roles',
          content:
            'Users may register as a seller. Sellers can list their products for wholesale, specifying minimum order quantities.',
        },
        {
          subtitle: '3.Access',
          content:
            'Users can browse and purchase products without registration. However, selling on Titrom requires an account.',
        },
        {
          subtitle: '4.Account Suspension/Termination',
          content:
            'Titrom reserves the right to suspend or terminate your account at any time without notice if there is any suspicion of fraudulent activities or breach of these terms.',
        },
      ],
    },
    {
      title: 'Transactions',
      subsections: [
        {
          subtitle: '1.Selling and Buying',
          content:
            'Sellers list their items specifying the terms of sale, including minimum orders. Buyers can purchase products either by logging into their account or as guests, providing the necessary delivery information as required by the order form.',
        },
        {
          subtitle: '2.Payment and Delivery',
          content:
            'Transactions are completed using cash on delivery, which is managed by the sellers. Sellers and buyers are responsible for handling the delivery and payment processes, respectively.',
        },
        {
          subtitle: '3.Responsibility',
          content:
            'Buyers are responsible for inspecting goods upon delivery and have the right to refuse payment and acceptance of goods if they do not meet the agreed standards.',
        },
      ],
    },
    {
      title: 'Use of Service',
      subsections: [
        {
          subtitle: '1.Fees',
          content:
            'Titrom is free to use for both sellers and buyers. Future updates about subscription fees and additional services will be communicated through the platform.',
        },
        {
          subtitle: '2.Content',
          content:
            'Users are responsible for the accuracy of the content they provide when listing products. By using our platform, sellers grant Titrom the right to use their content for advertising purposes.',
        },
        {
          subtitle: '3.Prohibited Activities',
          content:
            "It is prohibited to sell any materials that are immoral or contrary to Islamic principles. Additionally, the use of Titrom's logos, designs, and other intellectual property without permission is strictly forbidden.",
        },
      ],
    },
    {
      title: 'Legal Compliance',
      content:
        'Users agree to comply with all local laws applicable to online commerce in Morocco, including those related to wholesale transactions.',
    },
    {
      title: 'Intellectual Property',
      subsections: [
        {
          subtitle: 'User Content',
          content:
            'Content provided on Titrom by users remains the intellectual property of the respective owners. Users grant Titrom a non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and display such content for the purpose of operating and promoting the site.',
        },
        {
          subtitle: "Titrom's Intellectual Property",
          content:
            'All rights in the design, branding, and logos of Titrom are the property of Titrom and protected under intellectual property laws. Unauthorized use is strictly prohibited.',
        },
      ],
    },
    {
      title: 'Dispute Resolution',
      content:
        'Disputes should be resolved directly between the buyer and the seller. Titrom does not mediate disputes but recommends that users engage in fair and good faith negotiations to resolve conflicts.',
    },
    {
      title: 'Changes to the Terms',
      content:
        'Titrom reserves the right to modify these terms at any time. Users will be notified of any changes via the platform. Continued use of the platform after any such changes shall constitute your consent to such changes.',
    },
    {
      title: 'Privacy Policy',
      content:
        'For details about how your information is collected, used, and protected, please refer to our Privacy Policy page.',
    },
  ],
  footer: {
    contactInfo: 'For support or questions about these Terms, please contact us via email or WhatsApp chat.',
  },
};
