export const frContent = {
  title: 'Questions fréquemment posées (FAQ)',
  introduction:
    "Bienvenue sur la page FAQ de Titrom! Ici, vous trouverez des réponses aux questions fréquemment posées sur l'utilisation de notre plateforme. Si vous avez des questions supplémentaires, n'hésitez pas à nous contacter via email ou WhatsApp.",
  sections: [
    {
      title: 'Commencer',
      subsections: [
        {
          subtitle: 'Comment créer un compte?',
          content:
            'Pour créer un compte:\n• Accédez à la page d\'accueil.\n• Cliquez sur "S\'inscrire" si vous n\'avez pas de compte, ou "Se connecter" si vous avez déjà un compte.\n• Authentifiez-vous en utilisant Telegram.\n• Une fois enregistré, vous pouvez commencer à lister vos produits à la vente.',
        },
        {
          subtitle: 'Puis-je naviguer sur les produits sans un compte?',
          content:
            'Oui, vous pouvez naviguer et acheter des produits sans un compte. Cependant, pour vendre des produits, vous devez créer un compte.',
        },
        {
          subtitle: 'Existe-t-il un âge requis pour créer un compte?',
          content:
            'Oui, vous devez avoir au moins 18 ans pour créer un compte et utiliser les services fournis par Titrom.',
        },
      ],
    },
    {
      title: 'Gestion du compte',
      subsections: [
        {
          subtitle: 'Que dois-je faire si je ne peux pas me connecter?',
          content:
            "Si vous avez du mal à vous connecter:\n• Assurez-vous d'être authentifié en utilisant Telegram.\n• Si les problèmes persistent, veuillez contacter notre équipe de support pour obtenir de l'aide.",
        },
        {
          subtitle: 'Comment puis-je mettre à jour mes informations de compte?',
          content:
            'Pour mettre à jour vos informations de compte:\n• Naviguez vers l\'onglet "Mon compte" dans le tableau de bord.\n• Alternativement, cliquez sur votre icône de profil dans la barre de navigation en haut de la page d\'accueil.\n• De là, vous pouvez mettre à jour vos informations personnelles ou de magasin.',
        },
      ],
    },
    {
      title: 'Achat et vente',
      subsections: [
        {
          subtitle: 'Comment passer une commande?',
          content:
            'Pour placer une commande:\n• Cliquez sur le produit que vous souhaitez acheter.\n• Sélectionnez la quantité souhaitée (assurez-vous de respecter la quantité minimale de commande pour le gros lot).\n• Cliquez sur "Acheter maintenant" et complétez le formulaire de livraison.\n• Vous pouvez acheter des produits en tant que client sans avoir besoin d\'un compte.',
        },
        {
          subtitle: 'Puis-je modifier ou annuler ma commande?',
          content:
            "Oui, vous pouvez modifier ou annuler votre commande en contactant le vendeur directement via la plateforme. Assurez-vous d'agir rapidement avant que la commande ne soit expédiée.",
        },
        {
          subtitle: 'Comment lister mes produits en tant que vendeur?',
          content:
            'Pour lister vos produits en tant que vendeur:\n• Connectez-vous à votre compte.\n• Accédez au tableau de bord.\n• Sélectionnez l\'onglet "Mon compte" et créez un magasin si vous n\'en avez pas.\n• Accédez à la section "Mon magasin" \n• Ajoutez les détails du produit, y compris le nom, la description, le prix et la quantité minimale de commande.\n• Une fois approuvé, vos produits seront visibles aux acheteurs.',
        },
      ],
    },
    {
      title: 'Paiements et livraison',
      subsections: [
        {
          subtitle: 'Quelles sont les méthodes de paiement acceptées?',
          content:
            'Actuellement, Titrom prend en charge le paiement à la livraison (COD). Cela garantit des transactions sécurisées où les acheteurs inspectent les biens avant de faire des paiements.',
        },
        {
          subtitle: 'Puis-je refuser de payer si le produit ne répond pas à mes attentes?',
          content:
            "Oui, les acheteurs ont le droit d'inspecter les biens à la livraison. Si le produit ne répond pas aux conditions convenues, vous pouvez refuser de payer.",
        },
        {
          subtitle: 'Comment puis-je suivre ma commande?',
          content:
            'Si vous avez acheté en vous connectant:\n• Accédez à l\'onglet "Mes achats" dans votre tableau de bord pour suivre vos commandes.\nSi vous avez acheté en tant que client sans compte:\n• Contactez le vendeur directement via la plateforme pour obtenir des mises à jour sur votre commande.',
        },
      ],
    },
    {
      title: 'Support et commentaires',
      subsections: [
        {
          subtitle: 'Comment puis-je contacter le support?',
          content:
            'Vous pouvez nous contacter par:\n• Email: support@titrom.com\n• WhatsApp: +212 XXXXXXXXX\nNous sommes là pour vous aider avec toutes vos questions ou préoccupations.',
        },
        {
          subtitle: 'Comment puis-je fournir des commentaires?',
          content:
            'Nous apprécions vos commentaires! Vous pouvez partager vos idées ou suggestions via notre page Contactez-nous ou nous envoyer un email directement.',
        },
      ],
    },
  ],
  footer: {
    contactInfo: "Besoin d'aide supplémentaire? Contactez notre équipe de support",
    additionalInfo: "Pour des informations détaillées, veuillez consulter nos Conditions d'utilisation",
  },
};
