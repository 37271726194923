import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, Typography, Box, Avatar, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';

interface OrderSuccessModalProps {
  open: boolean;
  orderId?: string | number;
  onClose: () => void;
}

const OrderSuccessModal: React.FC<OrderSuccessModalProps> = ({ open, orderId, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  const handleViewOrders = () => {
    navigate('/user-dashboard', { state: { activeTab: 'My_Purchases' } });
  };

  const handleContinueShopping = () => {
    onClose();
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: '500px',
        },
      }}
    >
      <DialogContent sx={{ textAlign: 'center', py: 4 }}>
        <Box sx={{ mb: 3 }}>
          <Avatar
            sx={{
              bgcolor: 'success.light',
              width: 60,
              height: 60,
              margin: '0 auto',
              mb: 2,
            }}
          >
            <CheckIcon sx={{ fontSize: 40 }} />
          </Avatar>
          <Typography variant="h5" gutterBottom fontWeight={600} color="success.main">
            {t('orderSuccess.title')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t('orderSuccess.message')}
          </Typography>
          {orderId && (
            <Typography variant="body2" sx={{ mt: 2, color: 'text.primary' }}>
              {t('orderSuccess.orderId', { id: orderId })}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          {isAuthenticated && ( // Only show the view orders button if the user is authenticated
            <Button variant="outlined" onClick={handleViewOrders} sx={{ px: 3 }}>
              {t('orderSuccess.buttons.viewOrders')}
            </Button>
          )}
          <Button variant="contained" onClick={handleContinueShopping} sx={{ px: 3 }}>
            {t('orderSuccess.buttons.continueShopping')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderSuccessModal;
