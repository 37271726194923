import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import styles from './LanguageSwitcher.module.css';
import { useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    document.dir = lng === 'ar' ? 'rtl' : 'ltr';

    // Get current path without language prefix
    const currentPath = window.location.pathname.replace(/^\/(ar|fr)/, '') || '/';

    // Navigate to new language path
    if (lng === 'en') {
      navigate(currentPath);
    } else {
      navigate(`/${lng}${currentPath}`);
    }

    handleClose();
  };

  return (
    <div>
      <Button
        startIcon={<LanguageIcon />}
        onClick={handleClick}
        className={styles.languageButton}
        sx={{
          color: 'inherit',
          minWidth: 'auto',
          padding: '6px 12px',
        }}
      >
        {i18n.language.toUpperCase()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: i18n.dir() === 'rtl' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: i18n.dir() === 'rtl' ? 'right' : 'left',
        }}
      >
        <MenuItem onClick={() => changeLanguage('ar')}>العربية</MenuItem>
        <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
        <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
