import React, { useState, useEffect, useCallback } from 'react';
import { Badge, IconButton, Menu, MenuItem, Typography, Box, CircularProgress } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import EndPoints from '../EndPointConfig';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';

interface Notification {
  id: number;
  type: string;
  message: string;
  orderId: number;
  isSellerRead: boolean;
  createdAt: string;
}

interface NotificationBellProps {
  onNotificationsRead: () => void;
}

const NotificationBell: React.FC<NotificationBellProps> = ({ onNotificationsRead }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const { user, generateHeaders } = useAuth();
  const { t } = useTranslation();

  const unreadCount = notifications.filter(n => !n.isSellerRead).length;

  const fetchNotifications = useCallback(async () => {
    try {
      setLoading(true);
      if (user) {
        const headers = generateHeaders(user);
        const response = await axios.get(EndPoints.SELLER_NOTIFICATIONS, { headers });
        setNotifications(response.data);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  }, [user, generateHeaders]);

  useEffect(() => {
    fetchNotifications();
    // Set up polling every 30 seconds
    const interval = setInterval(fetchNotifications, 30000);
    return () => clearInterval(interval);
  }, [fetchNotifications]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: Notification) => {
    if (!notification.isSellerRead && user) {
      try {
        const headers = generateHeaders(user);
        await axios.put(`${EndPoints.SELLER_NOTIFICATIONS}/${notification.id}/read`, {}, { headers });
        // Update local state
        setNotifications(notifications.map(n => (n.id === notification.id ? { ...n, isSellerRead: true } : n)));
        // Call the callback to refresh parent's notification count
        onNotificationsRead();
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    }
    handleClose();
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        sx={{
          color: 'inherit',
          position: 'relative',
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon />
          </Badge>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxHeight: 400,
            width: 320,
          },
        }}
      >
        {notifications.length === 0 ? (
          <MenuItem disabled>
            <Typography variant="body2">{t('notifications.noNotifications')}</Typography>
          </MenuItem>
        ) : (
          notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              sx={{
                whiteSpace: 'normal',
                backgroundColor: notification.isSellerRead ? 'inherit' : 'action.hover',
                '&:hover': {
                  backgroundColor: 'action.selected',
                },
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="body2" sx={{ fontWeight: notification.isSellerRead ? 400 : 600 }}>
                  {t(`notifications.orders.${notification.type}`, { orderId: notification.orderId })}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {formatDate(notification.createdAt)}
                </Typography>
              </Box>
            </MenuItem>
          ))
        )}
      </Menu>
    </Box>
  );
};

export default NotificationBell;
