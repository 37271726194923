import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tab,
  Tabs,
  Chip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { uploadToS3, deleteFromS3 } from '../utils/awsUtils';
import styles from './Store.module.css';
import ProductCard from '../Components/ProductCard';
import EndPoints from '../EndPointConfig';
import SalesOrders from './SalesOrders';
import SellerNotificationBell from '../Components/SellerNotificationBell';
import MenuItemCompo from '../Components/MenuItemCompo';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import i18n from '../config/i18n';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Fab from '@mui/material/Fab';

// Product interface defines the shape of product data received from the API
interface Product {
  id: number;
  name: string;
  categoryId: number;
  description: string;
  price: number;
  stock: number;
  // ProductImages: Used in frontend state to store images from API response
  // The API returns images with this capitalized name
  ProductImages: Array<{
    displayOrder: number;
    imageUrl: string;
    isMain: boolean;
  }>;
  isActive: boolean;
  minOrder: number;
  attributes: Record<string, string[]>;
  createdAt: string;
  category?: {
    id: number;
    name: string;
    path: string;
    slug: string;
    parent?: {
      id: number;
      name: string;
      path: string;
    };
  };
}

interface FeedbackState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
}

interface StoreProps {
  onNotificationsRead: () => void;
}

interface Order {
  status: 'pending' | 'processing' | 'enroute' | 'delivered' | 'cancelled';
}

// field limits for validation inputs
const FIELD_LIMITS = {
  NAME_MAX: 100,
  DESCRIPTION_MAX: 2000,
  PRICE_MAX: 99999999.99,
  STOCK_MAX: 99999999,
  MIN_ORDER_MAX: 9999999,
};

// Main product management component for sellers
function Store({ onNotificationsRead }: StoreProps) {
  const { t } = useTranslation();
  // Replace Auth0 hooks with Telegram auth context
  const { isAuthenticated, user, generateHeaders } = useAuth();

  // State management for product data and UI controls
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [feedback, setFeedback] = useState<FeedbackState>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imageError, setImageError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [selectedUpsertCategory, setSelectedUpsertCategory] = useState('');
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);

  // Form data state for product creation/editing
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '0',
    categoryId: 0,
    stock: '0',
    minOrder: '1',
    attributes: {} as Record<string, string[]>,
    ProductImages: [] as Array<{
      displayOrder: number;
      imageUrl: string;
      isMain: boolean;
    }>,
  });

  // Add new state for active/inactive products
  const [activeProducts, setActiveProducts] = useState<Product[]>([]);
  const [inactiveProducts, setInactiveProducts] = useState<Product[]>([]);
  const [isUpsertCategoryMenuOpen, setIsUpsertCategoryMenuOpen] = useState(false);
  // Update the categories state type to match the database structure
  const [categories, setCategories] = useState<
    Array<{
      id: number;
      name: string;
      path: string;
      slug: string;
      description: string;
      level: number;
      children?: Array<{
        id: number;
        name: string;
        path: string;
        slug: string;
        description: string;
        level: number;
      }>;
    }>
  >([]);

  // Add state for managing the filter category menu
  const [isFilterCategoryMenuOpen, setIsFilterCategoryMenuOpen] = useState(false);
  const [selectedFilterCategoryName, setSelectedFilterCategoryName] = useState<string | null>(null);

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Add pagination constants
  const ITEMS_PER_PAGE = 15; // Number of products per page

  // Add formatPrice function
  const formatPrice = (price: number | string): string => {
    // Convert price to number if it's a string
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

    // Check if it's a valid number
    if (isNaN(numericPrice)) {
      return '0'; // or handle invalid price differently
    }

    const currencyFormat = t('currency.format');
    const formattedPrice = numericPrice
      .toFixed(Number(t('currency.precision')))
      .replace('.', t('currency.decimal'))
      .replace(/\B(?=(\d{3})+(?!\d))/g, t('currency.thousand'));

    return currencyFormat.replace('{amount}', formattedPrice);
  };

  // Fetch products from API with authentication
  const fetchProducts = useCallback(async () => {
    try {
      if (!user) return;

      const headers = generateHeaders(user);
      const response = await axios.get(EndPoints.SELLER_PRODUCTS, {
        headers: headers,
      });

      // Transform the products to parse stringified attributes
      const transformedProducts = response.data.map((product: Product) => ({
        ...product,
        attributes: typeof product.attributes === 'string' ? JSON.parse(product.attributes) : product.attributes,
      }));

      // Sort products by ID in descending order (newest first)
      const sortedProducts = transformedProducts.sort((a: Product, b: Product) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

      // Separate active and inactive products
      const active = sortedProducts.filter((p: Product) => p.isActive);
      const inactive = sortedProducts.filter((p: Product) => !p.isActive);
      setActiveProducts(active);
      setInactiveProducts(inactive);
      setLoading(false);
    } catch (error) {
      setFeedback({
        open: true,
        message: t('store.feedback.fetchError'),
        severity: 'error',
      });
      setLoading(false);
    }
  }, [user, generateHeaders, t]);

  // Fetch categories from API with authentication
  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get(`${EndPoints.CATEGORIES}?lang=${i18n.language}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setFeedback({
        open: true,
        message: t('store.feedback.categoryError'),
        severity: 'error',
      });
    }
  }, [t]);

  // Fetch products on component mount
  useEffect(() => {
    if (isAuthenticated) {
      fetchProducts();
      fetchCategories();
    }
  }, [isAuthenticated, fetchProducts, fetchCategories]);

  // Reset pagination when tab changes or search/filter updates
  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab, searchTerm, selectedCategory]);

  // Handle input changes for form fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Handle numeric inputs
    if (name === 'price' || name === 'stock' || name === 'minOrder') {
      // Allow empty string for all numeric fields
      if (value === '') {
        setFormData(prev => ({
          ...prev,
          [name]: '',
        }));
        return;
      }

      const numValue = name === 'price' ? parseFloat(value) : parseInt(value);
      if (isNaN(numValue)) return;

      // Validate minimum and maximum values
      if (numValue < 0) return;

      // Check maximum limits
      if (name === 'price' && numValue > FIELD_LIMITS.PRICE_MAX) return;
      if (name === 'stock' && numValue > FIELD_LIMITS.STOCK_MAX) return;
      if (name === 'minOrder') {
        if (numValue < 1) {
          setFeedback({
            open: true,
            message: t('store.feedback.minOrderError'),
            severity: 'error',
          });
          return;
        }
        if (numValue > FIELD_LIMITS.MIN_ORDER_MAX) return;
      }

      setFormData(prev => ({
        ...prev,
        [name]: numValue,
      }));
      return;
    }

    // Handle text field limits
    if (name === 'name' && value.length > FIELD_LIMITS.NAME_MAX) return;
    if (name === 'description' && value.length > FIELD_LIMITS.DESCRIPTION_MAX) return;

    // Handle all other inputs
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  //function for image handling
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    // Check file size
    const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      setImageError(t('store.form.fields.images.sizeError'));
      return;
    }

    if (files.length + imageFiles.length > 5) {
      setImageError(t('store.form.fields.images.maxError'));
      return;
    }

    const validFiles = files.filter(file => file.type.startsWith('image/'));
    if (validFiles.length !== files.length) {
      setImageError(t('store.form.fields.images.typeError'));
      return;
    }

    setImageFiles(prev => [...prev, ...validFiles]);
    setImageError('');
  };

  // Reset form to initial state
  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      price: '0',
      categoryId: 0,
      stock: '0',
      minOrder: '1',
      attributes: {} as Record<string, string[]>,
      ProductImages: [],
    });
    setSelectedUpsertCategory('');
    setIsCategoryMenuOpen(false);
    setImageFiles([]);
    setImageError('');
    setIsEditing(false);
    setSelectedProduct(null);
    setShowAddForm(false);
  };

  // Handle form submission for adding/editing products
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // Validate category selection
    if (!formData.categoryId) {
      setFeedback({
        open: true,
        message: t('store.feedback.categoryRequired'),
        severity: 'error',
      });
      setLoading(false);
      return;
    }

    // Validate price is not negative and not less than 0.01
    if (Number(formData.price) < 0.01) {
      setFeedback({
        open: true,
        message: t('store.feedback.priceError', {
          min: formatPrice(0.01),
        }),
        severity: 'error',
      });
      setLoading(false);
      return;
    }

    // Validate minOrder
    if (formData.minOrder === '' || Number(formData.minOrder) < 1) {
      setFeedback({
        open: true,
        message: t('store.feedback.minOrderError'),
        severity: 'error',
      });
      setLoading(false);
      return;
    }

    // Validate stock vs minOrder
    if (Number(formData.stock) < Number(formData.minOrder)) {
      setFeedback({
        open: true,
        message: t('store.feedback.stockError'),
        severity: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      // Add timeout handling for API calls
      const timeout = 30000; // 30 seconds
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      if (!user) throw new Error('Not authenticated');

      const headers = generateHeaders(user);

      // Upload images to S3 if there are new ones
      let uploadedImageUrls: string[] = [];
      if (imageFiles.length > 0) {
        // Upload each image to S3
        const uploadPromises = imageFiles.map(file => uploadToS3(file));
        uploadedImageUrls = await Promise.all(uploadPromises);
      }

      // Convert string values to numbers and prepare product data
      const productData = {
        ...formData,
        price: Number(formData.price),
        stock: Number(formData.stock),
        minOrder: Number(formData.minOrder),
        images: isEditing
          ? [
              ...formData.ProductImages,
              ...uploadedImageUrls.map((url, index) => ({
                imageUrl: url,
                isMain: formData.ProductImages.length === 0 && index === 0,
                displayOrder: formData.ProductImages.length + index,
              })),
            ]
          : uploadedImageUrls.map((url, index) => ({
              imageUrl: url,
              isMain: index === 0,
              displayOrder: index,
            })),
      };

      // Validate that there is at least one image
      if (productData.images.length === 0) {
        setImageError(t('store.form.fields.images.required'));
        setLoading(false);
        return;
      }

      // Send product data to API
      if (isEditing && selectedProduct) {
        await axios.put(`${EndPoints.UPDATE_PRODUCT}/${selectedProduct.id}`, productData, {
          headers: headers,
        });
        setFeedback({
          open: true,
          message: t('store.feedback.saveSuccess'),
          severity: 'success',
        });
      } else {
        await axios.post(EndPoints.ADD_PRODUCT, productData, {
          headers: headers,
          signal: controller.signal,
        });
        setFeedback({
          open: true,
          message: t('store.feedback.saveSuccess'),
          severity: 'success',
        });
      }
      resetForm();
      fetchProducts();

      clearTimeout(timeoutId);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'AbortError') {
          setFeedback({
            open: true,
            message: t('store.feedback.requestTimeout'),
            severity: 'error',
          });
        } else if (axios.isAxiosError(error)) {
          // Handle specific API error responses
          const errorMessage = error.response?.data?.message || t('store.feedback.saveError');
          setFeedback({
            open: true,
            message: errorMessage,
            severity: 'error',
          });
        } else {
          setFeedback({
            open: true,
            message: t('store.feedback.saveError'),
            severity: 'error',
          });
        }
      }
      console.error('Error:', error);
    }
    setLoading(false);
  };

  // Handle editing of existing product
  const handleEdit = (product: Product) => {
    setSelectedProduct(product);
    setFormData({
      name: product.name,
      description: product.description,
      price: product.price.toString(),
      categoryId: product.categoryId,
      stock: product.stock.toString(),
      minOrder: product.minOrder?.toString() || '',
      attributes: product.attributes || {},
      ProductImages: product.ProductImages.map(img => ({
        displayOrder: img.displayOrder,
        imageUrl: img.imageUrl,
        isMain: img.isMain,
      })),
    });
    setIsEditing(true);
    setShowAddForm(true);

    // Ensure we're in the correct tab when editing
    setActiveTab(product.isActive ? 0 : 1);

    // Add timeout to focus on the name field after the form renders
    setTimeout(() => {
      const nameInput = document.querySelector('input[name="name"]') as HTMLInputElement;
      if (nameInput) {
        nameInput.focus();
        nameInput.select();
      }
    }, 100);
  };

  // Handle product deletion
  const handleDelete = (product: Product) => {
    setSelectedProduct(product);
    setDeleteDialogOpen(true);
  };

  // Add a new function to handle the actual deletion
  const handleConfirmDelete = async () => {
    if (!selectedProduct || !user) return;

    try {
      const headers = generateHeaders(user);

      // Delete product from database first
      const response = await axios.delete(`${EndPoints.DELETE_PRODUCT}/${selectedProduct.id}`, {
        headers: headers,
      });

      // Only attempt S3 deletion if database deletion was successful
      if (response.status === 200) {
        if (selectedProduct.ProductImages && selectedProduct.ProductImages.length > 0) {
          try {
            const deletePromises = selectedProduct.ProductImages.map(img => deleteFromS3(img.imageUrl));
            await Promise.all(deletePromises);
          } catch (s3Error) {
            // Log S3 deletion error but don't show to user since product was deleted successfully
            console.error('Error deleting images from S3:', s3Error);
          }
        }

        setFeedback({
          open: true,
          message: t('store.feedback.deleteSuccess'),
          severity: 'success',
        });
        fetchProducts();
      }
    } catch (error) {
      setFeedback({
        open: true,
        message: t('store.feedback.deleteError'),
        severity: 'error',
      });
      console.error('Error:', error);
    }
    setDeleteDialogOpen(false);
    setSelectedProduct(null);
  };

  const handleDeleteExistingImage = async (img: { imageUrl: string }, index: number) => {
    try {
      if (isEditing && selectedProduct && user) {
        const headers = generateHeaders(user);

        // Delete image from S3 first
        try {
          await deleteFromS3(img.imageUrl);
        } catch (error) {
          console.error('Error deleting image from S3:', error);
          setFeedback({
            open: true,
            message: t('store.feedback.deleteImageError'),
            severity: 'error',
          });
          return;
        }

        // Create new array without the deleted image
        const updatedImages = formData.ProductImages.filter((_, i) => i !== index);

        // Update local state
        setFormData(prev => ({
          ...prev,
          ProductImages: updatedImages,
        }));

        // Prepare data for API
        const productData = {
          ...formData,
          images: updatedImages.map(img => ({
            imageUrl: img.imageUrl,
            isMain: img.isMain,
            displayOrder: img.displayOrder,
          })),
        };

        // Send update to API
        await axios.put(`${EndPoints.UPDATE_PRODUCT}/${selectedProduct.id}`, productData, {
          headers: headers,
        });

        setFeedback({
          open: true,
          message: t('store.feedback.deleteImageSuccess'),
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      setFeedback({
        open: true,
        message: t('store.feedback.deleteImageError'),
        severity: 'error',
      });

      // Revert local state on error
      setFormData(prev => ({
        ...prev,
        ProductImages: formData.ProductImages,
      }));
    }
  };

  // Filter products based on search and category
  const filteredProducts = (activeTab === 0 ? activeProducts : inactiveProducts).filter(
    product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (!selectedCategory || product.categoryId === Number(selectedCategory))
  );

  // Calculate pagination values
  const totalPages = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE);
  const paginatedProducts = filteredProducts.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  // Handle page change
  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    // Scroll to top of product grid
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleReorderImages = (dragIndex: number, hoverIndex: number) => {
    try {
      // Handle existing images
      if (isEditing) {
        setFormData(prev => {
          const newImages = [...prev.ProductImages];
          const [draggedItem] = newImages.splice(dragIndex, 1);
          newImages.splice(hoverIndex, 0, draggedItem);
          // Update isMain flag - first image is always main
          return {
            ...prev,
            ProductImages: newImages.map((img, index) => ({
              ...img,
              isMain: index === 0,
              displayOrder: index,
            })),
          };
        });
      }

      // Handle new images being added
      setImageFiles(prev => {
        const newFiles = [...prev];
        const [draggedItem] = newFiles.splice(dragIndex, 1);
        newFiles.splice(hoverIndex, 0, draggedItem);
        return newFiles;
      });
    } catch (error) {
      console.error('Error reordering images:', error);
      setFeedback({
        open: true,
        message: t('store.feedback.reorderError'),
        severity: 'error',
      });
    }
  };

  // Also modify handleToggleActive to prevent activation if stock is less than minOrder
  const handleToggleActive = async (product: Product) => {
    try {
      if (!user) return;

      const headers = generateHeaders(user);

      // Don't allow activation if stock is less than minOrder
      if (!product.isActive && product.stock < (product.minOrder || 1)) {
        setFeedback({
          open: true,
          message: t('store.feedback.stockError'),
          severity: 'error',
        });
        return;
      }

      // Send all required product data with proper type conversion
      const productData = {
        name: product.name,
        description: product.description,
        price: Number(product.price),
        categoryId: Number(product.categoryId),
        stock: Number(product.stock),
        minOrder: Number(product.minOrder || 1),
        isActive: !product.isActive,
        images: product.ProductImages.map(img => ({
          imageUrl: img.imageUrl,
          isMain: img.isMain,
          displayOrder: img.displayOrder,
        })),
        attributes: product.attributes || {},
      };

      await axios.put(`${EndPoints.UPDATE_PRODUCT}/${product.id}`, productData, {
        headers: headers,
      });

      // Update local state
      const updatedProduct = { ...product, isActive: !product.isActive };
      if (product.isActive) {
        setActiveProducts(prev => prev.filter(p => p.id !== product.id));
        setInactiveProducts(prev => [...prev, updatedProduct]);
      } else {
        setInactiveProducts(prev => prev.filter(p => p.id !== product.id));
        setActiveProducts(prev => [...prev, updatedProduct]);
      }

      setFeedback({
        open: true,
        message: t(product.isActive ? 'store.feedback.deactivateSuccess' : 'store.feedback.activateSuccess'),
        severity: 'success',
      });
    } catch (error) {
      console.error('Error toggling product status:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Server response:', error.response.data);
        setFeedback({
          open: true,
          message: error.response.data.message || t('store.feedback.toggleError'),
          severity: 'error',
        });
      } else {
        setFeedback({
          open: true,
          message: t('store.feedback.toggleError'),
          severity: 'error',
        });
      }
    }
  };

  // Modify the tab change handler
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    // Cancel add/edit form if it's open
    if (showAddForm) {
      resetForm();
    }
    setActiveTab(newValue);
  };

  //function to fetch pending orders count

  const fetchPendingOrdersCount = useCallback(async () => {
    try {
      if (!user) return;

      const headers = generateHeaders(user);
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

      const response = await axios.get(`${EndPoints.SELLER_ORDERS}?status=pending`, {
        headers: headers,
        signal: controller.signal,
      });

      clearTimeout(timeoutId);
      const pendingOrdersCount = response.data.filter((order: Order) => order.status === 'pending').length;
      setPendingOrdersCount(pendingOrdersCount);
    } catch (error) {
      console.error('Error fetching pending orders count:', error);
      if (error instanceof Error) {
        // Only show error to user if it's not an abort error
        if (error.name !== 'AbortError') {
          setFeedback({
            open: true,
            message: t('store.feedback.pendingOrdersError'),
            severity: 'error',
          });
        }
      }
    }
  }, [user, generateHeaders, t]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchPendingOrdersCount();
    }
  }, [isAuthenticated, fetchPendingOrdersCount]);

  const [selectedAttributeType, setSelectedAttributeType] = useState('');
  const [attributeValues, setAttributeValues] = useState<Record<string, string>>({});

  // Modify handleAddAttribute to use the attributeValues map
  const handleAddAttribute = (attrName: string, value: string) => {
    if (!value.trim()) return;

    setFormData(prev => ({
      ...prev,
      attributes: {
        ...prev.attributes,
        [attrName]: Array.from(new Set([...(prev.attributes[attrName] || []), value.trim()])),
      },
    }));
    // Clear only the specific attribute value
    setAttributeValues(prev => ({
      ...prev,
      [attrName]: '',
    }));
  };

  const handleDeleteAttributeValue = (key: string, valueToDelete: string) => {
    setFormData(prev => ({
      ...prev,
      attributes: {
        ...prev.attributes,
        [key]: prev.attributes[key].filter(value => value !== valueToDelete),
      },
    }));
  };

  return (
    <Box className={styles.pageContainer}>
      <Box className={styles.dashboardHeader}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4" className={styles.dashboardTitle}>
            {t('store.header.title')}
          </Typography>
          <SellerNotificationBell onNotificationsRead={onNotificationsRead} />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {activeTab === 0 && (
            <Button
              variant="contained"
              color="primary"
              startIcon={showAddForm ? <CloseIcon /> : <AddIcon />}
              onClick={() => setShowAddForm(!showAddForm)}
              className={styles.addButton}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: '0 2px 4px rgba(25,118,210,0.2)',
                '&:hover': {
                  boxShadow: '0 4px 8px rgba(25,118,210,0.3)',
                },
              }}
            >
              {showAddForm ? t('store.form.buttons.cancel') : t('store.form.buttons.add')}
            </Button>
          )}
        </Box>
      </Box>

      <Box className={styles.tabsContainer}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-scrollButtons': {
              '&.Mui-disabled': { opacity: 0.3 },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'primary.main',
            },
          }}
          className={styles.tabs}
        >
          <Tab
            label={
              <Box className={styles.tabContent}>
                <VisibilityIcon />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <span>{t('store.tabs.listedProducts')}</span>
                  {activeProducts.length > 0 && (
                    <Chip
                      label={activeProducts.length}
                      size="small"
                      color="primary"
                      sx={{
                        height: 20,
                        minWidth: 28,
                        fontSize: '0.75rem',
                      }}
                    />
                  )}
                </Box>
              </Box>
            }
            className={`${styles.tab} ${styles.tabActive}`}
          />
          <Tab
            label={
              <Box className={styles.tabContent}>
                <VisibilityOffIcon />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <span>{t('store.tabs.unlistedProducts')}</span>
                  {inactiveProducts.length > 0 && (
                    <Chip
                      label={inactiveProducts.length}
                      size="small"
                      color="default"
                      sx={{
                        height: 20,
                        minWidth: 28,
                        fontSize: '0.75rem',
                      }}
                    />
                  )}
                </Box>
              </Box>
            }
            className={`${styles.tab} ${styles.tabInactive}`}
          />
          <Tab
            label={
              <Box className={styles.tabContent}>
                <ShoppingBasketIcon />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                  className={pendingOrdersCount > 0 ? styles.tabOrdersPulse : ''}
                >
                  <span>{t('store.tabs.orders')}</span>
                  {pendingOrdersCount > 0 && (
                    <Chip
                      label={pendingOrdersCount}
                      size="small"
                      color="warning"
                      sx={{
                        height: 20,
                        minWidth: 28,
                        fontSize: '0.75rem',
                        backgroundColor: '#fff3e0',
                        color: '#ed6c02',
                      }}
                    />
                  )}
                </Box>
              </Box>
            }
            className={`${styles.tab} ${styles.tabOrders}`}
          />
        </Tabs>
      </Box>

      {/* Product Form Modal */}
      <Dialog
        open={showAddForm && activeTab !== 2}
        onClose={() => {
          setShowAddForm(false);
          resetForm();
        }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography component="span" variant="h5" sx={{ fontWeight: 600 }}>
            {isEditing ? t('store.form.title.edit') : t('store.form.title.add')}
          </Typography>
          <IconButton
            onClick={() => {
              setShowAddForm(false);
              resetForm();
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ pt: 3 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Images Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                  {t('store.form.fields.images.label')}
                  <Typography component="span" variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                    {t('store.form.fields.images.mainImageNote')}
                  </Typography>
                </Typography>

                {/* Image Preview Grid */}
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                    gap: 2,
                    mb: 2,
                  }}
                >
                  {/* Existing Images */}
                  {isEditing &&
                    formData.ProductImages?.map((img, index) => (
                      <Box
                        key={`existing-${index}`}
                        className={styles.imagePreview}
                        draggable
                        onDragStart={e => {
                          e.dataTransfer.setData('text/plain', `existing-${index}`);
                        }}
                        onDragOver={e => {
                          e.preventDefault();
                          e.currentTarget.style.border = '2px dashed #1976d2';
                        }}
                        onDragLeave={e => {
                          e.currentTarget.style.border = 'none';
                        }}
                        onDrop={e => {
                          e.preventDefault();
                          e.currentTarget.style.border = 'none';
                          const draggedIndex = parseInt(e.dataTransfer.getData('text/plain').split('-')[1]);
                          handleReorderImages(draggedIndex, index);
                        }}
                      >
                        <img src={img.imageUrl} alt={`Product ${index + 1}`} className={styles.previewImage} />
                        {index === 0 && (
                          <Chip
                            label={t('store.form.fields.images.mainLabel')}
                            size="small"
                            color="primary"
                            sx={{
                              position: 'absolute',
                              top: 4,
                              left: 4,
                              zIndex: 1,
                            }}
                          />
                        )}
                        <IconButton
                          className={styles.deleteButton}
                          size="small"
                          onClick={() => handleDeleteExistingImage(img, index)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}

                  {/* New Images */}
                  {imageFiles.map((file, index) => (
                    <Box
                      key={`new-${index}`}
                      className={styles.imagePreview}
                      draggable
                      onDragStart={e => {
                        e.dataTransfer.setData('text/plain', `new-${index}`);
                      }}
                      onDragOver={e => {
                        e.preventDefault();
                        e.currentTarget.style.border = '2px dashed #1976d2';
                      }}
                      onDragLeave={e => {
                        e.currentTarget.style.border = 'none';
                      }}
                      onDrop={e => {
                        e.preventDefault();
                        e.currentTarget.style.border = 'none';
                        const draggedIndex = parseInt(e.dataTransfer.getData('text/plain').split('-')[1]);
                        handleReorderImages(draggedIndex, index);
                      }}
                    >
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Upload preview ${index + 1}`}
                        className={styles.previewImage}
                      />
                      {index === 0 && formData.ProductImages.length === 0 && (
                        <Chip
                          label={t('store.form.fields.images.mainLabel')}
                          size="small"
                          color="primary"
                          sx={{
                            position: 'absolute',
                            top: 4,
                            left: 4,
                            zIndex: 1,
                          }}
                        />
                      )}
                      <IconButton
                        className={styles.deleteButton}
                        size="small"
                        onClick={() => {
                          setImageFiles(prev => prev.filter((_, i) => i !== index));
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}

                  {/* Upload Button */}
                  {formData.ProductImages.length + imageFiles.length < 5 && (
                    <Box sx={{ position: 'relative', height: '100%' }}>
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        id="image-upload"
                      />
                      <label htmlFor="image-upload">
                        <Button
                          component="span"
                          variant="outlined"
                          sx={{
                            borderRadius: 2,
                            borderStyle: 'dashed',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            minHeight: '150px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <AddIcon />
                          <Typography variant="body2" color="textSecondary" align="center">
                            {t('store.form.fields.images.addButton')}
                            <br />
                            {formData.ProductImages.length + imageFiles.length}/5
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                  )}
                </Box>
                {imageError && (
                  <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                    {imageError}
                  </Typography>
                )}
              </Grid>

              {/* Basic Information Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                  {t('store.form.sections.basicInfo')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label={t('store.form.fields.name.label')}
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      inputProps={{
                        maxLength: FIELD_LIMITS.NAME_MAX,
                      }}
                      helperText={t('store.form.fields.charactersCount', {
                        current: formData.name.length,
                        max: FIELD_LIMITS.NAME_MAX,
                      })}
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {isCategoryMenuOpen ? (
                      <TextField
                        select
                        fullWidth
                        name="categoryName"
                        label={t('store.form.fields.category.label')}
                        value={selectedUpsertCategory || ''}
                        onChange={() => setIsCategoryMenuOpen(false)}
                        required
                        InputProps={{
                          sx: { borderRadius: 2 },
                        }}
                        SelectProps={{
                          open: isUpsertCategoryMenuOpen, // Add this line
                          onClose: () => {
                            setIsUpsertCategoryMenuOpen(false);
                            setIsCategoryMenuOpen(false); //close the text field when the menu is closed
                          }, // Add this line
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 300,
                                borderRadius: '8px',
                                mt: 1,
                              },
                            },
                          },
                        }}
                      >
                        {categories.map(category => (
                          <MenuItemCompo
                            key={category.id}
                            category={category}
                            onSelect={selectedCategory => {
                              setFormData(prev => ({
                                ...prev,
                                categoryId: selectedCategory.id,
                              }));
                              setIsCategoryMenuOpen(false);
                              setSelectedUpsertCategory(selectedCategory.name);
                            }}
                          />
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        fullWidth
                        name="categoryName"
                        label={t('store.form.fields.category.label')}
                        value={selectedUpsertCategory || ''}
                        onClick={() => {
                          setIsCategoryMenuOpen(true);
                          setSelectedUpsertCategory('');
                          setIsUpsertCategoryMenuOpen(true);
                        }}
                        required
                        InputProps={{
                          sx: { borderRadius: 2 },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Description Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                  {t('store.form.fields.description.label')}
                </Typography>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder={t('store.form.fields.description.placeholder')}
                  helperText={t('store.form.fields.charactersCount', {
                    current: formData.description.length,
                    max: FIELD_LIMITS.DESCRIPTION_MAX,
                  })}
                  InputProps={{
                    sx: { borderRadius: 2 },
                  }}
                />
              </Grid>

              {/* Attributes Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                  {t('store.form.sections.attributes')}
                  <Typography component="span" variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                    {t('store.form.fields.attributes.optional')}
                  </Typography>
                </Typography>

                {/* Attribute Type Input */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label={t('store.form.fields.attributes.type')}
                      placeholder={t('store.form.fields.attributes.typePlaceholder')}
                      value={selectedAttributeType}
                      onChange={e => setSelectedAttributeType(e.target.value.toLowerCase())}
                      InputProps={{
                        sx: { borderRadius: 2 },
                        endAdornment: selectedAttributeType && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => {
                                if (!formData.attributes[selectedAttributeType]) {
                                  setFormData(prev => ({
                                    ...prev,
                                    attributes: {
                                      ...prev.attributes,
                                      [selectedAttributeType]: [],
                                    },
                                  }));
                                }
                                setSelectedAttributeType('');
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={t('store.form.fields.attributes.typeHelper')}
                    />
                  </Grid>
                </Grid>

                {/* Display Attribute Groups */}
                <Box sx={{ mt: 2 }}>
                  {Object.entries(formData.attributes).map(([attrName, values]) => (
                    <Paper
                      key={attrName}
                      sx={{
                        p: 2,
                        mb: 2,
                        borderRadius: 2,
                        backgroundColor: '#f8f9fa',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 2,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: 600,
                            color: 'primary.main',
                          }}
                        >
                          {attrName}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => {
                            const { [attrName]: _, ...restAttributes } = formData.attributes;
                            setFormData(prev => ({
                              ...prev,
                              attributes: restAttributes,
                            }));
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>

                      {/* Value Input for this Attribute */}
                      <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder={t('store.form.fields.attributes.addValue', { attribute: attrName })}
                          value={attributeValues[attrName] || ''}
                          onChange={e =>
                            setAttributeValues(prev => ({
                              ...prev,
                              [attrName]: e.target.value,
                            }))
                          }
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleAddAttribute(attrName, attributeValues[attrName] || '');
                            }
                          }}
                          InputProps={{
                            sx: { borderRadius: 2 },
                          }}
                        />
                        <Button
                          variant="contained"
                          size="small"
                          disabled={!attributeValues[attrName]?.trim()}
                          onClick={() => handleAddAttribute(attrName, attributeValues[attrName] || '')}
                          sx={{ borderRadius: 2, minWidth: '100px' }}
                        >
                          {t('store.form.fields.attributes.add')}
                        </Button>
                      </Box>

                      {/* Display Values as Chips */}
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {values.map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            onDelete={() => handleDeleteAttributeValue(attrName, value)}
                            size="small"
                            sx={{
                              borderRadius: '6px',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                              },
                            }}
                          />
                        ))}
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Grid>

              {/* Pricing & Inventory Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                  {t('store.form.sections.pricingInventory')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      label={t('store.form.fields.price.label')}
                      name="price"
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{t('currency.symbol')}</InputAdornment>,
                        sx: { borderRadius: 2 },
                      }}
                      value={formData.price}
                      onChange={handleInputChange}
                      helperText={t('store.form.fields.price.helper', {
                        max: formatPrice(FIELD_LIMITS.PRICE_MAX),
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      label={t('store.form.fields.stock.label')}
                      name="stock"
                      type="number"
                      value={formData.stock}
                      onChange={handleInputChange}
                      helperText={t('store.form.fields.stock.helper', {
                        max: FIELD_LIMITS.STOCK_MAX.toLocaleString(),
                      })}
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      label={t('store.form.fields.minOrder.label')}
                      name="minOrder"
                      type="number"
                      value={formData.minOrder}
                      onChange={handleInputChange}
                      error={formData.minOrder === '' || Number(formData.minOrder) < 1}
                      helperText={
                        formData.minOrder === '' || Number(formData.minOrder) < 1
                          ? t('store.form.fields.minOrder.error')
                          : t('store.form.fields.minOrder.helper', {
                              max: FIELD_LIMITS.MIN_ORDER_MAX.toLocaleString(),
                            })
                      }
                      InputProps={{
                        sx: { borderRadius: 2 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Attributes Section */}

              {/* Submit Buttons */}
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                  <Button onClick={resetForm} variant="outlined" sx={{ borderRadius: 2, minWidth: '120px' }}>
                    {t('store.form.buttons.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{ borderRadius: 2, minWidth: '120px' }}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      t(isEditing ? 'store.form.buttons.update' : 'store.form.buttons.add')
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {activeTab === 0 ? (
        // Active Products Tab Content
        <>
          {/* Search and Filter Section */}
          <Box className={styles.searchContainer}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('store.products.searchPlaceholder')}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: 2,
                  '& .MuiInputAdornment-root': {
                    // RTL support for search icon
                    marginInlineEnd: '8px',
                    marginInlineStart: '4px',
                  },
                },
              }}
              sx={{ flex: 1 }}
            />
            {isFilterCategoryMenuOpen ? (
              <TextField
                select
                label={t('store.products.filterByCategory')}
                value={selectedCategory || ''}
                onChange={() => setIsFilterCategoryMenuOpen(false)}
                sx={{
                  minWidth: 200,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        maxHeight: 300,
                        borderRadius: '8px',
                        mt: 1,
                      },
                    },
                  },
                }}
              >
                <MenuItemCompo
                  key="all"
                  category={{
                    id: 0,
                    name: t('store.tabs.allCategories'),
                    path: '',
                    slug: '',
                    level: 0,
                  }}
                  onSelect={() => {
                    setSelectedCategory('');
                    setIsFilterCategoryMenuOpen(false);
                    setSelectedFilterCategoryName(t('store.tabs.allCategories'));
                  }}
                />
                {categories.map(category => (
                  <MenuItemCompo
                    key={category.id}
                    category={category}
                    onSelect={selectedCategory => {
                      setSelectedCategory(selectedCategory.id.toString());
                      setIsFilterCategoryMenuOpen(false);
                      setSelectedFilterCategoryName(selectedCategory.name);
                    }}
                  />
                ))}
              </TextField>
            ) : (
              <TextField
                label={t('store.products.filterByCategory')}
                value={selectedFilterCategoryName || ''}
                onClick={() => {
                  setIsFilterCategoryMenuOpen(true);
                  setSelectedFilterCategoryName('');
                }}
                sx={{
                  minWidth: 200,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          </Box>

          {/* Products Grid */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          ) : filteredProducts.length === 0 ? (
            <Paper sx={{ p: 4, textAlign: 'center' }}>
              <Typography variant="h6" color="textSecondary">
                {t('store.products.noProducts')}
              </Typography>
            </Paper>
          ) : (
            <>
              <div className={styles.productsGrid}>
                {paginatedProducts.map(product => (
                  <ProductCard
                    key={product.id}
                    product={{
                      ...product,
                      price: Number(product.price),
                    }}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onToggleActive={handleToggleActive}
                  />
                ))}
              </div>

              {/* Pagination Controls */}
              {totalPages > 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem 0',
                    width: '100%',
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                    sx={{
                      '& .MuiPaginationItem-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </>
      ) : activeTab === 1 ? (
        // Inactive Products Tab Content
        <>
          {/* Search and Filter Section */}
          <Box className={styles.searchContainer}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('store.products.searchPlaceholder')}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: 2,
                  '& .MuiInputAdornment-root': {
                    // RTL support for search icon
                    marginInlineEnd: '8px',
                    marginInlineStart: '4px',
                  },
                },
              }}
              sx={{ flex: 1 }}
            />
            {isFilterCategoryMenuOpen ? (
              <TextField
                select
                label={t('store.products.filterByCategory')}
                value={selectedCategory || ''}
                onChange={() => setIsFilterCategoryMenuOpen(false)}
                sx={{
                  minWidth: 200,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        maxHeight: 300,
                        borderRadius: '8px',
                        mt: 1,
                      },
                    },
                  },
                }}
              >
                <MenuItemCompo
                  key="all"
                  category={{
                    id: 0,
                    name: t('store.tabs.allCategories'),
                    path: '',
                    slug: '',
                    level: 0,
                  }}
                  onSelect={() => {
                    setSelectedCategory('');
                    setIsFilterCategoryMenuOpen(false);
                    setSelectedFilterCategoryName(t('store.tabs.allCategories'));
                  }}
                />
                {categories.map(category => (
                  <MenuItemCompo
                    key={category.id}
                    category={category}
                    onSelect={selectedCategory => {
                      setSelectedCategory(selectedCategory.id.toString());
                      setIsFilterCategoryMenuOpen(false);
                      setSelectedFilterCategoryName(selectedCategory.name);
                    }}
                  />
                ))}
              </TextField>
            ) : (
              <TextField
                label={t('store.products.filterByCategory')}
                value={selectedFilterCategoryName || ''}
                onClick={() => {
                  setIsFilterCategoryMenuOpen(true);
                  setSelectedFilterCategoryName('');
                }}
                sx={{
                  minWidth: 200,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          </Box>

          {/* Loading State */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          ) : inactiveProducts.length === 0 ? (
            <Paper
              sx={{
                p: 4,
                textAlign: 'center',
                borderRadius: '12px',
                backgroundColor: '#fff',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No inactive products found
              </Typography>
            </Paper>
          ) : (
            // Products Grid
            <>
              <div className={styles.productsGrid}>
                {paginatedProducts.map(product => (
                  <ProductCard
                    key={product.id}
                    product={{
                      ...product,
                      price: Number(product.price), // Ensure price is a number
                    }}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onToggleActive={handleToggleActive}
                  />
                ))}
              </div>

              {/* Pagination Controls */}
              {totalPages > 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem 0',
                    width: '100%',
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                    sx={{
                      '& .MuiPaginationItem-root': {
                        borderRadius: '8px',
                      },
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        // Sales Orders Tab Content
        <SalesOrders />
      )}

      {/* Delete Confirmation Dialog - Confirms product deletion */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '8px',
          },
        }}
      >
        <DialogTitle
          sx={{
            pb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: 'error.main',
            '& .MuiSvgIcon-root': {
              marginInlineEnd: 1, // RTL-friendly margin
            },
          }}
        >
          <DeleteIcon color="error" />
          {t('store.dialog.delete.title')}
        </DialogTitle>
        <DialogContent sx={{ pt: 1 }}>
          <Typography variant="body1" gutterBottom>
            {t('store.dialog.delete.message', { name: selectedProduct?.name })}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('store.dialog.delete.warning')}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            pb: 2,
            gap: 2, // Add spacing between buttons
          }}
        >
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            variant="outlined"
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              minWidth: '120px', // Ensure consistent button width
            }}
          >
            {t('store.dialog.delete.cancel')}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              minWidth: '120px', // Ensure consistent button width
              display: 'flex',
              alignItems: 'center',
              gap: 1, // Space between icon and text
              '& .MuiSvgIcon-root': {
                marginInlineEnd: '4px', // RTL-friendly margin
                order: i18n.dir() === 'rtl' ? 1 : 0, // Change icon position in RTL
              },
              '&:hover': {
                backgroundColor: 'error.dark',
              },
            }}
          >
            <DeleteIcon />
            {t('store.dialog.delete.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Snackbar */}
      <Snackbar open={feedback.open} autoHideDuration={6000} onClose={() => setFeedback({ ...feedback, open: false })}>
        <Alert
          severity={feedback.severity}
          onClose={() => setFeedback({ ...feedback, open: false })}
          sx={{ width: '100%' }}
          component="div"
        >
          {feedback.message}
        </Alert>
      </Snackbar>

      {/* Add Floating Action Button for mobile */}
      {activeTab === 0 && (
        <Fab
          color="primary"
          aria-label="add product"
          onClick={() => setShowAddForm(!showAddForm)}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            display: { xs: 'flex', md: 'none' },
            zIndex: 1000,
          }}
        >
          {showAddForm ? <CloseIcon /> : <AddIcon />}
        </Fab>
      )}
    </Box>
  );
}

export default Store;
