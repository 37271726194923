import { S3Client, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION!,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  },
});
//upload product images and all other files to s3
export const uploadToS3 = async (file: File): Promise<string> => {
  const fileName = `${Date.now()}-${file.name}`;
  const bucket = process.env.REACT_APP_S3_BUCKET!;
  const key = `product-images/${fileName}`;

  const command = new PutObjectCommand({
    Bucket: bucket,
    Key: key,
    Body: file,
    ContentType: file.type,
  });

  try {
    await s3Client.send(command);
    // Return the URL of the uploaded file
    return `https://${bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`;
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw new Error('Could not upload file to S3');
  }
};

//upload profile photos to s3
export const uploadProfilePhotoToS3 = async (file: File): Promise<string> => {
  const fileName = `${Date.now()}-${file.name}`;
  const bucket = process.env.REACT_APP_S3_BUCKET!;
  const key = `profile-photos/${fileName}`;

  const command = new PutObjectCommand({
    Bucket: bucket,
    Key: key,
    Body: file,
    ContentType: file.type,
    CacheControl: 'max-age=31536000', // Cache for 1 year
  });

  try {
    await s3Client.send(command);
    // Return the URL of the uploaded file
    return `https://${bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`;
  } catch (error) {
    console.error('Error uploading profile photo to S3:', error);
    throw new Error('Could not upload profile photo to S3');
  }
};

//delete files from s3
export const deleteFromS3 = async (photoUrl: string): Promise<void> => {
  const bucket = process.env.REACT_APP_S3_BUCKET!;

  try {
    // Extract the key from the photo URL using URL parsing
    const url = new URL(photoUrl);
    const pathname = url.pathname;
    // Remove leading slash if present
    const key = pathname.startsWith('/') ? pathname.slice(1) : pathname;

    const command = new DeleteObjectCommand({
      Bucket: bucket,
      Key: key,
    });

    await s3Client.send(command);
  } catch (error) {
    console.error('Error deleting file from S3:', error);
    throw new Error('Could not delete file from S3');
  }
};
