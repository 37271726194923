export const arContent = {
  title: 'الشروط والأحكام',
  lastUpdated: 'آخر تحديث: نوفمبر 2024',
  introduction: `مرحبًا بك في Titrom! باستخدامك لموقعنا الإلكتروني وخدماتنا، فإنك توافق على الالتزام بشروط الخدمة هذه ("الشروط")، وجميع القوانين واللوائح المعمول بها، وتقر بأنك مسؤول عن الامتثال لأي قوانين محلية معمول بها. إذا كنت لا توافق على أي من هذه الشروط، فإنك ممنوع من استخدام أو الوصول إلى هذا الموقع.`,
  sections: [
    {
      title: 'حسابات المستخدمين',
      subsections: [
        {
          subtitle: '1.التسجيل',
          content:
            'يجب على المستخدمين تسجيل حساب لبيع المنتجات. يتطلب التسجيل تقديم معلومات صحيحة حسب النموذج المطلوب. يجب أن يكون عمر المستخدمين 18 عامًا أو أكثر لإنشاء حساب واستخدام خدمات Titrom.',
        },
        {
          subtitle: '2.أدوار الحسابات',
          content:
            'يمكن للمستخدمين التسجيل كبائعين. يمكن للبائعين إدراج منتجاتهم للبيع بالجملة مع تحديد الكميات الدنيا للطلب.',
        },
        {
          subtitle: '3.الوصول',
          content: 'يمكن للمستخدمين تصفح وشراء المنتجات دون تسجيل. ومع ذلك، يتطلب البيع على Titrom وجود حساب.',
        },
        {
          subtitle: '4.تعليق/إنهاء الحساب',
          content:
            'يحتفظ Titrom بالحق في تعليق أو إنهاء حسابك في أي وقت دون إشعار إذا كانت هناك شكوك بشأن أنشطة احتيالية أو انتهاك لهذه الشروط.',
        },
      ],
    },
    {
      title: 'المعاملات',
      subsections: [
        {
          subtitle: '1.البيع والشراء',
          content:
            'يقوم البائعون بإدراج منتجاتهم مع تحديد شروط البيع، بما في ذلك الحد الأدنى للطلبات. يمكن للمشترين شراء المنتجات إما عن طريق تسجيل الدخول إلى حسابهم أو كضيوف، مع تقديم معلومات التسليم اللازمة كما هو مطلوب في نموذج الطلب.',
        },
        {
          subtitle: '2.الدفع والتسليم',
          content:
            'تُكمل المعاملات بنظام الدفع عند الاستلام، ويكون البائعون مسؤولين عن التسليم، بينما يتحمل المشترون مسؤولية الدفع.',
        },
        {
          subtitle: '3.المسؤولية',
          content:
            'يتحمل المشترون مسؤولية فحص المنتجات عند التسليم، ويحق لهم رفض الدفع وقبول المنتجات إذا لم تتطابق مع المعايير المتفق عليها.',
        },
      ],
    },
    {
      title: 'استخدام الخدمة',
      subsections: [
        {
          subtitle: '1.الرسوم',
          content:
            'استخدام Titrom مجاني للبائعين والمشترين. سيتم إبلاغ المستخدمين بأي تحديثات مستقبلية تتعلق برسوم الاشتراك أو الخدمات الإضافية عبر المنصة.',
        },
        {
          subtitle: '2.المحتوى',
          content:
            'يتحمل المستخدمون مسؤولية دقة المحتوى الذي يقدّمونه عند إدراج المنتجات. من خلال استخدام المنصة، يمنح البائعون Titrom الحق في استخدام محتواهم لأغراض إعلانية.',
        },
        {
          subtitle: '3.الأنشطة المحظورة',
          content:
            ' يحظر بيع أي مواد غير أخلاقية أو مخالفة للمبادئ الإسلامية. كما يُمنع استخدام شعارات وتصاميم وأي حقوق ملكية فكرية خاصة بـ Titrom دون إذن.',
        },
      ],
    },
    {
      title: 'الامتثال القانوني',
      content:
        'يوافق المستخدمون على الامتثال لجميع القوانين المحلية المعمول بها المتعلقة بالتجارة الإلكترونية في المغرب، بما في ذلك تلك المتعلقة بالمعاملات بالجملة.',
    },
    {
      title: 'الملكية الفكرية',
      subsections: [
        {
          subtitle: '1.محتوى المستخدمين',
          content:
            'يظل المحتوى الذي يقدمه المستخدمون على Titrom ملكية فكرية لأصحابه. يمنح المستخدمون Titrom ترخيصًا غير حصري ومجاني لاستخدام هذا المحتوى وتعديله ونشره لغايات تشغيل الموقع وترويجه.',
        },
        {
          subtitle: '2.الملكية الفكرية لـ Titrom',
          content:
            'جميع الحقوق الخاصة بالتصميم والعلامات التجارية والشعارات مملوكة لـ Titrom ومحمية بموجب قوانين الملكية الفكرية. يُمنع الاستخدام غير المصرح به.',
        },
      ],
    },
    {
      title: 'حل النزاعات',
      content:
        'يجب حل النزاعات مباشرة بين البائع والمشتري. لا يتدخل Titrom في النزاعات، لكنه يوصي بأن يتفاوض الأطراف بنزاهة وبحسن نية لحل أي خلافات.',
    },
    {
      title: 'تغييرات على الشروط',
      content:
        'يحتفظ Titrom بالحق في تعديل هذه الشروط في أي وقت. سيتم إخطار المستخدمين بأي تغييرات عبر المنصة. يعتبر استمرار استخدامك للمنصة بعد أي تغييرات بمثابة موافقة منك على هذه التغييرات.',
    },
    {
      title: 'سياسة الخصوصية',
      content:
        'للحصول على تفاصيل حول كيفية جمع معلوماتك واستخدامها وحمايتها، يرجى الرجوع إلى صفحة سياسة الخصوصية الخاصة بنا.',
    },
  ],
  footer: {
    contactInfo: 'للدعم أو الاستفسارات حول هذه الشروط، يرجى التواصل معنا عبر البريد الإلكتروني أو دردشة واتساب.',
  },
};
