import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Paper,
  Typography,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Divider,
  Alert,
  Pagination,
  Tabs,
  Tab,
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import RouteIcon from '@mui/icons-material/Route';
import styles from './PurchaseOrders.module.css';
import EndPoints from '../EndPointConfig';
import { motion, AnimatePresence } from 'framer-motion';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BuyerNotificationBell from '../Components/BuyerNotificationBell';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeIcon from '@mui/icons-material/Home';
import { truncateText } from '../utils/formatters';
import { useAuth } from '../contexts/AuthContext';

interface Order {
  id: number;
  quantity: number;
  totalPrice: number;
  status: 'pending' | 'processing' | 'enroute' | 'delivered' | 'cancelled';
  deliveryAddress: string;
  orderDate: string;
  phoneNumber: string;
  buyerCity: string;
  buyerName: string;
  attributes?: Record<string, string[]> | null;

  Product: {
    id: number;
    name: string;
    price: number;
    ProductImages: Array<{
      imageUrl: string;
      isMain: boolean;
    }>;
    Seller: {
      id: number;
      storeName: string;
      profilePhoto: string;
    };
  };
}

interface PurchaseOrdersProps {
  onNotificationsRead: () => Promise<void>;
}

interface OrderSummaryProps {
  order: Order;
  formatDate: (date: string) => string;
  formatPrice: (price: number | string) => string;
  getStatusIcon: (status: Order['status']) => React.ReactElement;
  getStatusLabel: (status: Order['status']) => string;
  t: TFunction<'translation', undefined>;
  onOrderClick: (order: Order) => void;
}

const PurchaseOrders: React.FC<PurchaseOrdersProps> = ({ onNotificationsRead }) => {
  const { user, generateHeaders } = useAuth();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const ordersPerPage = 15;
  const [selectedTab, setSelectedTab] = useState<keyof typeof orderStatuses>('all');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  // Define order status types
  const orderStatuses = {
    all: t('purchaseOrders.status.all'),
    pending: t('purchaseOrders.status.pending'),
    processing: t('purchaseOrders.status.processing'),
    enroute: t('purchaseOrders.status.enroute'),
    delivered: t('purchaseOrders.status.delivered'),
    cancelled: t('purchaseOrders.status.cancelled'),
  };

  // Filter orders based on selected tab
  const getFilteredOrders = () => {
    if (selectedTab === 'all') return orders;
    return orders.filter(order => order.status === selectedTab);
  };

  const filteredOrders = getFilteredOrders();
  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
  const currentOrders = filteredOrders.slice((page - 1) * ordersPerPage, page * ordersPerPage);

  const handleTabChange = (event: React.SyntheticEvent, newValue: keyof typeof orderStatuses) => {
    setSelectedTab(newValue);
    setPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (!user) {
          setError('unauthorized');
          return;
        }
        const headers = generateHeaders(user);

        const response = await axios.get(EndPoints.BUYER_ORDERS, {
          headers,
        });
        const transformedOrders = response.data.map((order: Order) => ({
          ...order,
          attributes: typeof order.attributes === 'string' ? JSON.parse(order.attributes) : order.attributes,
        }));
        setOrders(transformedOrders);
      } catch (error) {
        // More specific error handling
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            setError('unauthorized');
          } else if (error.response?.status === 404) {
            setError('not found');
          } else {
            setError('errors generic');
          }
        } else {
          setError('errors generic');
        }
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user, generateHeaders]);

  const getStatusIcon = (status: Order['status']): React.ReactElement => {
    switch (status) {
      case 'pending':
        return <PendingIcon className={styles.pendingIcon} />;
      case 'processing':
        return <InventoryIcon className={styles.processingIcon} />;
      case 'enroute':
        return <RouteIcon className={styles.enrouteIcon} />;
      case 'delivered':
        return <CheckCircleIcon className={styles.deliveredIcon} />;
      case 'cancelled':
        return <CancelIcon className={styles.cancelledIcon} />;
      default:
        return <PendingIcon />;
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const pageTransitionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
      },
    },
  };

  // Add this function to get counts for each status
  const getStatusCounts = () => {
    const counts = {
      all: orders.length,
      pending: 0,
      processing: 0,
      enroute: 0,
      delivered: 0,
      cancelled: 0,
    };

    orders.forEach(order => {
      if (order.status in counts) {
        counts[order.status as keyof typeof counts]++;
      }
    });

    return counts;
  };

  // Update the status chip to use translations
  const getStatusLabel = (status: Order['status']): string => {
    return t(`purchaseOrders.status.${status}`);
  };

  // Add formatPrice function
  const formatPrice = (price: number | string): string => {
    // Convert price to number if it's a string
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

    // Check if it's a valid number
    if (isNaN(numericPrice)) {
      return '0'; // or handle invalid price differently
    }

    const currencyFormat = t('currency.format');
    const formattedPrice = numericPrice
      .toFixed(Number(t('currency.precision')))
      .replace('.', t('currency.decimal'))
      .replace(/\B(?=(\d{3})+(?!\d))/g, t('currency.thousand'));

    return currencyFormat.replace('{amount}', formattedPrice);
  };

  const handleOrderClick = (order: Order) => {
    setSelectedOrder(order);
  };

  const handleCloseModal = () => {
    setSelectedOrder(null);
  };

  if (loading) {
    return (
      <Box className={styles.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" className={styles.container}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className={styles.container}>
      <Box className={styles.headerSection}>
        <Box className={styles.headerContent}>
          <Typography variant="h1" className={styles.title}>
            {t('purchaseOrders.title')}
          </Typography>
          <BuyerNotificationBell onNotificationsRead={onNotificationsRead} />
        </Box>
      </Box>

      {/* Order Status Tabs */}
      <Box className={styles.tabsContainer}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          className={`${styles.tabs} ${styles[`tabs${selectedTab}`]}`}
        >
          {Object.entries(orderStatuses).map(([status, label]) => (
            <Tab
              key={status}
              label={
                <Box className={styles.tabContent}>
                  {status === 'all' && <ReceiptLongIcon />}
                  {status === 'pending' && <PendingIcon />}
                  {status === 'processing' && <InventoryIcon />}
                  {status === 'enroute' && <LocalShippingIcon />}
                  {status === 'delivered' && <CheckCircleIcon />}
                  {status === 'cancelled' && <CancelIcon />}
                  <span>
                    {label} ({getStatusCounts()[status as keyof typeof orderStatuses]})
                  </span>
                </Box>
              }
              value={status}
              className={`${styles.tab} ${styles[`tab${status.charAt(0).toUpperCase() + status.slice(1)}`]}`}
            />
          ))}
        </Tabs>
      </Box>

      {filteredOrders.length === 0 ? (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Paper className={styles.emptyState}>
            <Typography variant="h6">
              {selectedTab !== 'all'
                ? t('purchaseOrders.noOrders.filtered', { status: orderStatuses[selectedTab].toLowerCase() })
                : ''}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {selectedTab === 'all'
                ? t('purchaseOrders.noOrders.all')
                : t('purchaseOrders.noOrders.filtered', { status: orderStatuses[selectedTab].toLowerCase() })}
            </Typography>
          </Paper>
        </motion.div>
      ) : (
        <AnimatePresence mode="wait">
          <motion.div key={page} variants={pageTransitionVariants} initial="hidden" animate="visible" exit="exit">
            <Grid container spacing={3}>
              <div className={styles.orderSummaryGrid}>
                {currentOrders.map(order => (
                  <Grid item xs={12} key={order.id}>
                    <Box>
                      <OrderSummary
                        order={order}
                        formatDate={formatDate}
                        formatPrice={formatPrice}
                        getStatusIcon={getStatusIcon}
                        getStatusLabel={getStatusLabel}
                        t={t}
                        onOrderClick={handleOrderClick}
                      />
                    </Box>
                  </Grid>
                ))}
              </div>
            </Grid>

            {/* Pagination */}
            {totalPages > 1 && (
              <Box className={styles.paginationContainer}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                  className={styles.pagination}
                />
              </Box>
            )}
          </motion.div>
        </AnimatePresence>
      )}

      {/* Add the Modal */}
      <Dialog
        open={selectedOrder !== null}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: 2,
            m: 2,
          },
        }}
      >
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {selectedOrder && (
            <OrderCard
              order={selectedOrder}
              formatDate={formatDate}
              formatPrice={formatPrice}
              getStatusIcon={getStatusIcon}
              getStatusLabel={getStatusLabel}
              navigate={navigate}
              t={t}
            />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

// Add this component at the bottom of the file, before the export
interface OrderCardProps {
  order: Order;
  formatDate: (date: string) => string;
  formatPrice: (price: number | string) => string;
  getStatusIcon: (status: Order['status']) => React.ReactElement;
  getStatusLabel: (status: Order['status']) => string;
  navigate: (path: string) => void;
  t: TFunction<'translation', undefined>;
}

const OrderCard: React.FC<OrderCardProps> = ({
  order,
  formatDate,
  formatPrice,
  getStatusIcon,
  getStatusLabel,
  navigate,
  t,
}) => {
  return (
    <Paper className={styles.orderCard}>
      <Box className={styles.orderHeader}>
        <Box className={styles.orderHeaderLeft}>
          <Box className={styles.orderIdSection}>
            <Typography variant="h6" className={styles.orderId}>
              ID: {order.id}
            </Typography>
            <Box className={styles.orderDate}>
              <Typography variant="body2" color="textSecondary">
                {formatDate(order.orderDate)}
              </Typography>
            </Box>
          </Box>
          <Box
            className={`${styles.sellerInfo} ${styles.sellerInfoDesktop}`}
            onClick={e => {
              e.stopPropagation();
              navigate(`/user-profile/${order.Product.Seller.id}`);
            }}
          >
            <Avatar
              src={order.Product.Seller.profilePhoto}
              alt={order.Product.Seller.storeName}
              className={styles.sellerAvatar}
            >
              {order.Product.Seller.storeName.charAt(0)}
            </Avatar>
            <Box>
              <Typography variant="subtitle1" className={styles.sellerName}>
                {truncateText(order.Product.Seller.storeName, 20, 15)}
              </Typography>
              <Typography variant="body2" className={styles.sellerLabel}>
                {t('purchaseOrders.orderInfo.seller')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Chip
          icon={getStatusIcon(order.status)}
          label={getStatusLabel(order.status)}
          className={styles[`status${order.status}`]}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box
            className={styles.imageContainer}
            onClick={e => {
              e.stopPropagation(); // Prevent triggering parent click handlers
              navigate(`/product/${order.Product.id}`);
            }}
            sx={{ cursor: 'pointer' }}
          >
            <img
              src={order.Product?.ProductImages?.[0]?.imageUrl || '/placeholder-image.jpg'}
              alt={order.Product?.name}
              className={styles.productImage}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box className={styles.productDetails}>
            <Typography variant="h6" className={styles.productName} title={order.Product.name}>
              {order.Product.name}
            </Typography>

            <Box className={styles.orderInfo}>
              <Box className={styles.summaryItem}>
                <Typography variant="body2" color="textSecondary">
                  {t('purchaseOrders.orderInfo.quantity')}
                </Typography>
                <Typography className={styles.summaryValue}>{order.quantity}</Typography>
              </Box>
              <Box className={styles.summaryItem}>
                <Typography variant="body2" color="textSecondary">
                  {t('purchaseOrders.orderInfo.totalPrice')}
                </Typography>
                <Typography className={styles.summaryValue}>{formatPrice(order.totalPrice)}</Typography>
              </Box>
              <Box className={styles.summaryItem}>
                <Typography variant="body2" color="textSecondary">
                  {t('purchaseOrders.orderInfo.unitPrice')}
                </Typography>
                <Typography variant="body1" className={styles.summaryValue}>
                  {formatPrice(order.Product.price)}
                </Typography>
              </Box>
            </Box>

            {order.attributes && Object.keys(order.attributes).length > 0 && (
              <Box className={styles.specificationsBox}>
                <Typography variant="subtitle2" className={styles.specTitle}>
                  {t('purchaseOrders.orderInfo.specifications')}
                </Typography>
                {Object.entries(order.attributes).map(([key, values]) => (
                  <Box key={key} className={styles.specificationItem}>
                    <Typography variant="body2" color="textSecondary" className={styles.specKey}>
                      {key}:
                    </Typography>
                    <Typography variant="body2" className={styles.specValue}>
                      {Array.isArray(values) ? values.join(', ') : values}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Box className={styles.deliverySection}>
        <Typography variant="h6" className={styles.deliveryTitle}>
          <LocalShippingIcon sx={{ fontSize: 20 }} />
          {t('purchaseOrders.orderInfo.deliveryInfo')}
        </Typography>
        <Box className={styles.deliveryGrid}>
          <Box className={styles.deliveryRow}>
            <Box className={styles.deliveryItem}>
              <Box className={styles.deliveryLabel}>
                <PersonIcon sx={{ fontSize: 20 }} />
                <Typography variant="body2" color="textSecondary">
                  {t('purchaseOrders.orderInfo.name')}
                </Typography>
              </Box>
              <Typography variant="body1" className={styles.deliveryValue}>
                {order.buyerName}
              </Typography>
            </Box>
            <Box className={styles.deliveryItem}>
              <Box className={styles.deliveryLabel}>
                <PhoneIcon sx={{ fontSize: 20 }} />
                <Typography variant="body2" color="textSecondary">
                  {t('purchaseOrders.orderInfo.contact')}
                </Typography>
              </Box>
              <Typography variant="body1" className={styles.deliveryValue}>
                {order.phoneNumber}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.deliveryRow}>
            <Box className={styles.deliveryItem}>
              <Box className={styles.deliveryLabel}>
                <LocationCityIcon sx={{ fontSize: 20 }} />
                <Typography variant="body2" color="textSecondary">
                  {t('purchaseOrders.orderInfo.city')}
                </Typography>
              </Box>
              <Typography variant="body1" className={styles.deliveryValue}>
                {order.buyerCity}
              </Typography>
            </Box>
            <Box className={styles.deliveryItem}>
              <Box className={styles.deliveryLabel}>
                <HomeIcon sx={{ fontSize: 20 }} />
                <Typography variant="body2" color="textSecondary">
                  {t('purchaseOrders.orderInfo.address')}
                </Typography>
              </Box>
              <Typography variant="body1" className={styles.deliveryValue}>
                {order.deliveryAddress}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const OrderSummary: React.FC<OrderSummaryProps> = ({
  order,
  formatDate,
  formatPrice,
  getStatusIcon,
  getStatusLabel,
  t,
  onOrderClick,
}) => {
  return (
    <Paper
      className={styles.orderSummary}
      onClick={() => onOrderClick(order)}
      sx={{
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 3,
        },
      }}
    >
      <Box className={styles.summaryContent}>
        <Box className={styles.summaryImageContainer}>
          <img
            src={order.Product?.ProductImages?.[0]?.imageUrl || '/placeholder-image.jpg'}
            alt={order.Product?.name}
            className={styles.summaryImage}
          />
        </Box>

        <Box className={styles.summaryDetails}>
          <Typography variant="subtitle1" className={styles.summaryProductName}>
            {truncateText(order.Product.name, 15)}
          </Typography>
          <Typography variant="body2" className={styles.summaryDate}>
            {formatDate(order.orderDate)}
          </Typography>
        </Box>

        <Box className={styles.summaryQuantity}>
          <Typography variant="body1">
            {t('purchaseOrders.orderInfo.quantity')}: {order.quantity}
          </Typography>
        </Box>

        <Box className={styles.summaryPrice}>
          <Typography variant="body1">{formatPrice(order.totalPrice)}</Typography>
        </Box>

        <Box className={styles.summaryStatus}>
          <Chip
            icon={getStatusIcon(order.status)}
            label={getStatusLabel(order.status)}
            className={styles[`status${order.status}`]}
            size="small"
          />
        </Box>

        <Box>
          <Typography className={styles.summaryOrderId}>ID: {order.id}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default PurchaseOrders;
