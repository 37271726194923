import React from 'react';
import { enContent } from './content/en';
import { arContent } from './content/ar';
import { frContent } from './content/fr';
import './terms.css';

interface ContentType {
  title: string;
  lastUpdated: string;
  introduction: string;
  sections: {
    title: string;
    content?: string;
    subsections?: {
      subtitle: string;
      content: string;
    }[];
  }[];
  footer: {
    contactInfo: string;
  };
}

interface TermsProps {
  language: 'en' | 'ar' | 'fr';
}

const Terms: React.FC<TermsProps> = ({ language }) => {
  const content: Record<'en' | 'ar' | 'fr', ContentType> = {
    en: enContent,
    ar: arContent,
    fr: frContent,
  };

  const currentContent = content[language] || content.en;

  return (
    <div className={`terms-container ${language === 'ar' ? 'rtl' : 'ltr'}`}>
      <h1 className="terms-title">{currentContent.title}</h1>
      <p className="last-updated">{currentContent.lastUpdated}</p>

      <div className="introduction">
        <p>{currentContent.introduction}</p>
      </div>

      <div className="sections">
        {currentContent.sections.map((section, index) => (
          <div key={index} className="section">
            <h2>{section.title}</h2>

            {section.content && (
              <div className="content">
                <p>{section.content}</p>
              </div>
            )}

            {section.subsections && (
              <div className="subsections">
                {section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} className="subsection">
                    <h3>{subsection.subtitle}</h3>
                    <div className="content">
                      <p>{subsection.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <footer className="terms-footer">
        <p>{currentContent.footer.contactInfo}</p>
      </footer>
    </div>
  );
};

export default Terms;
